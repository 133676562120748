import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";

const WrapCard = ({ title, children, isFetching = true }) => {
  return (
    <Card sx={{ my: "30px" }}>
      <SuiBox px={3} py={2} sx={{ borderBottom: "1px solid #D3E0E5" }}>
        <SuiTypography fontSize="1rem" fontWeight="medium">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox component="form" px={3} py={2}>
        {isFetching ? (
          children
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            <CommonSpinner size="100px" color={colors.info.main} />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
};

WrapCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default WrapCard;
