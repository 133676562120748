import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

const AgentIcon = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17.728 23"
      fill={colors[color].main}
    >
      <path
        id="Union_46"
        d="M.959,22.54l-.219.115a4.838,4.838,0,0,1-.63-1.668A8.4,8.4,0,0,1,0,19.32v-.834A10.268,10.268,0,0,1,2.466,11.73a8.527,8.527,0,0,1,4.74-2.789A4.6,4.6,0,0,1,4.165,4.629a4.686,4.686,0,0,1,9.371,0,4.63,4.63,0,0,1-3.041,4.312,8.367,8.367,0,0,1,4.74,2.789A10.382,10.382,0,0,1,17.7,18.486h0a8.194,8.194,0,0,0,.027.833,8.4,8.4,0,0,1-.109,1.668,5.465,5.465,0,0,1-.63,1.668l-.219-.115a.6.6,0,0,1-.575.46H1.534A.6.6,0,0,1,.959,22.54ZM14.084,15.3v6.584H16.3a3.782,3.782,0,0,0,.329-1.035h0c.082-.46.082-.949.138-1.553v-.833a9.384,9.384,0,0,0-2.247-6.124,7.4,7.4,0,0,0-11.289,0A9.241,9.241,0,0,0,.987,18.458v.833A8.627,8.627,0,0,0,1.1,20.815a3.779,3.779,0,0,0,.329,1.035H3.645V15.3a.493.493,0,0,1,.986,0V21.85H13.1V15.3a.493.493,0,0,1,.986,0ZM6.247,2.042a3.6,3.6,0,0,0,0,5.175A3.716,3.716,0,0,0,8.85,8.28a3.668,3.668,0,0,0,2.6-1.064,3.6,3.6,0,0,0,0-5.175A3.716,3.716,0,0,0,8.85.978,3.668,3.668,0,0,0,6.247,2.042Zm.987,18.4a.3.3,0,0,1-.219-.086.319.319,0,0,1-.082-.2v-.029l.9-7.3a1.576,1.576,0,0,1-.63-1.265A1.672,1.672,0,0,1,8.85,9.919a1.653,1.653,0,0,1,1.644,1.639,1.618,1.618,0,0,1-.63,1.265l.9,7.3v.029a.267.267,0,0,1-.082.2.3.3,0,0,1-.219.086Z"
      />
    </svg>
  );
};

AgentIcon.defaultProps = {
  color: "dark",
  size: "16px",
};

AgentIcon.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AgentIcon;
