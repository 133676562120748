import React from "react";

const DownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5207 11.6315L12.6875 14.4654V7.1875C12.6875 6.808 12.3795 6.5 12 6.5C11.6205 6.5 11.3125 6.808 11.3125 7.1875V14.4654L8.47932 11.6322C8.2105 11.3634 7.77532 11.3634 7.50718 11.6322C7.23837 11.901 7.23837 12.3362 7.50718 12.6043L11.3964 16.4935C11.5614 16.6585 11.7869 16.7093 12.0007 16.6715C12.2139 16.7093 12.44 16.6578 12.605 16.4935L16.4942 12.6043C16.763 12.3355 16.763 11.9003 16.4942 11.6322C16.2247 11.3634 15.7895 11.3634 15.5207 11.6315ZM12 1C5.92527 1 1 5.92455 1 12C1 18.0755 5.92527 23 12 23C18.0747 23 23 18.0747 23 12C23 5.92527 18.0755 1 12 1ZM12 21.625C6.68423 21.625 2.375 17.3158 2.375 12C2.375 6.68423 6.68423 2.375 12 2.375C17.3158 2.375 21.625 6.68423 21.625 12C21.625 17.3158 17.3158 21.625 12 21.625Z"
        fill="#ED952F"
        stroke="#ED952F"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default DownloadIcon;
