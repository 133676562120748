import { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiOTPInput from "components/SuiOTPInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLoginWithPasswordMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
} from "app/features/api/AuthApi";
import { setCredentials } from "app/features/AuthSlice";
import { useDispatch } from "react-redux";
const useStyles = makeStyles(() => ({
  lineHight: {
    lineHeight: "0.75rem !important",
  },
  padding: {
    paddingTop: "0",
  },
  icon: {
    fontSize: "0.85rem !important",
    marginTop: "2px",
  },
}));

const EnterOTP = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);
  const [otp, setOtp] = useState(state?.data.otp);
  const [error, setError] = useState(undefined);
  const [loginWithPassword] = useLoginWithPasswordMutation();
  const [verifyOtp] = useVerifyOtpMutation();
  const [generateOtp] = useGenerateOtpMutation();
  const [btnTxt, setBtnTxt] = useState({ continue: "Continue", resend: "Resend" });
  const otpHandler = async (enterOtp) => {
    const enteredOTP = enterOtp.join("");
    if (enteredOTP.length < 6) {
      setError("Please enter valid otp");
    } else {
      const body = {
        mobile_no: state.body.mobile_no,
        otp: enteredOTP,
        country_code: state.body.country_code,
      };
      setBtnTxt({ ...btnTxt, continue: "Verifing OTP..." });
      if (state.body.otp_type === "LOGIN") {
        const data = await loginWithPassword(body);
        if (data.error) {
          setBtnTxt({ ...btnTxt, continue: "Continue" });
          setError(data.error.data.status.message);
          return;
        }
        if (data.data.status.code === 200) {
          localStorage.setItem("refresh_token", data.data.data.refresh_token);
          localStorage.setItem("token", data.data.data.access_token);
          localStorage.setItem("tokenExpiryTime", data.data.data.access_token_expiry);
          localStorage.setItem("loggedInTime", new Date());
          dispatch(setCredentials({ token: data.data.data.access_token, isAuthenticated: true }));
          localStorage.removeItem("timer");
          setBtnTxt({ ...btnTxt, continue: "Continue" });
          navigate("/dashboard");
        }
      }
      if (state.body.otp_type === "FORGOT_PASSWORD") {
        const forgetData = { ...body, otp_type: "FORGOT_PASSWORD" };
        const data = await verifyOtp(forgetData);
        if (data.error) {
          setError(data.error.data.status.message);
          return;
        }
        if (data.data.status.code === 200) {
          localStorage.removeItem("timer");
          navigate("/forgot", { state: body });
        }
      }
    }
  };

  const reSendOTPHandler = async () => {
    const { data, error } = await generateOtp(state.body);
    setBtnTxt({ ...btnTxt, resend: "Sending OTP..." });
    if (error) {
      setBtnTxt({ ...btnTxt, resend: "Resend" });
      setError(error.data.status.message);
      return;
    }
    if (data) {
      localStorage.removeItem("timer");
      setOtp(data.otp);
      localStorage.setItem("timer", JSON.stringify({ minute: 1, seconds: 59 }));
      setBtnTxt({ ...btnTxt, resend: "Resend" });
    }
  };

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Verification Required
      </SuiTypography>
      <Grid
        className={classes.lineHight}
        columnSpacing={1}
        container
        rowSpacing={1}
        pt={0.5}
        pb={2.5}
        textAlign="left"
      >
        <Grid className={classes.padding} item>
          <SuiTypography variant="formSubHeading" fontWeight="regular">
            We have sent you an OTP on
          </SuiTypography>
        </Grid>
        <Grid className={classes.padding} item xs="auto">
          <SuiTypography variant="formSubHeading" fontWeight="regular">
            {`${state?.body.country_code} ${state?.body.mobile_no}`}
          </SuiTypography>
        </Grid>
        <Grid className={classes.padding} item xs={12}>
          OTP is: {otp}
        </Grid>
      </Grid>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <SuiOTPInput
            initialResandTime={{ minute: 1, seconds: 59 }}
            buttonVariant={{ continue: "contained", reSend: "contained" }}
            buttonColor={{ continue: "info", reSend: "secondary" }}
            buttonTxt={{
              continue: btnTxt.continue,
              reSend: btnTxt.resend,
            }}
            btnFns={{ getOTPFn: otpHandler, reSendOTPFn: reSendOTPHandler }}
            error={{ setErrorFn: setError, msg: error }}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};
export default EnterOTP;
