import React from "react";

import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import PlanProgressBarUI from "./PlanProgressBarUI";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const AgentCurrentPlan = () => {
  const { id } = useParams();
  const { data: Plans, isFetching } = useHandleGetApiQuery({
    url: `admin/agent_current_plan/${id}`,
  });
  return (
    <>
      {Plans && !isFetching ? (
        <>
          <Grid
            container
            columnSpacing={2}
            alignItems="center"
            sx={{ pb: 2, position: "sticky", top: 0, bgcolor: "#fff", zIndex: 1 }}
          >
            <Grid item>
              <SuiTypography fontSize="1.1rem" textTransform="capitalize" fontWeight="medium">
                Current Plan
              </SuiTypography>
            </Grid>
            <Grid item>
              <SuiBox
                component="img"
                sx={{ verticalAlign: "middle" }}
                height={24}
                src={Plans?.data.plan.logo}
                alt="Current Plan Logo"
              />
            </Grid>
            <Grid item>
              <SuiTypography fontSize=".9rem" textColor="text2" sx={{ lineHeight: ".9rem" }}>
                Plan Valid Till: <strong>{Plans?.data.plan_valid_till}</strong>
              </SuiTypography>
            </Grid>
            {Plans?.data.is_renew === true ? (
              <Grid item>
                <SuiTypography
                  fontSize="0.8rem"
                  textColor="white"
                  sx={{ bgcolor: "#FC3C3C", borderRadius: "50rem", px: 2, py: "4px", ml: 3 }}
                >
                  Expired
                </SuiTypography>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={2}>
            {Plans?.data.plan_criteria.map((item) => {
              return (
                <Grid item xs={12} lg={10} xl={9} xxl={7} key={item.criteria}>
                  <SuiBox
                    sx={{
                      p: "1rem .5rem",
                      border: "1px solid #E2EBEF",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <SuiTypography
                          variant="h6"
                          fontWeight="semiBold"
                          fontSize={{ xs: 15, md: 16 }}
                        >
                          {item.criteria}
                        </SuiTypography>
                      </Grid>
                      <Grid item>
                        <SuiTypography textColor="text2" fontSize={{ xs: 12, md: 14 }}>
                          {item.cycle}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <PlanProgressBarUI
                          nextPlan={item.quarter.next_level_name}
                          currentPlan={item.quarter.current_level_name}
                          currentValue={item.quarter.current_cycle_count}
                          downgradeInfo={item.downgrade_info}
                          minCount={item.quarter.min_count}
                          maintenanceCount={item.quarter.maintenance_count}
                          nextValue={item.quarter.min_upgrade_count}
                        />
                      </Grid>
                    </Grid>
                  </SuiBox>
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 390px)"
        >
          {isFetching ? (
            <CommonSpinner size="100px" color={colors.info.main} />
          ) : (
            "This agent don't have any plan."
          )}
        </SuiBox>
      )}
    </>
  );
};

export default AgentCurrentPlan;
