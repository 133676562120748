import React, { useCallback } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Grid,
  FormLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import Edit from "Container/Icons/EditIcon";
import Delete from "Container/Icons/DeleteIcon";
import SuiBox from "components/SuiBox";
import SuiSearchBox from "components/SuiSearchBox";
import SuiTable from "components/SuiTable";
import SuiTablePagination from "components/SuiTablePagination";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { capitalize, getNewProperties } from "api/helper";
import SuiDropDownList from "components/SuiDropDownList";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getId } from "app/features/AuthSlice";
import { useDispatch } from "react-redux";
import { setSearchParams } from "app/features/AuthSlice";
import withGetContainer from "Container/withGetContainer";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import CompanyDropdown from "components/SuiDropDownList/CompanyDropdown";

const toolbar = {
  title: "List of Agent",
  button: {
    text: "Add New Agent",
    to: "add-agent",
  },
  button2: {
    text: "Import CSV",
    to: "import-agent",
  },
};
const headCells = [
  {
    id: "full_name",
    width: "auto",
    align: "left",
    sorting: true,
    label: "AGENT NAME",
  },
  {
    id: "agent_licence_id",
    width: "auto",
    align: "left",
    sorting: true,
    label: "AGENT ID",
  },
  {
    id: "policy_provider__company_name",
    width: "auto",
    align: "left",
    sorting: true,
    label: "COMPANY NAME",
  },
  {
    id: "mobile_no",
    width: "auto",
    align: "left",
    sorting: true,
    label: "MOBILE NUMBER",
  },
  {
    id: "agentsubscriptions__plan",
    width: "auto",
    align: "left",
    sorting: false,
    label: "CURRENT PLAN",
  },
  {
    id: "status",
    width: "auto",
    align: "center",
    sorting: false,
    label: "ACTIVE/INACTIVE",
  },
  {
    id: "actions",
    width: "auto",
    align: "right",
    sorting: false,
    label: "ACTIONS",
  },
];
const AgentTable = ({
  data,
  isLoading,
  isFetching,
  handleDelete,
  permissions,
  handleUpdateRequest,
  resetShorting,
  setResetShorting,
  searchParams,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      agent_id: "",
      policy_provider: "",
      mobile_no: "",
      email: "",
      pan_number: "",
      plan_id: "",
      status: "",
      page: 1,
    },
    validationSchema: yup.object({}),
    onSubmit: (values, { setFieldValue }) => {
      const search = getNewProperties(formik.initialValues, values);
      dispatch(setSearchParams({ ...search, page: 1 }));
      setFieldValue("page", 1);
    },
  });
  const { data: PlaneFeatures, isLoading: Loading } = useHandleGetApiQuery({
    url: "admin/filter/plans",
    params: { ordering: "level" },
  });
  const handleChange = (event, value) => {
    formik.setFieldValue("page", value);
    dispatch(setSearchParams({ ...searchParams, page: value }));
  };
  const handleResetForm = useCallback(() => {
    formik.resetForm();
    setResetShorting(true);
    dispatch(setSearchParams({}));
  }, []);
  const handleEdit = useCallback(
    (data) => () => {
      const editData = {
        company_name: data.policy_provider ? data.policy_provider : "",
        agent_id: data.agent_id,
        pan_number: data.pan_number,
        service_experience: data.service_experience,
        languages_know: data.languages_know,
        subscription_plan: data.subscription_plan,
        first_name: data.first_name,
        middle_name: data.middle_name ? data.middle_name : "",
        last_name: data.last_name,
        email: data.email,
        title: data.title,
        country_code: data.country_code,
        mobile_no: data.mobile_no,
      };
      dispatch(getId(data.agent_id));
      navigate(`edit-agent/${data.agent_id}`, { state: editData });
    },
    []
  );

  const handleUpdateStatus = useCallback(
    (id) => (e) => {
      const udatingState = e.target.checked ? "Active" : "Inactive";
      const currentState = !e.target.checked ? "Active" : "Inactive";
      const buttonColor = e.target.checked ? "info" : "error";
      handleUpdateRequest({
        data: { status: e.target.checked ? "ACTIVE" : "INACTIVE" },
        url: `admin/agent/${id}`,
        title: "Agent",
        subTitle: "",
        currentState,
        udatingState,
        buttonColor,
      });
    },
    []
  );
  const checkValue = (value) => (value ? value : "--");
  const rows = data?.data.results.map((row) => {
    const labelId = row.agent_id;
    const fullName = `${row.title} ${row.first_name} ${row.last_name}`;
    const companyName = row.policy_provider;
    const mobileNum = `${row.country_code} ${row.mobile_no}`;
    const currentPlan = capitalize(row.plan_name ? row.plan_name : "");
    return (
      <TableRow hover key={labelId}>
        <TableCell>{capitalize(fullName)}</TableCell>
        <TableCell>{checkValue(row.agent_licence_id)}</TableCell>
        <TableCell>{checkValue(companyName)}</TableCell>
        <TableCell>{mobileNum}</TableCell>
        <TableCell>{checkValue(currentPlan)}</TableCell>
        <TableCell align="center">
          <Switch
            color="success"
            checked={row.status === "INACTIVE" ? false : true}
            onChange={handleUpdateStatus(row.agent_id)}
            disabled={!permissions?.update}
          />
        </TableCell>
        <TableCell align="right">
          <SuiBox display="flex" justifyContent="flex-end">
            {permissions?.update ? (
              <IconButton sx={{ mr: "6px" }} onClick={handleEdit(row)}>
                <Edit />
              </IconButton>
            ) : null}
            {permissions?.delete ? (
              <IconButton onClick={handleDelete({ url: `admin/agent/${row.agent_id}` })}>
                <Delete />
              </IconButton>
            ) : null}
          </SuiBox>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox component="form" mx={1}>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={6}>
                <FormLabel htmlFor="name">Agent Name</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Agent Name"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="agent_id">Agent ID</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Agent ID"
                  id="agent_id"
                  name="agent_id"
                  value={formik.values.agent_id}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="policy_provider">Insurance Company</FormLabel>
                <CompanyDropdown
                  select={{
                    label: "Select Company",
                    name: "policy_provider",
                    val: formik.values.policy_provider,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="mobile_no">Mobile Number</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Mobile Number"
                  id="mobile_no"
                  name="mobile_no"
                  value={formik.values.mobile_no}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="pan_number">PAN Number</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="PAN Number"
                  id="pan_number"
                  name="pan_number"
                  value={formik.values.pan_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="plan_name">Current Plan</FormLabel>
                <SuiDropDownList
                  data={PlaneFeatures?.data}
                  isLoading={Loading}
                  select={{
                    label: "Select Current Plan",
                    name: "plan_id",
                    val: formik.values.plan_id,
                    fn: formik.handleChange,
                    key: "plan_id",
                    valueKey: "plan_id",
                    labelName: "name",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="status">Status</FormLabel>
                <Select
                  name="status"
                  value={formik.values.status}
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    "&> .MuiSelect-select": {
                      color: formik.values.status !== "" ? "inherit" : "#AAAEB1 !important",
                    },
                  }}
                  inputProps={{ id: "status" }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">Select Status</MenuItem>
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <SuiBox my={2}>
                  <SuiButton
                    type="button"
                    size="small"
                    buttonColor="info"
                    sx={{ px: "1rem", fontSize: "14px", marginRight: "14px" }}
                    onClick={(e) => {
                      setResetShorting(true);
                      formik.handleSubmit(e);
                    }}
                    disabled={!formik.dirty}
                  >
                    Search
                  </SuiButton>
                  <SuiButton
                    type="reset"
                    onClick={handleResetForm}
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem", fontSize: "14px" }}
                  >
                    Reset
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <SuiTable
          reset={resetShorting}
          setReset={setResetShorting}
          permission={permissions?.create}
          isFetching={isFetching}
          isLoading={isLoading}
          toolbar={toolbar}
          tablehead={headCells}
          rows={rows}
        />
      </SuiBox>
      <SuiTablePagination
        totalData={data?.data.count}
        totalShowing={data?.data.results.length}
        currentPage={{ page: formik.values.page, fn: handleChange }}
      />
    </>
  );
};

export default withGetContainer({
  WrappedComponent: AgentTable,
  url: "admin/agents",
  title: "Agent",
  message: "",
});
