import React from "react";
import PropTypes from "prop-types";

import SuiDropDownList from "components/SuiDropDownList";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";

const CompanyDropdown = ({ select, value = "policy_providers_id" }) => {
  const { data: Company, isLoading } = useHandleGetApiQuery({
    url: "admin/filter/policy-providers",
    params: { pagination: false, ordering: "company_name" },
  });
  return (
    <SuiDropDownList
      data={Company?.data}
      isLoading={isLoading}
      select={{
        key: "policy_providers_id",
        valueKey: value,
        labelName: "company_name",
        label: select.label,
        name: select.name,
        val: select.val,
        fn: select.fn,
      }}
    />
  );
};
CompanyDropdown.propTypes = {
  select: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    fn: PropTypes.func,
  }),
};

export default CompanyDropdown;
