import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SuiTypography from "components/SuiTypography";
import CommonRating from "components/Rating";

const RatingMenu = ({ data }) => {
  const style = {
    "& .MuiPaper-root": {
      p: 0,
      "& .MuiMenuItem-root": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
        bgcolor: "#fff !important",
        cursor: "auto",
        borderRadius: "0 !important",
        borderBottom: "1px solid #E6EDF0",
        "&:last-of-type": {
          borderBottom: "0",
        },
      },
    },
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id="expand-ratings-button"
        aria-controls={open ? "rating-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ bgcolor: "#CFDADE !important", p: 0 }}
        aria-label="Expand-Ratings"
      >
        {anchorEl ? (
          <ExpandLessIcon sx={{ width: 18, height: 18 }} />
        ) : (
          <ExpandMoreIcon sx={{ width: 18, height: 18 }} />
        )}
      </IconButton>
      <Menu
        id="rating-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "expand-ratings-button",
        }}
        sx={style}
      >
        {data.map(({ rating_parameter_id, rating, name }) => (
          <MenuItem key={rating_parameter_id} component="div" onClick={handleClose}>
            <SuiTypography fontSize="0.9rem" textColor="text2">
              {name}
            </SuiTypography>
            <CommonRating readOnly value={rating} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default RatingMenu;
