import React from "react";

import { Grid } from "@mui/material";
import CommonAccordion from "./CommonAccordion";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import SuiTablePagination from "components/SuiTablePagination";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const CustomerAddress = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const { data, isFetching } = useHandleGetApiQuery(
    { url: `admin/customer-address/${id}`, params: { page: page } },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePageChange = React.useCallback((event, value) => {
    setPage(value);
  }, []);
  return (
    <Grid container className="h-100" rowSpacing={2} alignContent="space-between">
      <Grid item xs={12}>
        {data?.data.count > 0 && !isFetching ? (
          <Grid
            container
            spacing={2}
            alignContent="flex-start"
            sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "auto", pb: "2rem" }}
          >
            {data?.data.results.map((item) => {
              const fullAddress = item.address_line1.concat(
                ", ",
                !item.address_line2 ? "" : item.address_line2.concat(", "),
                !item.address_line3 ? "" : item.address_line3.concat(", "),
                !item.landmark ? "" : item.landmark.concat(", "),
                item.city,
                ", ",
                item.state,
                " - ",
                item.pincode
              );
              return (
                <Grid key={item.user_address_id} id={item.user_address_id} item xs={6}>
                  <CommonAccordion
                    id={item.user_address_id}
                    head={{ type: item.address_type, label: item.address_label }}
                    isDefault={item.is_default}
                  >
                    <SuiTypography fontSize="1rem" textTransform="capitalize">
                      {fullAddress}
                    </SuiTypography>
                  </CommonAccordion>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "This customer don't have any address."
            )}
          </SuiBox>
        )}
      </Grid>
      {data?.data.count > 10 ? (
        <Grid item sx={{ ml: "auto" }}>
          <SuiTablePagination
            margin="0px"
            totalData={data?.data.count}
            totalShowing={data?.data.results.length}
            currentPage={{ page: page, fn: handlePageChange }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomerAddress;
