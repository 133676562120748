import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

const ServicesIcon = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M22.1989 5.22846C21.5893 5.83807 20.601 5.83807 19.9914 5.22846C19.3818 4.61885 19.3818 3.63051 19.9914 3.0209C20.601 2.41129 21.5893 2.41129 22.1989 3.0209C22.8086 3.63051 22.8086 4.61885 22.1989 5.22846Z"
        stroke={colors[color].main}
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00852 22.9794C5.39891 23.589 4.41057 23.589 3.80096 22.9794C3.19135 22.3698 3.19135 21.3815 3.80096 20.7719C4.41057 20.1623 5.39891 20.1623 6.00852 20.7719C6.61813 21.3815 6.61813 22.3698 6.00852 22.9794Z"
        stroke={colors[color].main}
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6338 24.7668C11.413 24.9224 12.2057 25.0005 13.0003 25.0001C19.6277 25.0001 25.0003 19.6275 25.0003 13.0001C25.0003 10.107 23.9763 7.45315 22.2712 5.3808"
        stroke={colors[color].main}
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3893 1.23797C14.6027 1.07936 13.8024 0.999661 13 1.00007C6.37259 1.00007 1 6.37265 1 13.0001C1 15.8932 2.024 18.547 3.72912 20.6194"
        stroke={colors[color].main}
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 16.5431C11.0438 16.5431 9.45752 14.9569 9.45752 13.0001C9.45752 11.0435 11.0438 9.45726 13.0004 9.45726C14.9571 9.45726 16.5434 11.0435 16.5434 13.0001C16.5434 14.9569 14.9571 16.5431 13.0004 16.5431ZM20.0656 11.4707C19.8827 10.6199 19.5469 9.80939 19.0744 9.07862L20.009 8.14394L17.8515 5.98799L16.9176 6.92189C16.1868 6.44936 15.3763 6.11351 14.5255 5.93072V4.60989H11.4754V5.93072C10.6246 6.11351 9.81407 6.44936 9.08333 6.92189L8.14942 5.98799L5.99191 8.14394L6.92655 9.07862C6.45401 9.80939 6.11815 10.6199 5.93533 11.4707H4.61103L4.60938 14.5208H5.93528C6.11811 15.3716 6.45397 16.1822 6.9265 16.913L5.9883 17.8512L8.14425 20.0087L9.08323 19.0697C9.814 19.5423 10.6245 19.8781 11.4754 20.061V21.3904H14.5255V20.061C15.3764 19.8781 16.1869 19.5423 16.9177 19.0697L17.8566 20.0087L20.0126 17.8512L19.0744 16.913C19.5469 16.1822 19.8828 15.3716 20.0656 14.5208H21.3915V11.4707H20.0656Z"
        stroke={colors[color].main}
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09783 24.1464C9.09783 24.3754 8.91219 24.5611 8.68319 24.5611C8.45419 24.5611 8.26855 24.3754 8.26855 24.1464C8.26855 23.9174 8.45419 23.7318 8.68319 23.7318C8.91219 23.7318 9.09783 23.9174 9.09783 24.1464Z"
        fill={colors[color].main}
      />
      <path
        d="M17.7808 1.80489C17.7808 2.03389 17.5951 2.21953 17.3661 2.21953C17.1371 2.21953 16.9515 2.03389 16.9515 1.80489C16.9515 1.5759 17.1371 1.39026 17.3661 1.39026C17.5951 1.39026 17.7808 1.5759 17.7808 1.80489Z"
        fill={colors[color].main}
      />
    </svg>
  );
};

ServicesIcon.defaultProps = {
  color: "dark",
  size: "16px",
};
ServicesIcon.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ServicesIcon;
