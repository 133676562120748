import { useCallback, useState } from "react";

import { FormLabel, Switch } from "@mui/material";

import { getCountryCode } from "api/helper";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPhoneNo from "components/SuiPhoneNo";
import SuiButton from "components/SuiButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useGenerateOtpMutation } from "app/features/api/AuthApi";
import { MobileValidationSchema } from "Validation";
// import { Toast } from "components/Toaster";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useEffect } from "react";
const EnterMobileNum = () => {
  localStorage.removeItem("timer");
  const { state } = useLocation();
  const navigate = useNavigate();

  const [remember, setRemembe] = useState(false);
  const [loginOtpError, setLoginOtpError] = useState(false);
  const [generateOtp] = useGenerateOtpMutation();
  const formik = useFormik({
    initialValues: { country_code: "+91", mobile_no: "" },
    validationSchema: MobileValidationSchema,
    onSubmit: async (values) => {
      const body = {
        mobile_no: values.mobile_no,
        country_code: getCountryCode(values.country_code),
        otp_type: state === "FORGOT_PASSWORD" ? "FORGOT_PASSWORD" : "LOGIN",
      };
      const { data, error } = await generateOtp(body);
      if (data) {
        navigate("/verify-otp", { state: { body, data } });
        localStorage.removeItem("timer");
        localStorage.setItem("timer", JSON.stringify({ minute: 1, seconds: 59 }));
      }
      if (error) {
        setLoginOtpError(error.data.status.message);
        // Toast({ type: "error", message: error.data.status.message });
      }
    },
  });
  let heading;
  let subHeading = "Enter Registered Mobile number to get OTP";
  if (state === "OTP_LOGIN") {
    heading = "Login with OTP";
  } else if (state === "FORGOT_PASSWORD") {
    heading = "Forgot password";
  }
  const handleSetRememberMe = () => {
    setRemembe(!remember);
    localStorage.setItem("isRemember", !remember);
  };
  const handleCancel = useCallback(() => {
    navigate("/");
  }, []);
  useEffect(() => {
    if (loginOtpError && Object.keys(formik.errors)?.length > 0) {
      setLoginOtpError(false);
    }
  }, [formik.errors, loginOtpError]);
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        {heading}
      </SuiTypography>
      <SuiTypography component="p" variant="formSubHeading">
        {subHeading}
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="mobile_no">Mobile Number</FormLabel>
          <SuiPhoneNo
            countryCode={formik.handleChange}
            mobileNum={formik.handleChange}
            codeValue={getCountryCode(formik.values.country_code)}
            phoneValue={formik.values.mobile_no}
          />
          <ErrorMessageComponent
            touched={formik.touched.mobile_no}
            error={formik.errors.mobile_no}
          />
        </SuiBox>
      </SuiBox>
      {/* <SuiBox mt={2} mb={2}>
        <h6
          style={{
            fontSize: ".9em",
            color: "#FC3C3C",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {formik.errors.mobile_no}
        </h6>
      </SuiBox> */}
      <SuiBox my={2}>
        <Switch checked={remember} onChange={handleSetRememberMe} />
        <SuiTypography
          variant="button"
          fontWeight="regular"
          onClick={handleSetRememberMe}
          customClass="cursor-pointer user-select-none"
        >
          &nbsp;&nbsp;Remember Me
        </SuiTypography>
      </SuiBox>
      {Object.keys(formik.errors)?.length === 0 && loginOtpError && (
        <ErrorMessageComponent touched={loginOtpError} error={loginOtpError} />
      )}
      <SuiBox my={2}>
        <SuiButton
          tabIndex={2}
          type="submit"
          variant="contained"
          buttonColor="info"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          onClick={formik.handleSubmit}
        >
          Send OTP
        </SuiButton>
      </SuiBox>

      <SuiBox>
        <SuiButton variant="outlined" buttonColor="dark" fullWidth onClick={handleCancel}>
          Cancel
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default EnterMobileNum;
