import PropTypes from "prop-types";

const HomeAddIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.5 24.949">
    <g id="Group_766" transform="translate(-41 -276)">
      <path
        id="Path_458"
        d="M297.283,84.1H290.84v6.531h6.443Zm5.811-12.582-4.172-.009-.012,2.154,4.27,4.259Z"
        transform="translate(-238.184 205.937)"
        fill={color.fill ? color.fill : "#97AFB8"}
        fillRule="evenodd"
      />
      <path
        id="Path_459"
        d="M94.766,57.933l-9.881-10.6-9.542,10.6v8.98H94.766v-8.98ZM73.293,59.65l-1.416,1.456-1.872-1.82L84.833,44.2,99.505,59.286l-1.872,1.82-.764-.786v8.828H73.292Z"
        transform="translate(-29.005 231.801)"
        fill={color.path ? color.path : "#97AFB8"}
        fillRule="evenodd"
      />
    </g>
  </svg>
);

HomeAddIcon.propTypes = {
  color: PropTypes.any,
};

export default HomeAddIcon;
