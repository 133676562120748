import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import styles from "Container/Breadcrumbs/styles";

function Breadcrumbs({ icon, title, route, light }) {
  const classes = styles({ light });
  let routes = route.map((item) => {
    if (isNaN(+item)) {
      return item.split("-").join(" ");
    }
  });
  routes = routes.slice(0, route.length < 3 ? -1 : -2);
  const heading = isNaN(+title) ? title.split("-").join(" ") : routes[routes.length - 1];
  return (
    <SuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs className={classes.breadcrumbs}>
        <Link to="/dashboard">
          <SuiTypography
            component="span"
            variant="body2"
            textColor={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            customClass="line-height-0"
          >
            <Icon>{icon}</Icon>
          </SuiTypography>
        </Link>
        {routes.map(
          (el) =>
            heading !== el && (
              <Link to={`/${el.split(" ").join("-")}`} key={el} className="decoration-none">
                <SuiTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  textColor={light ? "white" : "dark"}
                  opacity={light ? 0.8 : 0.5}
                  customClass="line-height-0"
                >
                  {el === "ticket" ? "Tickets Management" : el}
                </SuiTypography>
              </Link>
            )
        )}
        <SuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          textColor={light ? "white" : "dark"}
          customClass="line-height-0"
        >
          {heading === "ticket" ? "Tickets Management" : heading}
        </SuiTypography>
      </MuiBreadcrumbs>
      <SuiTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        textColor={light ? "white" : "dark"}
        noWrap
      >
        {heading === "ticket" ? "Tickets Management" : heading}
      </SuiTypography>
    </SuiBox>
  );
}

Breadcrumbs.defaultProps = {
  light: false,
};

Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
