import { baseAPIService } from "app/features/api";
import { Logout } from "app/features/AuthSlice";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Toast } from "components/Toaster";
function LogOut() {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    const data = null;
    const remember = localStorage.getItem("isRemember");
    const token = localStorage.getItem(remember ? "refresh_token" : "token");
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}admin/logout`, data, {
      headers: {
        "Public-Key": "1d5ee304-5de4-4490-8cfa-4b23a2bb5058",
        "x-appversion": "web",
        authorization: `Bearer ${token}`,
      },
    });
    if (response?.data.status.code === 200) {
      Toast({ type: "success", message: response?.data.status.message });
      localStorage.removeItem("tokenExpiryTime");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInTime");
      localStorage.removeItem("isRemember");
      localStorage.removeItem("timer");
      dispatch(Logout());
      dispatch(baseAPIService.util.resetApiState());
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
}

export default LogOut;
