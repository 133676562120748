import React from "react";
import PropTypes from "prop-types";

import { Box, Grid, Typography, Tooltip } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import progressBarStyle from "./progressBarStyle";
import { appendZero, capitalize } from "api/helper";

const PlanProgressBarUI = ({
  currentValue,
  currentPlan,
  nextPlan,
  downgradeInfo,
  minCount,
  maintenanceCount,
  nextValue,
}) => {
  const classes = progressBarStyle();
  const progressWidth = () => {
    let progressWidthRatio;
    if (currentValue <= maintenanceCount) {
      progressWidthRatio = (currentValue * 100) / (maintenanceCount * 2) + 2;
    }
    if (currentValue > maintenanceCount) {
      if (!nextValue) {
        const totalCount = currentValue > maintenanceCount ? currentValue : maintenanceCount * 2;
        progressWidthRatio = (currentValue * 100) / totalCount + 2;
      } else {
        progressWidthRatio = (currentValue * 100) / nextValue + 2;
      }
    }
    return progressWidthRatio;
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ pt: 2 }}>
      <Grid item>
        <Typography sx={{ color: "#6F828A", fontSize: 12, pl: 1 }}>
          {capitalize(currentPlan)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: "#6F828A", fontSize: 12, pr: 1 }}>
          {capitalize(nextPlan)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.progressBox}>
          <Box className={classes.progressBoxBullets}>
            <Box component="span" className="trans" />
            <Box component="span" className={progressWidth() > 52 ? "active" : null} />
            <Box component="span" />
          </Box>
          <Box className={classes.progressbar} sx={{ width: `${progressWidth()}%` }}>
            <Box className={classes.countBox}>{appendZero(currentValue)}</Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <Grid item>
            <Typography
              sx={{
                color: "#303841",
                fontSize: 12,
                fontWeight: 500,
                pl: 1,
              }}
            >
              {minCount}
            </Typography>
          </Grid>
          <Grid item className={classes.midBox}>
            <Typography
              sx={{
                color: "#303841",
                fontWeight: 500,
                fontSize: 12,
                pl: 1,
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              {maintenanceCount}
              <Tooltip
                enterTouchDelay={0}
                title={downgradeInfo}
                PopperProps={{ className: classes.planToolTip }}
                arrow
              >
                <InfoOutlinedIcon sx={{ fontSize: 14, color: "#ED952F" }} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "#303841",
                fontWeight: 500,
                fontSize: 12,
                pr: 1,
              }}
            >
              {nextValue}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlanProgressBarUI.propTypes = {
  currentValue: PropTypes.number,
  totalValue: PropTypes.number,
};

export default PlanProgressBarUI;
