import React, { useCallback } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Grid,
  FormLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import Edit from "Container/Icons/EditIcon";
import Delete from "Container/Icons/DeleteIcon";
import SuiBox from "components/SuiBox";
import SuiSearchBox from "components/SuiSearchBox";
import SuiTable from "components/SuiTable";
import SuiTablePagination from "components/SuiTablePagination";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { capitalize, getNewProperties } from "api/helper";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { SearchSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useDispatch } from "react-redux";
import withGetContainer from "Container/withGetContainer";
import { setSearchParams } from "app/features/AuthSlice";

const toolbar = {
  title: "List of Customers",
  button: {
    text: "Add New Customer",
    to: "/customer/add-customer",
  },
};
const headCells = [
  {
    id: "usr_first_name",
    width: "30%",
    align: "left",
    sorting: true,
    label: "CUSTOMER NAME",
  },
  {
    id: "usr_mobile_no",
    width: "25%",
    align: "left",
    sorting: true,
    label: "MOBILE NUMBER",
  },
  {
    id: "usr_email",
    width: "25%",
    align: "left",
    sorting: true,
    label: "EMAIL",
  },
  {
    id: "status",
    width: "25%",
    align: "center",
    sorting: true,
    label: "ACTIVE/INACTIVE",
  },
  {
    id: "actions",
    width: "auto",
    align: "right",
    sorting: false,
    label: "ACTIONS",
  },
];

const CustomerTable = ({
  data,
  isLoading,
  isFetching,
  handleDelete,
  permissions,
  handleUpdateRequest,
  resetShorting,
  setResetShorting,
  searchParams,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      usr_name: "",
      usr_mobile_no: "",
      usr_email: "",
      status: "",
      page: 1,
    },
    validationSchema: SearchSchema,
    onSubmit: (values, { setFieldValue }) => {
      const search = getNewProperties(formik.initialValues, values);
      dispatch(setSearchParams({ ...search, page: 1 }));
      setFieldValue("page", 1);
    },
  });
  const handleChange = (event, value) => {
    formik.setFieldValue("page", value);
    dispatch(setSearchParams({ ...searchParams, page: value }));
  };
  const handleResetForm = useCallback(() => {
    formik.resetForm();
    setResetShorting(true);
    dispatch(setSearchParams({}));
  }, []);
  const handleEdit = useCallback(
    (id) => () => {
      navigate(`/customer/edit-customer/${id}`);
    },
    []
  );
  const handleUpdateStatus = useCallback(
    (id) => (e) => {
      const udatingState = e.target.checked ? "Active" : "Inactive";
      const currentState = !e.target.checked ? "Active" : "Inactive";
      const buttonColor = e.target.checked ? "info" : "error";
      handleUpdateRequest({
        data: { status: e.target.checked },
        url: `admin/customer/${id}`,
        title: "Customer",
        subTitle: "",
        currentState,
        udatingState,
        buttonColor,
      });
    },
    []
  );
  const rows = data?.data.results.map((row) => {
    const labelId = row.usr_mobile_no;
    const fullName = `${row.usr_title} ${row.usr_first_name} ${
      row?.usr_middle_name ? row?.usr_middle_name : ""
    } ${row.usr_last_name}`;
    const mobileNum = row.usr_mobile_no;
    const email = row.usr_email.toLowerCase();
    return (
      <TableRow hover key={labelId}>
        <TableCell>{capitalize(fullName)}</TableCell>
        <TableCell>{mobileNum}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell align="center">
          <Switch
            disabled={!permissions?.update}
            color="success"
            checked={row.status}
            onChange={handleUpdateStatus(row.id)}
          />
        </TableCell>
        <TableCell>
          <SuiBox display="flex" justifyContent="flex-end">
            {permissions?.update ? (
              <IconButton sx={{ mr: "6px" }} onClick={handleEdit(row.id)}>
                <Edit />
              </IconButton>
            ) : null}
            {permissions?.delete ? (
              <IconButton onClick={handleDelete({ url: `admin/customer/${row.id}` })}>
                <Delete />
              </IconButton>
            ) : null}
          </SuiBox>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox component="form" mx={1}>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={6}>
                <FormLabel htmlFor="customerName">Customer Name</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Customer Name"
                  id="customerName"
                  name="usr_name"
                  value={formik.values.usr_name}
                  onChange={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.usr_name}
                  error={formik.errors.usr_name}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="mobile_no">Mobile Number</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Mobile Number"
                  id="usr_mobile_no"
                  name="usr_mobile_no"
                  value={formik.values.usr_mobile_no}
                  onChange={(e) => {
                    if (isNaN(+e.target.value)) return false;
                    formik.handleChange(e);
                  }}
                />
                <ErrorMessageComponent
                  touched={formik.touched.usr_mobile_no}
                  error={formik.errors.usr_mobile_no}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Email"
                  id="usr_email"
                  name="usr_email"
                  value={formik.values.usr_email}
                  onChange={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.usr_email}
                  error={formik.errors.usr_email}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="status">Status</FormLabel>
                <Select
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    "&> .MuiSelect-select": {
                      color: formik.values.status !== "" ? "inherit" : "#AAAEB1 !important",
                    },
                  }}
                  inputProps={{ id: "status" }}
                >
                  <MenuItem value="">Select Status</MenuItem>
                  <MenuItem value={"ACTIVE"}>Active</MenuItem>
                  <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <SuiBox my={2}>
                  <SuiButton
                    type="button"
                    size="small"
                    buttonColor="info"
                    sx={{ px: "1rem", fontSize: "14px", marginRight: "14px" }}
                    onClick={(e) => {
                      setResetShorting(true);
                      formik.handleSubmit(e);
                    }}
                    disabled={!formik.dirty}
                  >
                    Search
                  </SuiButton>
                  <SuiButton
                    type="reset"
                    onClick={handleResetForm}
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem", fontSize: "14px" }}
                  >
                    Reset
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <SuiTable
          reset={resetShorting}
          setReset={setResetShorting}
          tableMinWidth="100%"
          isLoading={isLoading}
          isFetching={isFetching}
          toolbar={toolbar}
          tablehead={headCells}
          rows={rows}
          permission={permissions?.create}
        />
      </SuiBox>
      <SuiTablePagination
        totalData={data?.data.count}
        totalShowing={data?.data.results.length}
        currentPage={{ page: formik.values.page, fn: handleChange }}
      />
    </>
  );
};

export default withGetContainer({
  WrappedComponent: CustomerTable,
  url: "admin/customers",
  title: "Customer",
  message: "",
});
