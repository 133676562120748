import React from "react";
import { Card, CardMedia, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

const AgentCountCard = ({ count = 0, label, src }) => {
  return (
    <Card>
      <SuiBox px={1} py={2}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <SuiTypography
              variant="h5"
              fontSize={{ xs: 18, md: 20, xl: 22 }}
              fontWeight="bold"
              textColor="dark"
            >
              {count}
            </SuiTypography>
          </Grid>
          <Grid item xs={12}>
            <CardMedia
              component="img"
              alt={label}
              sx={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "left center",
                m: 0,
                borderRadius: 0,
              }}
              height="25px"
              image={src}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography
              variant="button"
              fontSize={{ xs: 12, lg: 14 }}
              textColor="text2"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {label}
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
};

export default AgentCountCard;
