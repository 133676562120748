import React from "react";
import { Checkbox, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import style from "layouts/agent/components/style";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import { stringAvatar, capitalize } from "api/helper";
import moment from "moment";
import CommonSpinner from "components/CommonSpinner";
import SuiTablePagination from "components/SuiTablePagination";
import colors from "assets/theme/base/colors";

const AgentRecommendation = () => {
  const classes = style();
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const { data: Recommendation, isFetching } = useHandleGetApiQuery(
    { url: `admin/agent-recommendations/${id}`, params: { page: page } },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePageChange = React.useCallback((event, value) => {
    setPage(value);
  }, []);
  return (
    <>
      <SuiTypography
        component="div"
        fontSize="1.1rem"
        fontWeight="medium"
        pb={2}
        sx={{ position: "sticky", top: 0, bgcolor: "#fff", zIndex: 1 }}
      >
        Recommendation
        {Recommendation?.data.count > 0 && (
          <SuiTypography pt={1} component="div" fontSize="1rem" textColor="text2" sx={{ pb: 2 }}>
            {`Received ${Recommendation?.data.count} recommendation`}
          </SuiTypography>
        )}
      </SuiTypography>
      <Grid container rowSpacing={2} alignContent="space-between">
        <Grid item xs={12}>
          {Recommendation?.data?.count > 0 && !isFetching ? (
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "auto", pb: "2rem" }}
            >
              {Recommendation?.data?.results.map((item) => {
                const fullName = capitalize(
                  `${item.user.usr_title} ${item.user.usr_first_name} ${item.user.usr_last_name}`
                );
                const altName = `${item.user.usr_first_name} ${item.user.usr_last_name}`;
                return (
                  <Grid key={item.recommendation_id} id={item.recommendation_id} item xs={6}>
                    <SuiBox
                      component="label"
                      htmlFor={item.recommendation_id}
                      customClass={`${classes.rec_box} ${item.is_shared ? "active" : ""}`}
                      px={1}
                      py={1.5}
                    >
                      <Checkbox
                        id={item.recommendation_id}
                        value={item.recommendation_id}
                        checked={item.is_shared}
                        className={classes.check_box}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container flexWrap="nowrap" alignItems="center" spacing={2}>
                            <Grid item>
                              <SuiAvatar
                                alt={altName}
                                src={item.user.usr_profile_image}
                                {...stringAvatar(altName, "", "50px", "1.1rem")}
                              />
                            </Grid>
                            <Grid item>
                              <SuiTypography fontSize={{ xs: 14, lg: 16 }} fontWeight="semiBold">
                                {fullName}
                              </SuiTypography>
                              <SuiTypography fontSize={12} textColor="text2">
                                {moment(new Date(item.created_at)).format("Do MMM YYYY | hh:mm A")}
                              </SuiTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <SuiTypography fontSize={14}>{item.recommendation}</SuiTypography>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 390px)"
            >
              {isFetching ? (
                <CommonSpinner size="100px" color={colors.info.main} />
              ) : (
                "This agent don't have any recommendation."
              )}
            </SuiBox>
          )}
        </Grid>
        {Recommendation?.data?.count > 10 && (
          <Grid item sx={{ ml: "auto" }}>
            <SuiTablePagination
              margin="0px"
              totalData={Recommendation?.data.count}
              totalShowing={Recommendation?.data.results.length}
              currentPage={{ page: page, fn: handlePageChange }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AgentRecommendation;
