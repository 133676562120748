import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import SuiInput from "components/SuiInput";
import SuiCountryCodeInput from "components/SuiCountryCodeInput";
import colors from "assets/theme/base/colors";

const useStyles = makeStyles(() => ({
  selectInput: {
    borderRadius: "0 !important",
    border: "none !important",
  },
  countryCodeBox: {
    width: "auto",
  },
  mobileNumBox: {
    flexGrow: "1",
  },
  inputBase: {
    borderRadius: "10px",
    border: "0.125rem solid #D3E0E5",
    overflow: "hidden",
  },
  inputBaseFocus: {
    borderRadius: "10px",
    border: "0.125rem solid #1656D7",
    overflow: "hidden",
  },
  searchBar: {
    position: "sticky",
    paddingTop: "8px",
    paddingBottom: "8px",
    top: "-8px",
    left: 0,
    background: "#fff",
    zIndex: "999",
    "& .actionBtn": {
      position: "s",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "&>div": {
        padding: "5px 10px",
        fontSize: ".8rem",
        fontWeight: 500,
        color: colors.info.main,
        cursor: "pointer",
      },
    },
  },
}));

const SuiPhoneNo = ({ countryCode, codeValue, phoneValue, mobileNum }) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const focusInput = () => {
    setFocus(true);
  };
  const bluarInput = () => {
    setFocus(false);
  };
  return useMemo(
    () => (
      <Grid
        container
        columnSpacing={0}
        className={!focus ? classes.inputBase : classes.inputBaseFocus}
      >
        <Grid item className={classes.countryCodeBox}>
          <SuiCountryCodeInput
            codeValue={codeValue}
            countryCode={countryCode}
            customClass={classes.selectInput}
          />
        </Grid>
        <Grid item className={classes.mobileNumBox}>
          <SuiInput
            inputProps={{ className: classes.selectInput }}
            value={phoneValue}
            inputSettungs={{ autoComplete: "off", required: true }}
            onChange={(e) => {
              if (isNaN(+e.target.value)) return false;
              mobileNum(e);
            }}
            onFocus={focusInput}
            onBlur={bluarInput}
            name="mobile_no"
            id="mobile_no"
            type="tel"
            placeholder="Mobile Number"
          />
        </Grid>
      </Grid>
    ),
    [codeValue, phoneValue, focus]
  );
};
SuiPhoneNo.propTypes = {
  countryCode: PropTypes.func,
  mobileNum: PropTypes.func,
  codeValue: PropTypes.string,
  phoneValue: PropTypes.string,
};
export default SuiPhoneNo;
