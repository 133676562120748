import moment from "moment";
import * as yup from "yup";
const onlyAlphabet = /^[aA-zZ\s]+$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const SUPPOERTED_IMAGE = ["image/jpeg", "image/jpg", "image/png"];
const urlValidate =
  /(^https?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi;
const emailValidate = /(?=^.{3,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,10}$/i;
const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(provideFile);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;

        resolve(imgDimensions);
      };
    };
  });
};
export const imageDimensionCheck = yup.addMethod(
  yup.mixed,
  "imageDimensionCheck",
  function (message, requiredWidth, requiredHeight, isDimension) {
    return this.test("image-width-height-check", message, async function (value) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      if (typeof value !== "object" && value.split("/")[0] === "https:") {
        return true;
      }
      if (!SUPPOERTED_IMAGE.includes(value.type)) {
        return createError({
          path,
          message,
        });
      }
      if (!value && value.size <= 1048576 * 7) {
        return createError({
          path,
          message: "Max allowed size is 7MB",
        });
      }
      const imgDimensions = await imageWidthAndHeight(value);

      if (
        (imgDimensions.width !== requiredWidth && isDimension) ||
        (imgDimensions.height !== requiredHeight && isDimension)
      ) {
        return createError({
          path,
          message: `The banner dimension should be ${requiredWidth}px to ${requiredHeight}px!`,
        });
      }

      return true;
    });
  }
);
export const userProfileSchema = yup.object().shape({
  title: yup.string().required("Please select title."),
  first_name: yup
    .string()
    .required("You must enter your first name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters", (val) => (val ? val.length < 50 : true)),
  middle_name: yup
    .string()
    .nullable()
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters.", (val) => (val ? val.length < 50 : true)),
  last_name: yup
    .string()
    .required("You must enter your last name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters.", (val) => (val ? val.length < 50 : true)),
  mobile_no: yup.number().required("You must enter your mobile number."),
  country_code: yup.string().required("You must enter country code."),
  email: yup.string().matches(emailValidate).required("You must enter your email address."),
});
const password = {
  newPassword: yup
    .string()
    .required("You must enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
      "You have entered an invalid password format."
    ),
  confirmPassword: yup
    .string()
    .required("You must confirm your password.")
    .oneOf([yup.ref("newPassword"), null], "Confirm password must be match."),
};
const UserCommonSchema = {
  title: yup.string().required("Please select title."),
  first_name: yup
    .string()
    .required("Please enter first name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters.", (val) => (val ? val.length < 50 : true)),
  middle_name: yup
    .string("Please enter valid data.")
    .nullable()
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters.", (val) => (val ? val.length < 50 : true)),
  last_name: yup
    .string()
    .required("Please enter last name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters.", (val) => (val ? val.length < 50 : true)),
  mobile_no: yup.number().required("Please enter mobile number."),
  email: yup
    .string()
    .required("Please enter email address.")
    .matches(emailValidate, "Please enter valid email address."),
  country_code: yup.string().required("Please enter country code."),
};
export const passwordSchema = yup.object({
  ...password,
});

export const subAdminAddSchema = yup.object({
  ...UserCommonSchema,
  assigned_role: yup.string().required("Please select role."),
  ...password,
});
export const subAdminUpdateSchema = yup.object({
  ...UserCommonSchema,
  assigned_role: yup.string().required("Please select role."),
});

export const CustomerSchema = yup.object({
  ...UserCommonSchema,
  ...password,
  referral_code: yup
    .string()
    .matches(/^[a-z0-9]+$/i, "Please enter valid referral code.")
    .nullable(),
});
export const CustomerUpdateSchema = yup.object({
  ...UserCommonSchema,
  referral_code: yup
    .string()
    .matches(/^[a-z0-9]+$/i, "Please enter valid referral code.")
    .nullable(),
});
export const PlanFeatureSchema = yup.object({
  name: yup
    .string()
    .required("Please enter plan feature name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
});

export const PolicyCategorySchema = yup.object({
  name: yup
    .string()
    .required("Please enter policy category name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
});

export const PolicyTypeSchema = yup.object({
  name: yup
    .string()
    .required("Please enter policy type name.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});
export const PolicyRatingParameterSchema = yup.object({
  product_type: yup.string().required("Please select policy type."),
  name: yup
    .string()
    .required("Please enter Parameter Name")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field.")
    .test("len", "Must be less than 50 characters", (val) => (val ? val.length < 50 : true)),
});

export const SearchSchema = yup.object({
  usr_name: yup.string("Please enter valid name."),
  usr_mobile_no: yup.number("Please enter valid mobile number."),
  usr_email: yup.string().matches(emailValidate, "Please enter valid email address."),
  page: yup.number("Please enter valid page number."),
});

export const passwordChangeSchema = yup.object({
  oldPassword: yup
    .string()
    .required("You must enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
      "You have entered an invalid password format."
    ),
  ...password,
});

export const CompanySchema = yup.object({
  company_name: yup
    .string()
    .required("You must enter company name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  logo: yup
    .mixed()
    .test("image-type", "Please upload logo in jpg, jpeg or png format.", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return SUPPOERTED_IMAGE.includes(value.type);
        }
      }
    })
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
  about: yup.string().required("You must enter company description."),
  premium_url: yup.string().matches(urlValidate, "Enter correct url!").nullable(),
  email: yup
    .string()
    .matches(emailValidate, "Please enter valid email address.")
    .required("You must enter your email address."),
  email_tld_name: yup
    .string()
    .matches(/(?=^.{2,256}$)[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i, "Please enter valid ltd name.")
    .required("Please enter ltd name."),
});

export const InsuranceBranchrAddSchema = yup.object({
  name: yup
    .string()
    .required("Branch name is required.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  state: yup.string().required("Please select a State."),
  city: yup.string().required("Please select a City."),
  policy_provider: yup.string().required("Please select an Insurance Company."),
  status: yup.boolean().required("Please select status."),
});

export const PlanSchema = yup.object({
  name: yup
    .string()
    .required("You must enter plan name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  level: yup.string().required("You must select level of plan."),
  features: yup.array().min(1, "Please select plan features.").required("Select plan features."),
  plan_criteria: yup.lazy((value) => {
    const newEntries = Object.keys(value).reduce(
      (acc, val) => ({
        ...acc,
        [val]: yup.number("Please enter number.").required("Please enter plan criteria." + val),
      }),
      {}
    );

    return yup.object().shape(newEntries);
  }),
  logo: yup
    .mixed()
    .test("image-type", "Please upload logo in jpg, jpeg or png format.", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return SUPPOERTED_IMAGE.includes(value.type);
        }
      }
    }),
  description: yup
    .string("Description must be string.")
    .required("You must enter plan description.")
    .nullable(),
  is_video_cover_enable: yup.boolean().required("Please select video cover enable."),
  allowed_recommendations: yup
    .number("Please enter number only.")
    .required("Please enter recomendations.")
    .typeError("Expected a value of type ${type} but got: ${value}")
    .test("Is positive?", "The number must be greater than 0!", (value) => value > 0),
});

export const MobileValidationSchema = yup.object({
  mobile_no: yup
    .string()
    .required("Please enter mobile number.")
    .min(10, "Your mobile number must have 10 digits.")
    .max(10, "Your mobile number must have 10 digits."),
  country_code: yup.string().required("Please select country code."),
});

export const LoginWithPasswordSchema = yup.object({
  mobile_no: yup
    .string()
    .required("Please enter mobile number.")
    .min(10, "Your mobile number must have 10 digits.")
    .max(10, "Your mobile number must have 10 digits."),
  country_code: yup.string().required("Please select country code."),
  password: yup
    .string()
    .required("Please enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
      "You have entered an invalid password format."
    ),
});

export const AgentAddSchema = yup.object({
  ...UserCommonSchema,
  ...password,
  agent_licence_id: yup.string("").nullable(),
  pan_number: yup.string(),
  subscription_plan: yup.string().required("Please select plan."),
  state: yup.number().required("Please select branch state."),
  city: yup.number().required("Please select branch city."),
  branch: yup.number().required("Please select branch."),
  policy_provider: yup.number().required("Please select company."),
  service_experience: yup
    .date()
    .max(new Date(), "Date should not be greater than today.")
    .typeError("Expected a value of type ${type} but got: ${value}")
    .nullable(),
});

export const AgentUpdateSchema = yup.object({
  ...UserCommonSchema,
  agent_licence_id: yup.string("").nullable(),
  pan_number: yup.string(),
  subscription_plan: yup.string().required("Please select plan."),
  state: yup.number().required("Please select branch state."),
  city: yup.number().required("Please select branch city."),
  branch: yup.number().required("Please select branch."),
  policy_provider: yup.number().required("Please select company."),
  service_experience: yup
    .date()
    .max(new Date(), "Date should not be greater than today.")
    .typeError("Expected a value of type ${type} but got: ${value}")
    .nullable(),
});

export const ELearningModuleSchema = yup.object({
  name: yup
    .string()
    .required("Module name is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  sequence_order: yup
    .number()
    .nullable()
    .required("Sequence order is required.")
    .integer("Decimal values are not allowed.")
    .min(1, "Less than 1 sequence order not allowed."),
  cover_image: yup
    .mixed()
    .nullable()
    .required("Please upload image cover.")
    .test(
      "image-type",
      "Please upload image cover jpg, jpeg or png.",
      (value) => value && SUPPOERTED_IMAGE.includes(value.type)
    )
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});
export const ELearningModuleUpdateSchema = yup.object({
  name: yup
    .string("Enter string only.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  sequence_order: yup
    .number()
    .integer("Decimal values are not allowed.")
    .min(1, "Less than 1 sequence order not allowed."),
  cover_image: yup
    .mixed()
    .test("image-type", "Please upload image cover in jpg, jpeg or png format.", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return SUPPOERTED_IMAGE.includes(value.type);
        }
      }
    })
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});
export const ELearningTopicSchema = yup.object().shape({
  module: yup.string().required("Module name is required."),
  description: yup.string().required("Topic Description is required."),
  content_type: yup.string().required("Topic contact type is required."),
  files: yup.mixed().when("content_type", {
    is: (content_type) => content_type === "PDF",
    then: yup
      .mixed()
      .required("Please upload PDF file.")
      .test("file-type", "Please upload PDF file.", (value) => {
        if (value) {
          if (value[0]?.file_name) {
            return true;
          } else {
            return value.type === "application/pdf";
          }
        }
      }),
    otherwise: yup.mixed().notRequired(),
  }),

  file_name: yup.string().when("content_type", {
    is: (content_type) => content_type === "VIDEO",
    then: yup.string().url("Please enter valid url.").required("Enter a url."),
    otherwise: yup.string().notRequired(),
  }),

  name: yup.string().required("Topic name is required."),
  content_duration: yup.string().required("Please enter content duration."),
  sequence_order: yup
    .number()
    .nullable()
    .integer("Decimal values are not allowed.")
    .min(1, "Less than 1 sequence order not allowed.")
    .required("Please enter order sequence."),
  cover_image: yup
    .mixed()
    .nullable()
    .required("Please upload image cover.")
    .test("image-type", "Please upload image cover jpg, jpeg or png.", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return SUPPOERTED_IMAGE.includes(value.type);
        }
      }
    })
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});
export const ELearningExamSchema = yup.object({
  module: yup.string().required("Module name is required."),
  topic: yup.string().required("Topic name is required."),
  passing_marks: yup
    .number("Please enter valid marks.")
    .min(1, "Negative marks are not allowed.")
    .required("Please enter marks."),
});
export const AdvertismentSchema = yup.object({
  banner_name: yup
    .string()
    .required("Post name is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),

  type: yup.string().required("Type  is required."),
  banner_image_url: yup
    .mixed()
    .when("type", {
      is: "COVER_IMAGE",
      then: yup
        .mixed()
        .imageDimensionCheck(
          "Please upload cover image in jpg, jpeg or png format",
          1920,
          600,
          true
        ),
    })
    .when("type", {
      is: (value) =>
        value === "LANDING_AD_BANNER" ||
        value === "USER_DASHBOARD_AD_BANNER" ||
        value === "AGENT_DASHBOARD_AD_BANNER",
      then: yup
        .mixed()
        .imageDimensionCheck(
          "Please upload landing ad banner image in jpg, jpeg or png format",
          1000,
          560,
          true
        ),
    })
    .imageDimensionCheck("Please upload image in jpg, jpeg or png format", false)
    .nullable()
    .required("Please upload image."),
  sequence_number: yup.number().required("Sequence number is required."),
  banner_url: yup.string().matches(urlValidate, "Enter correct url!").nullable(),
});

export const EmailSchema = yup.object({
  email_tmp_name: yup
    .string()
    .required("Post name is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  email_tmp_subject: yup
    .string()
    .required("Post subject is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  email_tmp_desc: yup
    .string()
    .required("Post description is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  user_type: yup.string().required("Post type is required."),
});

export const ServiceIntegrationSchema = yup.object({
  service_request: yup.string().required("Please select service request."),
  policy_provider: yup.string().required("Please select service provider."),
  service_integration_name: yup.string().nullable(),
  integration_type: yup.string().nullable(),
  integration_format: yup
    .string()
    .when("integration_type", {
      is: "API",
      then: yup.string().required("Please Select integration format."),
    })
    .nullable(),
  integration_request_template: yup
    .string()
    .when("integration_type", {
      is: "API",
      then: yup.string().required("Please generate request template."),
    })
    .nullable(),
  integration_response_template: yup
    .string()
    .when("integration_type", {
      is: "API",
      then: yup.string().required("Please generate response template."),
    })
    .nullable(),
  integration_subject: yup.string().nullable(),
  integration_to_address: yup.string().nullable(),
  fields: yup.array(),
  end_point_url: yup.string().nullable(),
  proof_document: yup.array(),
  method: yup.string().nullable(),
  ticket_end_point_method: yup.string().nullable(),
  ticket_end_point_url: yup.string().matches(urlValidate, "Enter correct url!").nullable(),
  ticket_request_template: yup.string().nullable(),
  ticket_response_template: yup.string().nullable(),
});

export const NotificationSchema = yup.object({
  title: yup
    .string()
    .required("Please enter title.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  message: yup.string().required("Please enter message."),
  notification_type: yup.string().required("Please select type."),
  user_type: yup.string().required("Please select user type."),
  agent: yup.mixed().when("user_type", {
    is: (user_type) => user_type === "AGENT",
    then: yup.array().min(1, "Please select agent.").required("Select plan agent."),
    otherwise: yup.mixed().notRequired(),
  }),
  user: yup.mixed().when("user_type", {
    is: (user_type) => user_type === "USER",
    then: yup.array().min(1, "Please select user.").required("Select plan user."),
    otherwise: yup.mixed().notRequired(),
  }),
  image: yup
    .mixed()
    .nullable()
    .imageDimensionCheck("Please upload image in jpg, jpeg or png format.", false),
});

export const PagesSchema = yup.object({
  title: yup
    .string()
    .required("Please enter title.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  name: yup
    .string()
    .required("Please enter name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  description: yup.string().required("Please enter description"),
  slug: yup.string().required("Please enter slug."),
});

export const ProofDocumentSchema = yup.object({
  title: yup
    .string()
    .required("Please enter title.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  description: yup.string().required("Please enter description."),
});
export const ServiceMasterSchema = yup.object({
  name: yup
    .string("Name should be string.")
    .required("Please enter name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  description: yup.string("Description should be string.").required("Please enter description."),
  tat: yup.number("TAT should be number.").required("Please enter TAT time."),
  ping_duration: yup
    .number("Ping duration should be number.")
    .required("Please enter Ping duration time."),
  ping_type: yup.string().required("Please select ping type."),
  tat_type: yup.string().required("Please select tat type."),
  display_order: yup
    .number("display order should be number.")
    .required("Please enter display order."),
  logo: yup
    .mixed()
    .nullable()
    .required("Please upload logo.")
    .test("image-type", "Please upload logo in jpg, jpeg or png format.", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return SUPPOERTED_IMAGE.includes(value.type);
        }
      }
    })
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});
export const RatingParameterSchema = yup.object({
  name: yup
    .string()
    .required("Parameter name is required.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
});

export const ProfileImageSchema = yup.object({
  profile_image: yup
    .mixed()
    .test(
      "image-type",
      "Please upload profile photo in jpg, jpeg or png format.",
      (value) => value && SUPPOERTED_IMAGE.includes(value.type)
    )
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});

export const PolicySchema = yup.object({
  product_name: yup
    .string("Name should be string.")
    .required("Please enter name.")
    .matches(onlyAlphabet, "Only alphabets are allowed for this field."),
  puin: yup
    .string("PUIN should be alphanumeric.")
    .required("Please enter puin.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  policy_provider: yup
    .number("policy provider should be numeric.")
    .required("Please enter policy provider.")
    .nullable(),
  type: yup.string("type should be string.").required("Please enter type.").nullable(),
  // categories: yup.array().min(1, "Please select categories.").required("Select plan categories."),
  poleasy_rating: yup
    .number("Poleasy rating should be numeric.")
    .max(5, "Poleasy rating cannot be more than 5.")
    .required("Please enter poleasy rating.")
    .nullable(),
  // about: yup.string("about should be string.").required("Please enter about.").nullable(),
  key_features: yup
    .array()
    .min(1, "Please enter key features.")
    .required("Enter plan key features."),
  website_link: yup.string().url("website link should be a valid url.").nullable(),
  brochure: yup
    .mixed()
    .nullable()
    .required("Please upload brochure.")
    .test("file-type", "Please upload brochure in pdf format.", (value) => {
      if (value && typeof value === "object") {
        return ["application/pdf"].includes(value.type);
      } else {
        return true;
      }
    })
    .test("is-valid-size", "Max allowed size is 100MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 100;
        }
      }
    }),
});

export const LanguageTranslationSchema = yup.object({
  language: yup
    .number()
    .nullable()
    .required("Sequence order is required.")
    .integer("Decimal values are not allowed.")
    .min(1, "Less than 1 sequence order not allowed."),
  file_path: yup
    .mixed()
    .nullable()
    .required("Please upload file.")
    .test("file-type", "Please upload file in JSON format.", (value) => {
      if (value && typeof value === "object") {
        return ["application/json"].includes(value.type);
      } else {
        return true;
      }
    })
    .test("is-valid-size", "Max allowed size is 7MB", (value) => {
      if (value) {
        if (typeof value !== "object" && value.split("/")[0] === "https:") {
          return true;
        } else {
          return value && value.size <= 1048576 * 7;
        }
      }
    }),
});

export const DateValidateSchema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), "Start date should not be greater than today.")
    .typeError("Please enter valid start date.")
    .nullable(),
  end_date: yup
    .date()
    .max(new Date(), "End date should not be greater than today.")
    .when("start_date", {
      is: (start_date) => moment.isDate(start_date),
      then: yup
        .date()
        .required("Please enter end date.")
        .typeError("Please enter valid end date.")
        .nullable(),
    })
    .test("lessthenSD", "End date should not be less than start date.", (value, testContext) => {
      return !moment(value).isBefore(testContext.parent.start_date);
    })
    .typeError("Please enter valid end date.")
    .nullable(),
});
