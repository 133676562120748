import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const SuiBackdrop = ({ open }) => {
  const { info } = colors;
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        invisible={true}
        open={open}
      >
        <CommonSpinner size="100px" color={info.main} />
      </Backdrop>
    </div>
  );
};

export default SuiBackdrop;
