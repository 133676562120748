import { useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import gradientChartLine from "assets/theme/functions/gradientChartLine";
import configs from "Container/Charts/LineCharts/GradientLineChart/configs";
import colors from "assets/theme/base/colors";
import { Box } from "@mui/material";

function GradientLineChart({ title, height, chart }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options } = chartData;
  useEffect(() => {
    const chartDatasets = chart.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 1,
      borderColor: colors[dataset.color].main,
      fill: true,
      maxBarThickness: 1,
      backgroundColor: gradientChartLine(chartRef.current.children[0], colors.primary.main),
    }));

    setChartData(configs(chart.labels, chartDatasets, chart.xTicks, chart.xTitle));
  }, [chart]);

  const toolTipLine = {
    id: "toolTipLine",
    beforeDraw(chart) {
      const { ctx, tooltip, chartArea } = chart;

      if (tooltip._active && tooltip._active.length) {
        ctx.save();

        const activePoint = tooltip._active[0];

        ctx.beginPath();
        ctx.setLineDash([3, 4]);
        ctx.moveTo(activePoint.element.x, chartArea.top);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.success.main;
        ctx.stroke();
        ctx.restore();

        ctx.beginPath();
        ctx.moveTo(activePoint.element.x, chartArea.bottom);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.success.main;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  const renderChart = (
    <SuiBox p={2}>
      {title ? (
        <SuiBox mb={2}>
          <SuiTypography variant="h6" fontSize={18}>
            {title}
          </SuiTypography>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <Box ref={chartRef} height={height}>
            <Line data={data} options={options} plugins={[toolTipLine]} />
          </Box>
        ),
        [chartData, height]
      )}
    </SuiBox>
  );

  return title ? <Card>{renderChart}</Card> : renderChart;
}

GradientLineChart.defaultProps = {
  title: "",
  height: "19.125rem",
};

GradientLineChart.propTypes = {
  chart: PropTypes.any.isRequired,
};

export default GradientLineChart;
