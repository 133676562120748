import { useCallback, useState } from "react";

import { FormLabel, Icon } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { passwordSchema } from "Validation";
import { useResetPasswordMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState(undefined);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      const resetData = { ...state, new_password: values.newPassword };
      const data = await resetPassword(resetData);
      if (data.error) {
        setError(data.error.data.status.message);
        return;
      }
      if (data.data.status.code === 200) {
        navigate("/");
      }
    },
  });
  const handleCancel = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Reset Password
      </SuiTypography>
      <SuiTypography component="p" variant="formSubHeading">
        Enter New Password
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={2}>
          <FormLabel htmlFor="newPassword">New Password</FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "newPassword", placeholder: "New Password" }}
            value={formik.values.newPassword}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.newPassword}
            error={formik.errors.newPassword}
          />
          <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
            <Icon
              fontSize="small"
              sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}
            >
              info_outlined
            </Icon>
            <SuiTypography
              fontWeight="medium"
              textColor="text2"
              sx={{ fontSize: "0.7rem" }}
              px={0.5}
              py={0}
            >
              Note: Password required at least 8 characters, 1 number, 1 Alphabet, 1 special
              character.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
          <SuiPasswordInput
            index={2}
            attr={{ name: "confirmPassword", placeholder: "Confirm Password" }}
            value={formik.values.confirmPassword}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.confirmPassword}
            error={formik.errors.confirmPassword}
          />
        </SuiBox>
      </SuiBox>
      <SuiBox mt={2} mb={2}>
        <h6
          style={{
            fontSize: ".9em",
            color: "#FC3C3C",
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error}
        </h6>
      </SuiBox>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={3}
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty}
          variant="contained"
          buttonColor="info"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {isLoading ? "Changing...." : "Change Password"}
        </SuiButton>
      </SuiBox>
      <SuiBox textAlign="center">
        <SuiButton
          tabIndex={4}
          disabled={formik.isSubmitting}
          variant="outlined"
          buttonColor="dark"
          fullWidth
          onClick={handleCancel}
        >
          Cancel
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default ForgetPassword;
