import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";

export default makeStyles(({ breakpoints }) => {
  return {
    list_label: { fontSize: ".8rem !important", fontWeight: "500 !important", color: "#6E828A" },
    area_wraper: {
      maxHeight: "197px",
      paddingBottom: "5px",
      overflowY: "auto",
    },
    area_box: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      borderRadius: "5px",
      backgroundColor: "#F4F6F7",
      marginBottom: "5px",
      padding: "5.5px 12px",
      "&:last-of-type": { marginBottom: "0" },
      "& .lables": {
        fontSize: ".9rem",
      },
    },
    searchBar: {
      position: "sticky",
      paddingTop: "8px",
      paddingBottom: "8px",
      top: "-8px",
      left: 0,
      background: "#fff",
      zIndex: "999",
      "& .actionBtn": {
        position: "s",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "&>div": {
          padding: "5px 10px",
          fontSize: ".8rem",
          fontWeight: 500,
          color: colors.info.main,
          cursor: "pointer",
        },
      },
    },
    checkbox_wrap: {
      marginBottom: "5px !important",
      "&.MuiMenuItem-root": {
        backgroundColor: "transparent !important",
        color: "#303841 !important",
      },
      "&:last-of-type": { marginBottom: "0 !important" },
      "& .Checkbox_box": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: "100%",
        "&>span": {
          fontSize: "1rem",
          fontWeight: 500,
        },
      },
    },
    achievement_box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      border: `2px solid ${colors.success.main}`,
      boxShadow: `0px 4px 0px ${colors.success.main}`,
      borderRadius: 19,
      gap: 10,
      padding: "0.5rem 2rem",
      [breakpoints.down("xl")]: {
        borderRadius: 16,
        gap: 12,
      },
      [breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "1rem",
        gap: 2,
      },
      "& .count": {
        color: colors.success.main,
        fontSize: "3.5rem",
        [breakpoints.down("xxl")]: {
          fontSize: "calc(7vw - 45px)",
        },
        [breakpoints.down("lg")]: {
          fontSize: "calc(9.5vw - 42px)",
        },
        [breakpoints.down("md")]: {
          fontSize: "calc(11.5vw - 35px)",
        },
        [breakpoints.down("sm")]: {
          fontSize: "2rem",
        },
      },
      "& .label": {
        color: "#737677",
        maxWidth: 110,
        lineHeight: 1.2,
        fontSize: "1.1rem",
        [breakpoints.down("xxl")]: {
          fontSize: "1rem",
        },
        [breakpoints.down("md")]: {
          fontSize: "1.19rem",
        },
        [breakpoints.down("sm")]: {
          fontSize: "1.2rem",
        },
      },
      "& .divider": {
        height: 45,
        borderLeft: "1px solid #D0D2D3",
        [breakpoints.down("xl")]: {
          height: 35,
        },
        [breakpoints.down("md")]: {
          height: 25,
        },
        [breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    rec_box: {
      border: "1px solid #E2EBEF",
      borderRadius: 7,
      position: "relative",
      display: "block",
      backgroundColor: "#fff",
      "&.active": {
        border: `2px solid ${colors.success.main}`,
      },
    },
    check_box: {
      position: "absolute",
      right: 11,
      top: -11,
      backgroundColor: "#fff",
      pointerEvents: "none",
      zIndex: 1,
    },
  };
});
