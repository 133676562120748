import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

const SuiOTPInput = forwardRef(({ buttonVariant, buttonColor, buttonTxt, btnFns, error }, ref) => {
  const [otp, setOTP] = useState(new Array(6).fill(""));
  const [timerLeft, setTimerLeft] = useState(
    localStorage.getItem("timer") || { minute: 1, seconds: 59 }
  );
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOTP([...otp.map((d, idx) => (idx === index ? element.value.trim() : d))]);
    error.setErrorFn(undefined);
  };
  useEffect(() => {
    if (timerLeft) {
      const t = typeof timerLeft === "string" ? JSON.parse(timerLeft) : timerLeft;
      setTimerLeft(t);
      setMinutes(t.minute);
      setSeconds(t.seconds);
    }
  }, [timerLeft]);
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex;
      if (next > 1) {
        elmnt.target.form.elements[(next - 1) * 2 - 2].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        if (!elmnt.target.value) {
          return;
        }
        elmnt.target.form.elements[(next - 1) * 2 + 2].focus();
      }
    }
  };
  const submit = (e) => {
    e.preventDefault();
    error.setErrorFn(undefined);
    btnFns.getOTPFn(otp);
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        localStorage.setItem("timer", JSON.stringify({ minute: 1, seconds: seconds - 1 }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          localStorage.setItem("timer", JSON.stringify({ minute: 0, seconds: 0 }));
        } else {
          setMinutes(minutes - 1);
          localStorage.setItem("timer", JSON.stringify({ minute: minutes - 1, seconds: seconds }));
          setSeconds(59);
        }
      }
    }, 958.33);
    return () => {
      clearInterval(myInterval);
    };
  });
  const timer = `${minutes < 10 ? `0${minutes}` : minutes} : ${
    seconds < 10 ? `0${seconds}` : seconds
  }`;

  return (
    <SuiBox ref={ref}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Grid container columnSpacing={{ xs: 0.5, md: 1, lg: 1.5 }}>
            {otp.map((data, index) => (
              <Grid item xs={2} key={`otp-${index}`}>
                <SuiInput
                  inputProps={{
                    style: { textAlign: "center" },
                    maxLength: "1",
                    tabIndex: index + 1,
                    autoComplete: "off",
                    required: true,
                  }}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyUp={(e) => inputfocus(e)}
                  onFocus={(e) => e.target.select()}
                  name={`otp${index + 1}`}
                  id={`otp${index + 1}`}
                  type="tel"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <SuiBox pb={0.5} pr={2} pt={2} textAlign="right">
        {timer !== "00 : 00" && (
          <SuiTypography variant="caption">
            Resend OTP after{" "}
            {
              <SuiTypography variant="caption" fontWeight="bold">
                {timer}
              </SuiTypography>
            }{" "}
            Sec
          </SuiTypography>
        )}
      </SuiBox>
      <SuiBox mt={2} mb={2} textAlign="center">
        <h6
          style={{
            fontSize: ".8rem",
            color: "red",
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error.msg}
        </h6>
      </SuiBox>
      <SuiBox mt={3} mb={1.5}>
        <SuiButton
          type="submit"
          onClick={submit}
          variant={buttonVariant.continue}
          buttonColor={buttonColor.continue}
          fullWidth
          disabled={
            buttonTxt.continue === "Verifing OTP..." || buttonTxt.reSend === "Sending OTP..."
              ? true
              : minutes === 0 && seconds === 0
              ? true
              : false
          }
        >
          {buttonTxt.continue}
        </SuiButton>
      </SuiBox>
      <SuiBox mb={1}>
        <SuiButton
          disabled={
            buttonTxt.continue === "Verifing OTP..." || buttonTxt.reSend === "Sending OTP..."
              ? true
              : minutes === 0 && seconds === 0
              ? false
              : true
          }
          onClick={() => {
            error.setErrorFn(undefined);
            btnFns.reSendOTPFn();
            setMinutes(1);
            setSeconds(59);
          }}
          variant={buttonVariant.reSend}
          buttonColor={buttonColor.reSend}
          fullWidth
        >
          {buttonTxt.reSend}
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
});
SuiOTPInput.displayName = "SuiOTPInput";
SuiOTPInput.defaultProps = {
  initialResandTime: {
    minute: 1,
    seconds: 59,
  },
  buttonVariant: {
    continue: "gradient",
    reSend: "gradient",
  },
  buttonColor: {
    continue: "primary",
    reSend: "light",
  },
  buttonTxt: {
    continue: "Continue",
    reSend: "Resend OTP",
  },
};
SuiOTPInput.propTypes = {
  initialResandTime: PropTypes.shape({
    minute: PropTypes.number,
    seconds: PropTypes.number,
  }),
  buttonVariant: PropTypes.shape({
    continue: PropTypes.oneOf(["gradient", "text", "contained", "outlined"]),
    reSend: PropTypes.oneOf(["gradient", "text", "contained", "outlined"]),
  }),

  buttonColor: PropTypes.shape({
    continue: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    reSend: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
  }),
  buttonTxt: PropTypes.shape({
    continue: PropTypes.string,
    reSend: PropTypes.string,
  }),
  btnFns: PropTypes.shape({
    getOTPFn: PropTypes.func.isRequired,
    reSendOTPFn: PropTypes.func.isRequired,
  }),
  error: PropTypes.shape({
    setErrorFn: PropTypes.func.isRequired,
    msg: PropTypes.any,
  }),
};

export default SuiOTPInput;
