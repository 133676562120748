import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";

const SuiTitleInput = ({ value, setValue }) => {
  const option = ["Mr.", "Mrs.", "Ms."];
  return React.useMemo(
    () => (
      <Select
        name="title"
        fullWidth
        value={value ? value : ""}
        renderValue={() => (value ? value : "Select")}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        inputProps={{ required: true, id: "title" }}
        onChange={setValue}
        sx={{
          "&> .MuiSelect-select": {
            color: value !== "" ? "inherit" : "#AAAEB1 !important",
          },
        }}
      >
        <MenuItem value="">Select Title</MenuItem>
        {option.map((items) => (
          <MenuItem key={items} value={items}>
            {items}
          </MenuItem>
        ))}
      </Select>
    ),
    [value]
  );
};

SuiTitleInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default SuiTitleInput;
