export default (xTitle, labels = [], revenue = [], xTicks = true) => ({
  xTitle,
  xTicks,
  labels,
  datasets: [
    {
      label: "Revenue",
      color: "info",
      data: [...revenue],
      borderWidth: 1,
    },
  ],
});
