import React from "react";
import { TableCell, TableRow } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTable from "components/SuiTable";
import withGetContainer from "Container/withGetContainer";
import SuiTablePagination from "components/SuiTablePagination";
import { setSearchParams } from "app/features/AuthSlice";
import { getNewProperties } from "api/helper";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import DownloadIcon from "Container/Icons/DownloadIcon";
const toolbar = {
  title: "List Of Bulk Imports",
};
const headCells = [
  {
    id: "module_name",
    width: "auto",
    align: "left",
    sorting: false,
    label: "MODULE NAME",
  },
  {
    id: "message",
    width: "20%",
    align: "center",
    sorting: false,
    label: "MESSAGE",
  },
  {
    id: "status",
    width: "15%",
    align: "left",
    sorting: false,
    label: "STATUS",
  },
  {
    id: "file",
    width: "20%",
    align: "center",
    sorting: false,
    label: "CSV FILES",
  },
];

const BulkImport = ({ data, isLoading, isFetching, permissions, searchParams }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { page: 1 },
    onSubmit: (values) => {
      formik.setFieldValue("page", 1);
      const search = getNewProperties(formik.initialValues, {
        ...values,
        page: formik.initialValues.page,
      });
      dispatch(setSearchParams(search));
    },
  });
  const onDownload = (path) => {
    fetch(path).then((response) => {
      const fileName = response.url.split("/");
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName[fileName.length - 1];
        alink.click();
      });
    });
  };
  const rows = data?.data.results.map((row) => {
    return (
      <TableRow hover key={row.download_id}>
        <TableCell>{row.module_name}</TableCell>
        {row.message ? (
          <TableCell>{row.message}</TableCell>
        ) : (
          <TableCell align="center">...</TableCell>
        )}

        <TableCell>{row.status}</TableCell>
        {row.status === "ERROR" ? (
          <TableCell align="center">
            <SuiBox
              onClick={() => onDownload(row.file)}
              component="span"
              sx={{ cursor: "pointer" }}
            >
              <DownloadIcon />
            </SuiBox>
          </TableCell>
        ) : (
          <TableCell align="center">...</TableCell>
        )}
      </TableRow>
    );
  });
  const handleChange = (event, value) => {
    formik.setFieldValue("page", value);
    dispatch(setSearchParams({ ...searchParams, page: value }));
  };
  const handleReload = (event, value) => {
    dispatch(setSearchParams({ ...searchParams, reload: value }));
  };
  return (
    <>
      <SuiBox my={3}>
        <SuiTable
          reloadBtnFn={(e) => handleReload(e, !searchParams.reload)}
          permission={permissions?.listing}
          isFetching={isFetching}
          isLoading={isLoading}
          tableMinWidth="100%"
          toolbar={toolbar}
          tablehead={headCells}
          rows={rows}
        />
      </SuiBox>
      <SuiTablePagination
        totalData={data?.data.count}
        totalShowing={data?.data.results.length}
        currentPage={{ page: formik.values.page, fn: handleChange }}
      />
    </>
  );
};
export default withGetContainer({
  WrappedComponent: BulkImport,
  url: "admin/bulk-import-data?pagination=true",
});
