import React from "react";
import PropTypes from "prop-types";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

const CommonAccordion = ({ id, head, children }) => {
  const [showActions, setShowActions] = React.useState(false);
  const handleChange = React.useCallback(() => {
    setShowActions(!showActions);
  }, [showActions]);
  const styled = (active) => {
    return {
      sx: {
        border: "1px solid #D3E0E5",
        boxShadow: active ? "rgb(207 218 222 / 80%) 0px 0.25rem 0.75rem" : "none",
        transition: "all .3s ease",
      },
    };
  };
  return (
    <Accordion {...styled(showActions)} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ width: "20px", height: "20px" }} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{ position: "relative", ".MuiAccordionSummary-content": { alignItems: "center" } }}
      >
        <SuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SuiTypography fontWeight="medium" fontSize="1rem">
            {head.city}
          </SuiTypography>
          <SuiTypography textColor="text2" fontSize=".8rem" pr={1}>
            {head.state}
          </SuiTypography>
        </SuiBox>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

CommonAccordion.propTypes = {
  id: PropTypes.any,
  head: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
  }),
  children: PropTypes.any,
};

export default CommonAccordion;
