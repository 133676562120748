import React from "react";
import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const AgentAddress = () => {
  const { id } = useParams();
  const { data: Address, isFetching } = useHandleGetApiQuery({ url: `admin/agent-address/${id}` });
  return (
    <Grid container className="h-100" spacing={3} alignContent="flex-start">
      <Grid item xs={12}>
        <SuiTypography fontSize="1.1rem" textTransform="capitalize" fontWeight="medium">
          Address
        </SuiTypography>
      </Grid>
      <Grid item xs={12}>
        {Address?.data.length > 0 && !isFetching ? (
          <Grid container spacing={2} alignContent="flex-start">
            {Address?.data.map((item) => {
              const fullAddress = item.address_line1.concat(
                ", ",
                !item.address_line2 ? "" : item.address_line2.concat(", "),
                !item.address_line3 ? "" : item.address_line3.concat(", "),
                !item.landmark ? "" : item.landmark.concat(", "),
                item.city,
                ", ",
                item.state,
                " - ",
                item.pincode
              );
              return (
                <Grid key={item.user_address_id} id={item.user_address_id} item xs={6}>
                  <SuiBox
                    sx={{
                      boxShadow: "0px 4px 12px #CFDADECC",
                      border: "1px solid #E2EBEF",
                      borderRadius: "7px",
                      py: 2.5,
                      px: 2,
                    }}
                  >
                    <SuiTypography fontSize="1rem" textTransform="capitalize">
                      {fullAddress}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "This Agent don't have any address."
            )}
          </SuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default AgentAddress;
