import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Box, IconButton } from "@mui/material";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";

const Controls = forwardRef(({ onPlayPause }, ref) => (
  <Box
    ref={ref}
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <IconButton
      onClick={onPlayPause}
      sx={{
        p: 0,
        backdropFilter: "blur(5px)",
        bgcolor: "rgba(0, 0, 0, 0.2)",
        "&:hover": { bgcolor: "rgba(0, 0, 0, 0.25)" },
      }}
    >
      <PlayCircleOutlinedIcon sx={{ fontSize: "60px !important", fill: "#fff" }} />
    </IconButton>
  </Box>
));
Controls.propTypes = {
  onPlayPause: PropTypes.bool,
};
export default Controls;
