import React from "react";

import { Avatar, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import { stringAvatar, capitalize } from "api/helper";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonSpinner from "components/CommonSpinner";
import SuiTablePagination from "components/SuiTablePagination";
import colors from "assets/theme/base/colors";

const CustomerEmergencyContact = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const { data, isFetching } = useHandleGetApiQuery(
    { url: `admin/customer-emergency-contact/${id}`, params: { page: page } },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePageChange = React.useCallback((event, value) => {
    setPage(value);
  }, []);
  return (
    <Grid container className="h-100" rowSpacing={2} alignContent="space-between">
      <Grid item xs={12}>
        {data?.data.count > 0 && !isFetching ? (
          <Grid container spacing={1}>
            {data?.data.results.map((item) => {
              const name = `${item.first_name} ${item.last_name} `;
              const fullName = `${item.title} ${item.first_name} ${
                item.middle_name ? item.middle_name : ""
              } ${item.last_name}`;
              const mobile = `${item.country_code} ${item.mobile_no}`;
              const email = item?.email;
              return (
                <Grid
                  key={item.user_emergency_contact_id}
                  id={item.user_emergency_contact_id}
                  item
                  xs={6}
                >
                  <SuiBox
                    className="h-100"
                    sx={{
                      border: "2px solid #E2EBEF",
                      padding: "1rem 0.625rem",
                      borderRadius: "7px",
                      bgcolor: "#fff",
                    }}
                  >
                    <Grid container spacing={2} flexWrap="nowrap">
                      <Grid item>
                        <Avatar {...stringAvatar(name, "", "60px", "1.4rem")} />
                      </Grid>
                      <Grid item>
                        <SuiTypography fontWeight="medium" fontSize="1.1rem">
                          {capitalize(fullName)}
                        </SuiTypography>
                        <SuiBox>
                          <SuiTypography component="span" fontWeight="medium" fontSize="14px">
                            Mobile No. :&nbsp;
                          </SuiTypography>
                          <SuiTypography component="span" textColor="text2" fontSize="14px">
                            {mobile}
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox>
                          <SuiTypography component="span" fontWeight="medium" fontSize="14px">
                            Email :&nbsp;
                          </SuiTypography>
                          <SuiTypography component="span" textColor="text2" fontSize="14px">
                            {email?.toLowerCase()}
                          </SuiTypography>
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "This customer don't have any emergency contact."
            )}
          </SuiBox>
        )}
      </Grid>
      {data?.data.count > 10 ? (
        <Grid item sx={{ ml: "auto" }}>
          <SuiTablePagination
            margin="0px"
            totalData={data?.data.count}
            totalShowing={data?.data.results.length}
            currentPage={{ page: page, fn: handlePageChange }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomerEmergencyContact;
