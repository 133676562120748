import React, { useState } from "react";

import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import StarIcon from "@mui/icons-material/Star";
import SuiTypography from "components/SuiTypography";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonRating from "components/Rating";
import { stringAvatar } from "api/helper";
import RatingMenu from "./RatingMenu";
import SuiTablePagination from "components/SuiTablePagination";
import moment from "moment";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
const headCells = [
  {
    id: "customer_name",
    width: "auto",
    align: "left",
    sorting: false,
    label: "CUSTOMER NAME",
  },
  {
    id: "comment",
    width: "250px",
    align: "left",
    sorting: false,
    label: "COMMENT",
  },
  {
    id: "rating",
    width: "auto",
    align: "center",
    sorting: false,
    label: "RATING",
  },
  {
    id: "date_time",
    width: "auto",
    align: "left",
    sorting: false,
    label: "DATE - TIME",
  },
];
const AgentRating = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { data: Ratings, isFetching } = useHandleGetApiQuery(
    {
      url: `admin/agent-ratings/${id}`,
      params: { page: page },
    },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <>
      <SuiBox sx={{ position: "sticky", top: 0, bgcolor: "#fff !important", zIndex: 1 }}>
        <SuiTypography fontSize="1.1rem" textTransform="capitalize" fontWeight="medium" pb={1}>
          Agent Rating
        </SuiTypography>
        {Ratings?.data && !isFetching ? (
          <>
            <SuiTypography fontSize="14px" textTransform="capitalize" fontWeight="medium">
              {Ratings?.data.total_count} Ratings
            </SuiTypography>
            <SuiBox pb={1}>
              <CommonRating readOnly value={Ratings?.data.overall_average} size="large" />
            </SuiBox>
          </>
        ) : null}
      </SuiBox>
      <Grid container rowSpacing={2} alignContent="space-between">
        <Grid item xs={12}>
          {Ratings?.data.data.results.length > 0 && !isFetching ? (
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              sx={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", pb: "1rem" }}
            >
              <Grid item xs={12}>
                <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0, bgcolor: "#E6EDF0" },
                        }}
                      >
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            width={headCell.width}
                            sx={{ fontSize: "14px", fontWeight: "500", color: "#6E828A" }}
                          >
                            {headCell.sorting ? (
                              <TableSortLabel
                                sx={{ color: "#6E828A !important", fontSize: 13 }}
                                active
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            ) : (
                              headCell.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Ratings?.data.data.results.map((row) => (
                        <TableRow key={row.id} sx={{ "& td, & th": { fontSize: 14 } }}>
                          <TableCell>
                            <SuiBox display="flex" alignItems="center" columnGap={1}>
                              <Avatar
                                src={row.user.usr_profile_image}
                                alt={`${row.user.usr_first_name} ${row.user.usr_last_name}`}
                                {...stringAvatar(
                                  `${row.user.usr_first_name} ${row.user.usr_last_name}`,
                                  ""
                                )}
                              />
                              <SuiTypography fontSize={14} fontWeight="semiBold">
                                {`${row.user.usr_title} ${row.user.usr_first_name} ${
                                  row.user.usr_middle_name ? row.user.usr_middle_name : ""
                                } ${row.user.usr_last_name}`}
                              </SuiTypography>
                            </SuiBox>
                          </TableCell>
                          <TableCell sx={{ lineHeight: 1.4, fontWeight: 400 }}>
                            {row.review}
                          </TableCell>
                          <TableCell align="center">
                            {row.average_rating}
                            <StarIcon sx={{ width: 12, height: 12, color: "#ED952F", mx: 0.5 }} />
                            <RatingMenu data={row.rating_json} />
                          </TableCell>
                          <TableCell>
                            {moment(new Date(row.created_at)).format("Do MMM YYYY | hh:mm A")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 390px)"
            >
              {isFetching ? (
                <CommonSpinner size="100px" color={colors.info.main} />
              ) : (
                "This Agent don't have any Ratings."
              )}
            </SuiBox>
          )}
        </Grid>
        <SuiTablePagination
          totalData={Ratings?.data.data.count}
          totalShowing={Ratings?.data.data.results.length}
          currentPage={{ page: page, fn: handlePageChange }}
        />
      </Grid>
    </>
  );
};

export default AgentRating;
