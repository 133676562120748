import React from "react";
import { Rating } from "@mui/material";

const CommonRating = ({ name, value, readOnly, disabled, size }) => (
  <Rating
    name={name}
    value={+value < Math.round(+value) ? +value + 0.1 : Math.round(+value)}
    precision={0.5}
    readOnly={readOnly}
    disabled={disabled}
    size={size}
  />
);
export default CommonRating;
