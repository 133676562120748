import React from "react";

import { Grid } from "@mui/material";
import CommonAccordion from "./CommonAccordion";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import style from "layouts/agent/components/style";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import SuiTablePagination from "components/SuiTablePagination";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
const AgentServiceArea = () => {
  const classes = style();
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const { data: ServiceArea, isFetching } = useHandleGetApiQuery(
    { url: `admin/agent-service-area/${id}`, params: { page: page } },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePageChange = React.useCallback((event, value) => {
    setPage(value);
  }, []);
  return (
    <>
      <SuiTypography
        fontSize="1.1rem"
        textTransform="capitalize"
        fontWeight="medium"
        sx={{ pb: 2, position: "sticky", top: 0, bgcolor: "#fff", zIndex: 1 }}
      >
        Service areas
      </SuiTypography>
      <Grid container rowSpacing={2} alignContent="space-between">
        <Grid item xs={12}>
          {ServiceArea?.data.count > 0 && !isFetching ? (
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "auto", pb: "2rem" }}
            >
              {ServiceArea?.data.results.map((item) => {
                return (
                  <Grid key={item.user_address_id} id={item.user_address_id} item xs={6}>
                    <CommonAccordion
                      id={item.user_address_id}
                      head={{ city: item.city.name, state: item.state.name }}
                    >
                      <SuiTypography customClass={classes.list_label}>List of areas</SuiTypography>
                      <SuiBox customClass={classes.area_wraper}>
                        {item.service_area_pincode?.map((item) => (
                          <SuiBox customClass={classes.area_box} key={item.area_name}>
                            <SuiTypography component="div" customClass="lables">
                              {item.area_name}
                            </SuiTypography>
                            <SuiTypography component="div" customClass="lables">
                              {item.pincode}
                            </SuiTypography>
                          </SuiBox>
                        ))}
                      </SuiBox>
                    </CommonAccordion>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 390px)"
            >
              {isFetching ? (
                <CommonSpinner size="100px" color={colors.info.main} />
              ) : (
                "This agent don't have any Service Area."
              )}
            </SuiBox>
          )}
        </Grid>
        {ServiceArea?.data.count > 10 && (
          <Grid item sx={{ ml: "auto" }}>
            <SuiTablePagination
              margin="0px"
              totalData={ServiceArea?.data.count}
              totalShowing={ServiceArea?.data.results.length}
              currentPage={{ page: page, fn: handlePageChange }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AgentServiceArea;
