import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormLabel, Switch } from "@mui/material";
import { getCountryCode } from "api/helper";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import SuiPhoneNo from "components/SuiPhoneNo";
import Separator from "layouts/authentication/components/Separator";
import { useFormik } from "formik";
import { useLoginWithPasswordMutation } from "app/features/api/AuthApi";
import { useDispatch } from "react-redux";
import { setCredentials } from "app/features/AuthSlice";
import ErrorMessageComponent from "components/FormikErrorMessage";
// import { Toast } from "components/Toaster";
import { LoginWithPasswordSchema } from "Validation";

const LoginWithPassword = () => {
  localStorage.removeItem("timer");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithPassword, { isLoading }] = useLoginWithPasswordMutation();
  const [loginwithPasswordError, setLoginwithPasswordError] = useState(false);
  const [btnTxt, setBtnTxt] = useState("Login");
  const [remember, setRemembe] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setBtnTxt("Login...");
    } else {
      setBtnTxt("Login");
    }
  }, [isLoading]);
  const formik = useFormik({
    initialValues: {
      country_code: `+91`,
      mobile_no: "",
      password: "",
    },
    validationSchema: LoginWithPasswordSchema,
    onSubmit: async (values) => {
      const body = {
        mobile_no: values.mobile_no,
        password: values.password,
        country_code: getCountryCode(values.country_code),
      };

      try {
        const loginData = await loginWithPassword(body).unwrap();
        if (loginData.status.code === 200) {
          localStorage.setItem("token", loginData.data.access_token);
          localStorage.setItem("tokenExpiryTime", loginData.data.access_token_expiry);
          localStorage.setItem("loggedInTime", new Date());
          localStorage.setItem("refresh_token", loginData.data.refresh_token);
          dispatch(setCredentials({ token: loginData.data.access_token, isAuthenticated: true }));
          navigate("/dashboard");
        } else {
          setLoginwithPasswordError(loginData.data.status.message);
          // Toast({ type: "error", message: loginData.data.status.message });
        }
      } catch (error) {
        setLoginwithPasswordError(error.data.status.message);
        // if (error?.originalStatus >= 500) {
        //   Toast({ type: "error", message: "Something went wrong!" });
        // } else if (error.data.message) {
        //   Toast({ type: "error", message: error.data.status.message });
        // } else {
        //   Toast({ type: "error", message: error.data.status.message });
        // }
      }
    },
  });
  const handleSetRememberMe = () => {
    setRemembe(!remember);
    localStorage.setItem("isRemember", !remember);
  };
  const handleForgot = () => {
    navigate("/send-otp", { state: "FORGOT_PASSWORD" });
  };
  const handleLoginOtp = useCallback(() => {
    navigate("/otp-login", { state: "OTP_LOGIN" });
  }, []);
  useEffect(() => {
    if (loginwithPasswordError && Object.keys(formik.errors)?.length > 0) {
      setLoginwithPasswordError(false);
    }
  }, [formik.errors, loginwithPasswordError]);
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Login
      </SuiTypography>
      <SuiTypography component="p" variant="formSubHeading">
        Filll up below details to login
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={1}>
          <FormLabel htmlFor="mobile_no">Mobile Number</FormLabel>
          <SuiPhoneNo
            countryCode={formik.handleChange}
            mobileNum={formik.handleChange}
            codeValue={getCountryCode(formik.values.country_code)}
            phoneValue={formik.values.mobile_no}
          />
          <ErrorMessageComponent
            touched={formik.touched.country_code}
            error={formik.errors.country_code}
          />
          <ErrorMessageComponent
            touched={formik.touched.mobile_no}
            error={formik.errors.mobile_no}
          />
        </SuiBox>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <SuiPasswordInput value={formik.values.password} getValue={formik.handleChange} />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Switch checked={remember} onChange={handleSetRememberMe} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            customClass="cursor-pointer user-select-none"
          >
            &nbsp;&nbsp;Remember Me
          </SuiTypography>
          <SuiTypography
            customClass="cursor-pointer"
            ml="auto"
            onClick={handleForgot}
            variant="caption"
            textColor="info"
            fontWeight="medium"
            tabIndex="3"
          >
            Forgot Password?
          </SuiTypography>
        </SuiBox>
        {Object.keys(formik.errors)?.length === 0 && loginwithPasswordError && (
          <ErrorMessageComponent touched={loginwithPasswordError} error={loginwithPasswordError} />
        )}
      </SuiBox>
      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          buttonColor="info"
          fullWidth
          isLoading={isLoading}
          onClick={formik.handleSubmit}
        >
          {btnTxt}
        </SuiButton>
      </SuiBox>
      <Separator />
      <SuiBox textAlign="center">
        <SuiButton
          variant="text"
          buttonColor="dark"
          sx={{ textDecoration: "underline !important", textTransform: "none" }}
          onClick={handleLoginOtp}
        >
          Login with OTP
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default LoginWithPassword;
