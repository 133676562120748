import React from "react";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import CommonSpinner from "components/CommonSpinner";
import CustomerIcon from "Container/Icons/CustomerIcon";
import AgentIcon from "Container/Icons/AgentIcon";
import ServicesIcon from "Container/Icons/ServicesIcon";
import { capitalize, getNewProperties, formatDate } from "api/helper";
import SuiDatePicker from "components/SuiDatePicker";
import { FormLabel } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setSearchParams } from "app/features/AuthSlice";
import withGetContainer from "Container/withGetContainer";
import moment from "moment";
import SuiButton from "components/SuiButton";
import colors from "assets/theme/base/colors";

import SuiBox from "components/SuiBox";
import MiniStatisticsCard from "Container/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "Container/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "Container/Charts/LineCharts/GradientLineChart";
import DefaultDoughnutChart from "Container/Charts/DoughnutCharts/DefaultDoughnutChart";
import AgentCountCard from "Container/Cards/AgentCountCard";

import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import leadGenerationDoughnutChartData from "layouts/dashboard/data/leadGenerationDoughnutChartData";

function Dashboard({ data, isLoading, isFetching }) {
  const { info, success, warning, error } = colors;
  const dashboardData = data?.data;
  const revenue = dashboardData?.revenue;
  const death_claim = dashboardData?.death_claim_intimation;
  const keyArray = React.useCallback((object) => {
    if (object) {
      const newObject = { ...object };
      delete newObject.total_sr;
      delete newObject.total_leads;

      const keys = Object.keys(newObject).map((item) => capitalize(item.replaceAll("_", " ")));
      return keys;
    }
    return [];
  }, []);
  const valueArray = React.useCallback((object) => {
    if (object) {
      const newObject = { ...object };
      delete newObject.total_sr;
      delete newObject.total_leads;

      const values = Object.values(newObject).map((item) => +item);
      return values;
    }
    return [];
  }, []);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => {
      const search = getNewProperties(formik.initialValues, values);
      dispatch(setSearchParams({ ...search }));
    },
  });

  const handleStartDate = React.useCallback((e) => {
    const isTrue = formatDate(new Date(e)) === "NaN-NaN-NaN";
    if (e && !isTrue) {
      formik.resetForm();
      dispatch(setSearchParams({}));
      formik.setFieldValue("start_date", formatDate(new Date(e)));
      formik.setFieldValue("end_date", formatDate(new Date()));
      formik.handleSubmit();
    } else {
      formik.setFieldValue("start_date", null);
      formik.setFieldValue("end_date", null);
      formik.handleSubmit();
    }
  }, []);
  const handleEndDate = React.useCallback((e) => {
    const toDate = new Date(e);
    if (e) {
      if (toDate.getTime()) {
        formik.setFieldValue("end_date", formatDate(toDate));
        formik.handleSubmit();
      } else {
        return;
      }
    } else {
      formik.setFieldValue("end_date", null);
      formik.setFieldValue("start_date", null);
      formik.handleSubmit();
    }
  }, []);

  const handleResetForm = React.useCallback(() => {
    formik.resetForm();
    dispatch(setSearchParams({}));
  }, []);
  const dateSelected = formik.values.start_date && formik.values.end_date;
  const dateRange = dateSelected
    ? `${moment(formik.values.start_date).format("Do MMMM, YYYY")} - ${moment(
        formik.values.end_date
      ).format("Do MMMM, YYYY")}`
    : "";
  if (isLoading) {
    return (
      <SuiBox
        height="calc(100vh - 150px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CommonSpinner size="100px" color={info.main} />
      </SuiBox>
    );
  }
  return (
    <SuiBox py={3} customClass={isFetching ? "loading" : ""}>
      <SuiBox mb={3}>
        <Grid container columnSpacing={3} rowSpacing={2} alignItems="center">
          <Grid item>
            <FormLabel>Date Range</FormLabel>
          </Grid>
          <Grid item>
            <SuiDatePicker
              input={{
                id: "start_date",
                lable: "",
                placeholder: "From",
                minDate: new Date("01-01-2023"),
                value: formik.values.start_date,
                fn: handleStartDate,
              }}
            />
          </Grid>
          <Grid item>to</Grid>
          <Grid item>
            <SuiDatePicker
              input={{
                id: "end_date",
                lable: "",
                placeholder: "To",
                disabled: !formik.values.start_date ? true : false,
                minDate: new Date(formik.values.start_date),
                value: formik.values.end_date,
                fn: handleEndDate,
              }}
            />
          </Grid>
          {dateSelected && (
            <Grid item>
              <SuiButton
                type="reset"
                onClick={handleResetForm}
                variant="outlined"
                buttonColor="dark"
                sx={{ px: "2rem", py: "0.4rem", fontSize: "14px" }}
              >
                Reset
              </SuiButton>
            </Grid>
          )}
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} xl={3}>
            <MiniStatisticsCard
              title="Customer Registered"
              count={dashboardData?.customer_registered}
              icon={{ color: "primary", component: <CustomerIcon color="light" size="1.5rem" /> }}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <MiniStatisticsCard
              title="Agent Registered"
              count={dashboardData?.agent_registered}
              icon={{ color: "primary", component: <AgentIcon color="light" size="1.5rem" /> }}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <MiniStatisticsCard
              title="Total Payment"
              count={`₹ ${dashboardData?.total_payment || 0}`}
              icon={{
                color: "primary",
                component: (
                  <Icon baseClassName="material-icons" fontSize="medium">
                    currency_rupee
                  </Icon>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <MiniStatisticsCard
              title="Total Service Request"
              count={dashboardData?.total_service_request}
              icon={{
                color: "primary",
                component: <ServicesIcon color="light" size="1.8rem" />,
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} xxl={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <AgentCountCard
                      count={dashboardData?.gold_agents?.count}
                      label="Gold Agents"
                      src={dashboardData?.gold_agents?.image}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AgentCountCard
                      count={dashboardData?.platinum_agents?.count}
                      label="Platinum Agents"
                      src={dashboardData?.platinum_agents?.image}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AgentCountCard
                      count={dashboardData?.diamond_agents?.count}
                      label="Diamond Agents"
                      src={dashboardData?.diamond_agents?.image}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DefaultDoughnutChart
                  totalText="SRs"
                  id="srChart"
                  title="Service Request Count"
                  chart={leadGenerationDoughnutChartData(
                    "Service Request Count",
                    keyArray(dashboardData?.service_request_count),
                    valueArray(dashboardData?.service_request_count),
                    [info.main, warning.main, success.main, error.main]
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} xxl={7}>
            <GradientLineChart
              title="Revenue"
              height={{ xs: "25.5rem", xl: "21.4rem" }}
              chart={gradientLineChartData(
                dateRange,
                revenue?.map((label) =>
                  !dateSelected ? label?.month : moment(label?.date).format("Do MMM YYYY")
                ),
                revenue?.map((revenueData) =>
                  !dateSelected ? revenueData?.sum_amount : revenueData?.revenue
                ),
                !dateSelected
              )}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <ReportsBarChart
            title="Death Claim Intimation"
            height={{ xs: "240px", xl: "218px" }}
            chart={reportsBarChartData(
              dateRange,
              death_claim?.map((label) =>
                !dateSelected ? label?.month : moment(label?.date).format("Do MMM YYYY")
              ),
              death_claim?.map(({ sr_count }) => sr_count),
              !dateSelected
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DefaultDoughnutChart
            title="Lead Generation"
            id="lgChart"
            totalText="Leads"
            height="210px"
            chart={leadGenerationDoughnutChartData(
              "Lead Generation",
              keyArray(dashboardData?.lead_generation),
              valueArray(dashboardData?.lead_generation),
              [info.main, "#FC3C3C"]
            )}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default withGetContainer({ WrappedComponent: Dashboard, url: "admin/dashboard" });
