import React from "react";
import { Grid, FormLabel } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import { Close } from "./index";

const FileUploadUI = ({ id, label, accept, required, fileValue, getFile, removeFileName }) => {
  const [fileName, setFileName] = React.useState("");
  React.useEffect(() => {
    if (fileValue?.includes(".")) {
      setFileName(fileValue);
    } else {
      setFileName("");
    }
  }, [fileValue]);

  const handleFileUpload = (e) => {
    if (!e.target.files[0]) {
      setFileName("");
      return;
    }
    setFileName(e.target.files[0].name);
    getFile(e.target.files[0]);
  };
  const removeFile = () => {
    const file = document.getElementById(id);
    file.value = "";
    setFileName("");
    removeFileName("");
    getFile(null);
  };
  const name =
    fileName?.length > 30
      ? `${fileName?.slice(0, 18)}...${fileName?.slice(fileName?.length - 18)}`
      : fileName;
  return (
    <>
      <FormLabel htmlFor={id} required={required}>
        {label}
      </FormLabel>
      <Grid container spacing={2} alignItems="center">
        {!fileName ? (
          <>
            <Grid item sx={{ flexGrow: 1 }}>
              <SuiBox display="flex" title="No file chosen">
                <SuiInput
                  value={name}
                  type="text"
                  placeholder={label}
                  inputProps={{
                    readOnly: true,
                    sx: { "&:focus": { border: "0.125rem solid #D3E0E5" } },
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <SuiButton
                  buttonColor="warning"
                  size="large"
                  sx={{
                    ml: -1.5,
                    px: 2,
                    fontWeight: 400,

                    "&:hover": { transform: "none" },
                  }}
                  component="label"
                  htmlFor={id}
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload
                </SuiButton>
              </SuiBox>
            </Grid>
          </>
        ) : (
          <Grid item>
            <SuiBox
              position="relative"
              borderRadius="5px"
              display="inline-block"
              py={1}
              px={2}
              my={1}
              sx={{ bgcolor: "#f4f4f4 !important" }}
              title={fileName}
            >
              <Close onClose={removeFile} />
              {name}
            </SuiBox>
          </Grid>
        )}
      </Grid>
      <SuiInput
        id={id}
        type="file"
        inputProps={{
          sx: { display: "none" },
          hidden: true,
          required: true,
          multiple: true,
          accept: accept,
        }}
        onChange={handleFileUpload}
      />
    </>
  );
};

export default FileUploadUI;
