import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  progressBox: {
    position: "relative",
    backgroundColor: "#E5EBED",
    borderRadius: "50rem",
    minHeight: "30px",
    margin: "5px 0",
    overflow: "hidden",
    zIndex: 0,
  },
  progressBoxBullets: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>span": {
      position: "relative",
      backgroundColor: "#97AFB8",
      borderRadius: "50rem",
      height: "7px",
      width: "7px",
      margin: "0 0.8rem",
      "&.trans": {
        backgroundColor: "transparent",
      },
      "&.active": {
        backgroundColor: "#fff",
        zIndex: 1,
      },
    },
  },
  progressbar: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    minWidth: "30.5px",
    maxWidth: "100%",
    overflow: "hidden",
    backgroundColor: "#16c79a",
    MozLinearBackground:
      "-moz-linear-gradient(-45deg, rgba(160,230,101,1) 16%, rgba(139,206,82,1) 55%, rgba(139,206,82,1) 55%)",
    backgroundImage:
      "-webkit-linear-gradient(-45deg, rgba(160,230,101,1) 16%,rgba(139,206,82,1) 55%,rgba(139,206,82,1) 55%)",
    background:
      "linear-gradient(135deg, rgba(160,230,101,1) 16%,rgba(139,206,82,1) 55%,rgba(139,206,82,1) 55%)",
    filter:
      'progid:DXImageTransform.Microsoft.gradient( startColorstr="#a0e665", endColorstr="#8bce52",GradientType=1 )',
    borderRadius: "50rem",
  },
  countBox: {
    position: "absolute",
    top: "2.5px",
    right: "3px",
    backgroundColor: "#fff",
    boxShadow: "-3px 1px 1px #639D31",
    border: "0.7px solid #54EFC7",
    color: "#737677",
    fontWeight: 700,
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50rem",
    fontSize: "0.7rem",
    lineHeight: 4,
    zIndex: 2,
  },
  midBox: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  planToolTip: {
    textAlign: "center",
    "& .MuiTooltip-tooltip": {
      maxWidth: 200,
    },
  },
}));
