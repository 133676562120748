import React from "react";
import { Grid, Box, Typography, CardMedia } from "@mui/material";
import Controls from "layouts/agent/components/Controls";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import SuiBox from "components/SuiBox";

const AgentVideoCover = () => {
  const controlsRef = React.useRef(null);
  const { id } = useParams();
  const { data: VideoCover, isFetching } = useHandleGetApiQuery({
    url: `admin/agent-video-cover/${id}`,
  });
  const [state, setState] = React.useState({
    playing: false,
    controls: false,
    played: 0,
  });
  const { playing, played } = state;

  const handlePlayPause = () => {
    if (!playing) {
      const video = document.getElementById("coverVideo");
      video.load();
      video.disablePictureInPicture = true;
      video.play();
      setState({ ...state, playing: !state.playing, played: 1 });
    }
  };
  return (
    <Grid container>
      {VideoCover?.data && !isFetching ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10} lg={7} xl={8}>
              <Box
                sx={{
                  borderRadius: "7px",
                  overflow: "hidden",
                  boxShadow: "0px 4px 12px #CFDADECC",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <CardMedia
                    id="coverVideo"
                    component="video"
                    poster={VideoCover?.data.video_thumbnail}
                    src={VideoCover?.data.video}
                    width="100%"
                    height="auto"
                    preload="auto"
                    controls={!!played}
                    sx={{
                      maxHeight: "277px",
                      objectFit: playing ? "contain" : "cover",
                      objectPosition: "center",
                      m: 0,
                      borderRadius: "initial",
                      width: "100%",
                    }}
                    onClick={handlePlayPause}
                    controlsList="noplaybackrate nodownload"
                    crossOrigin="anonymous"
                  />
                  {!played && <Controls ref={controlsRef} onPlayPause={handlePlayPause} />}
                </Box>
                <Box
                  component="div"
                  sx={{
                    position: "relative",
                    border: "1px solid #E2EBEF",
                    borderRadius: "7px",
                    mt: "-8px",
                    bgcolor: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: "1rem",
                    px: ".7rem",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: "1.1rem",
                          fontWeight: 600,
                        }}
                      >
                        {VideoCover?.data.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: ".8rem",
                          fontWeight: 500,
                          color: "#6F828A",
                        }}
                        lineHeight={1.2}
                      >
                        {VideoCover?.data.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "No video cover available"
            )}
          </SuiBox>
        </Grid>
      )}
    </Grid>
  );
};

export default AgentVideoCover;
