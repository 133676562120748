import { useState } from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CertificateTypeTab from "./CertificateTypeTab";
import { bgColor, appendZero } from "api/helper";
import CommonSpinner from "components/CommonSpinner";
import SuiTablePagination from "components/SuiTablePagination";
import colors from "assets/theme/base/colors";
import PDFViewer from "components/PDFViewer";

const tabs = [
  { label: "MDRT/COT/TOT", value: "1", searchKey: "MDRT" },
  { label: "e-Learning", value: "2", searchKey: "E_LEARNING" },
  { label: "Other", value: "3", searchKey: "OTHER" },
];

const AgentCertificate = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const { data: Certificate, isFetching } = useHandleGetApiQuery(
    {
      url: `admin/agent-certificates/${id}`,
      params: { type: tabs[activeTab - 1].searchKey, page: page },
    },
    activeTab,
    page,
    { refetchOnMountOrArgChange: true }
  );
  const handlePageChange = (e, value) => {
    setPage(value);
  };
  const noMessage = `This agent don't have any ${tabs[activeTab - 1].label} certificates.`;
  return (
    <>
      <SuiTypography
        component="div"
        fontSize="1.1rem"
        fontWeight="medium"
        pb={2}
        sx={{ position: "sticky", top: 0, bgcolor: "#fff", zIndex: 1 }}
      >
        Certificate
        <SuiBox pt={1} sx={{ pb: 2 }}>
          <CertificateTypeTab tabs={tabs} value={activeTab} setValue={setActiveTab} />
        </SuiBox>
      </SuiTypography>
      <Grid container rowSpacing={2} alignContent="space-between">
        <Grid item xs={12}>
          {Certificate?.data?.results.length > 0 ? (
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "auto", pb: "2rem" }}
            >
              {Certificate?.data?.results.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={12} lg={6} key={item.certificate_id}>
                    <Card sx={{ borderRadius: 0, boxShadow: "none", position: "relative" }}>
                      {activeTab == 1 ? (
                        <SuiBox
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bgcolor: "#00000066 !important",
                            borderRadius: "0 7px 0 0",
                          }}
                          color="light"
                          px={1}
                        >
                          {item.type}
                        </SuiBox>
                      ) : null}
                      {item?.certificate.includes(".pdf") ? (
                        <PDFViewer
                          className="pdf_certificate"
                          pages={1}
                          pdfUrl={item?.certificate}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          alt={"ghjjgh"}
                          height={220}
                          sx={bgColor(item.title)}
                          image={item.certificate}
                        />
                      )}
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: "16px 8px !important",
                          gap: 2,
                          borderRadius: "0 0 7px 7px",
                          border: "1px solid #E2EBEF",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: 14, md: 15, xl: 16 }}
                          variant="h6"
                          component="h6"
                          color="#303841"
                          fontWeight={600}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          fontSize={{ xs: 13, md: 14, xl: 15 }}
                          variant="body2"
                          color="text.secondary"
                          fontWeight={500}
                        >
                          {appendZero(item.month)}-{item.year}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 390px)"
            >
              {isFetching ? <CommonSpinner size="100px" color={colors.info.main} /> : noMessage}
            </SuiBox>
          )}
        </Grid>
        {Certificate?.data?.count > 10 && (
          <Grid item sx={{ ml: "auto" }}>
            <SuiTablePagination
              margin="0px"
              totalData={Certificate?.data.count}
              totalShowing={Certificate?.data.results.length}
              currentPage={{ page: page, fn: handlePageChange }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AgentCertificate;
