import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function CustomerIcon({ color, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={colors[color].main}
      viewBox="0 0 16.448 23.129"
    >
      <path
        id="Path_709"
        d="M113.165,113.749a4.877,4.877,0,0,0-.6.056,6.693,6.693,0,0,0-1.789.511,5.423,5.423,0,0,0-3.21,5.335v2.325a2,2,0,0,0,.434,1.165,3.209,3.209,0,0,0,.687.67,9.288,9.288,0,0,0,1.887,2.637c.024.022.049.042.073.063V127.8l-3.53,1.414.02-.008a3.85,3.85,0,0,0-1.468.89A2.22,2.22,0,0,0,105,131.77v4.592a.514.514,0,0,0,.514.514h15.417a.514.514,0,0,0,.514-.514v-4.62a2.368,2.368,0,0,0-.692-1.645,3.724,3.724,0,0,0-1.45-.893l.02.008-3.533-1.415v-1.273c.027-.024.056-.047.083-.072a9.283,9.283,0,0,0,1.885-2.637,3.206,3.206,0,0,0,.687-.67,2,2,0,0,0,.435-1.165V118.36a3.6,3.6,0,0,0-.593-2.125,2.371,2.371,0,0,0-1.7-.956,4.022,4.022,0,0,0-3.415-1.529Zm1.013,5.783a3.441,3.441,0,0,0,1.192.749,3.219,3.219,0,0,0,.934.1v.57a.514.514,0,0,0,.514.514h.514a.506.506,0,0,1,.514.513,1.168,1.168,0,0,1-.24.565,2.4,2.4,0,0,1-.559.549.514.514,0,0,0-.175.2,8.436,8.436,0,0,1-1.7,2.406,2.551,2.551,0,0,1-3.9,0,8.437,8.437,0,0,1-1.7-2.406.514.514,0,0,0-.175-.2,2.4,2.4,0,0,1-.559-.549,1.168,1.168,0,0,1-.24-.565.506.506,0,0,1,.514-.513h.514a.514.514,0,0,0,.514-.514v-.544a7.793,7.793,0,0,0,4.042-.875Zm-2.5,7.691a3.56,3.56,0,0,0,1.485.4c.017,0,.034.006.051.006h.056a3.559,3.559,0,0,0,1.493-.4v.912l-1.542,2.053-1.542-2.055Zm-2.474,2.261,1.5,3a.514.514,0,0,0,.823.134l.151-.151.916.916-1.235,2.468h-5.334v-4.107c0-.01,0-.02,0-.028a1.129,1.129,0,0,1,.378-.9,2.93,2.93,0,0,1,1.078-.643c.006,0,.013-.006.019-.008Zm8.032,0,1.7.682c.007,0,.013.006.02.008a2.78,2.78,0,0,1,1.053.64,1.28,1.28,0,0,1,.4.93v4.107h-5.334l-1.235-2.469.916-.916.151.15a.514.514,0,0,0,.823-.132Zm-4.015,1.442.814.814-.814.814-.814-.814Z"
        transform="translate(-104.994 -113.746)"
      />
    </svg>
  );
}

CustomerIcon.defaultProps = {
  color: "dark",
  size: "16px",
};

CustomerIcon.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CustomerIcon;
