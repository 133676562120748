import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import SuiInput from "components/SuiInput";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  inputBase: {
    borderRadius: "10px",
    border: "0.125rem solid #D3E0E5",
    overflow: "hidden",
  },
  inputBaseFocus: {
    borderRadius: "10px",
    border: "0.125rem solid #1656D7",
    overflow: "hidden",
  },
}));

function SuiPasswordInput({ attr, getValue, value }) {
  const classes = useStyles();
  const [showPass, setShowPass] = useState(false);
  const [focus, setFocus] = useState(false);
  const focusInput = () => {
    setFocus(true);
  };
  const bluarInput = () => {
    setFocus(false);
  };
  return useMemo(
    () => (
      <SuiInput
        customClass={!focus ? classes.inputBase : classes.inputBaseFocus}
        sx={{ flexGrow: 1 }}
        inputProps={{ style: { border: "none", borderRadius: 0 } }}
        inputSettungs={{ autoComplete: "off", required: true }}
        withIcon={{
          baseClass: "outlined",
          color: "info",
          icon: showPass ? "visibility" : "visibility_off",
          direction: "right",
          onClickIcon: () => setShowPass(!showPass),
        }}
        value={value}
        onChange={getValue}
        onFocus={focusInput}
        onBlur={bluarInput}
        id={attr.name}
        name={attr.name}
        type={showPass ? "text" : "password"}
        placeholder={attr.placeholder}
      />
    ),
    [focus, value, showPass]
  );
}
SuiPasswordInput.defaultProps = {
  attr: {
    placeholder: "Password",
    name: "password",
  },
};
SuiPasswordInput.propTypes = {
  index: PropTypes.number,
  attr: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
  }),
  value: PropTypes.string,
  getValue: PropTypes.func,
};

export default SuiPasswordInput;
