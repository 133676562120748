import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SidenavLink from "Container/Sidenav/SidenavLink";
import SidenavCollapse from "Container/Sidenav/SidenavCollapse";

import styles from "Container/Sidenav/styles/sidenav";
import { Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import Logo from "assets/images/PolEasy.png";
import { updateUI } from "app/features/UIControllerSlice";
import { getPermissions } from "app/features/AuthSlice";
const loading = Array(10)
  .fill("")
  .map((_, index) => (
    <Grid container columnSpacing={1.5} key={index} py={1} px={2.5} alignItems="center">
      <Grid item>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={40}
          height={40}
          sx={{ borderRadius: 1 }}
        />
      </Grid>
      <Grid item flexGrow={1}>
        <Skeleton animation="wave" height={30} width="100%" />
      </Grid>
    </Grid>
  ));

function Sidenav({ data, isLoading, ...rest }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { uiControl, auth } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav } = uiControl;
  const classes = styles({ miniSidenav, transparentSidenav });
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const closeSizenav = () => dispatch(updateUI({ type: "miniSidenav", value: true }));
  useEffect(() => {
    dispatch(
      updateUI({
        type: "miniSidenav",
        value: tabView,
      })
    );
  }, [dispatch, location, tabView]);
  const handlePermission = (permission) => {
    if (JSON.stringify(permission) !== JSON.stringify(auth.permissions)) {
      dispatch(getPermissions(permission));
    }
  };
  const renderRoutes = data?.data.map((value) => {
    if (value.route_url_label === `/${collapseName}`) {
      handlePermission(value.permissions);
    }
    return (
      <ListItem
        component="li"
        key={`${Math.floor(value.ordering_value)}`}
        sx={{ order: Math.floor(value.ordering_value) }}
      >
        {value.route_url_label ? (
          <SidenavLink
            to={value.route_url_label}
            name={value.menu_label}
            icon={value.icon_url}
            active={value.route_url_label === `/${collapseName}`}
          />
        ) : (
          <SidenavCollapse name={value.menu_label} icon={value.icon_url} subMenu={value.sub_menu} />
        )}
      </ListItem>
    );
  });
  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSizenav}
        >
          <SuiTypography variant="h6" textColor="secondary">
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <Link to="/dashboard">
          <SuiBox
            component="img"
            src={Logo}
            alt="PolEasy Logo"
            customClass={classes.sidenav_logo}
          />
        </Link>
      </SuiBox>
      <Divider />
      <List className={classes.sidenav_list}>{isLoading ? loading : renderRoutes}</List>
    </Drawer>
  );
}

export default Sidenav;
