import React from "react";
import PropTypes from "prop-types";

import { Accordion, AccordionSummary, AccordionDetails, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

import HomeAddIcon from "Container/Icons/HomeAddIcon";
import OfficeAddIcon from "Container/Icons/OfficeAddIcon";
import OtherAddIcon from "Container/Icons/OtherAddIcon";
import { stringAvatar } from "api/helper";

const CommonAccordion = ({ id, head, isDefault, children }) => {
  const iconActiveColor = {
    path: "#447beb",
    fill: "#ed952f",
  };
  const [showActions, setShowActions] = React.useState(false);
  const handleChange = React.useCallback(() => {
    setShowActions(!showActions);
  }, [showActions]);
  const styled = (active) => {
    return {
      sx: {
        border: "1px solid #D3E0E5",
        boxShadow: active ? "rgb(207 218 222 / 80%) 0px 0.25rem 0.75rem" : "none",
        transition: "all .3s ease",
      },
    };
  };
  return (
    <Accordion {...styled(showActions)} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ width: "20px", height: "20px" }} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{ position: "relative", ".MuiAccordionSummary-content": { alignItems: "center" } }}
      >
        {head.type === "Home" && <HomeAddIcon color={showActions ? iconActiveColor : ""} />}
        {head.type === "Office" && <OfficeAddIcon color={showActions ? iconActiveColor : ""} />}
        {head.type === "Other" && <OtherAddIcon color={showActions ? iconActiveColor : ""} />}
        {head.type === "Family" && <Avatar {...stringAvatar(head.label, "", "60px", "1.4rem")} />}

        {head.type !== "Family" ? (
          <SuiTypography fontWeight="medium" fontSize="1.2rem" pl={2}>
            {head.label ? head.label : head.type}
          </SuiTypography>
        ) : (
          <SuiBox>
            <SuiTypography fontWeight="medium" fontSize="1.2rem" pl={2}>
              {head.label ? head.label : head.type}
            </SuiTypography>
            <SuiTypography textColor="text2" fontSize=".9rem" pl={2}>
              {head.relation}
            </SuiTypography>
          </SuiBox>
        )}
        {head.type !== "Family" && isDefault && (
          <StarIcon color="warning" sx={{ ml: "auto", mr: ".5rem" }} />
        )}
        {head.type === "Family" && isDefault && (
          <FavoriteIcon color="error" sx={{ ml: "auto", mr: ".5rem" }} />
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

CommonAccordion.propTypes = {
  id: PropTypes.any,
  head: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.any,
    relation: PropTypes.string,
  }),
  isDefault: PropTypes.bool,
  children: PropTypes.any,
};

export default CommonAccordion;
