import { useState } from "react";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import styles from "Container/Sidenav/styles/sidenavCollapse";
import SubLink from "./SubLink";
import { getPermissions } from "app/features/AuthSlice";

function SidenavCollapse({ icon, name, subMenu }) {
  const { uiControl, auth } = useSelector((store) => store);
  const dispatch = useDispatch();
  const location = useLocation();
  const { miniSidenav, transparentSidenav, sidenavColor } = uiControl;
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  let active = subMenu.map((link) => {
    if (link.route_url_label === `/${collapseName}`) {
      return true;
    }
    return false;
  });
  active = active.includes(true);
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });
  const [expanded, setExpanded] = useState(active);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handlePermission = (permission) => {
    if (JSON.stringify(permission) !== JSON.stringify(auth.permissions)) {
      dispatch(getPermissions(permission));
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(!expanded)}
      className={classes.collapse_item}
    >
      <AccordionSummary className={classes.collapse_summary} expandIcon={<ExpandMoreIcon />}>
        <ListItemIcon className={classes.collapse_iconBox}>
          {typeof icon === "string" ? <img src={icon} width={15} height={15} /> : icon}
        </ListItemIcon>
        <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
      </AccordionSummary>
      <AccordionDetails>
        {subMenu.map((link) => {
          if (link.route_url_label === `/${collapseName}`) {
            handlePermission(link.permissions);
          }
          return (
            <SubLink
              key={link.route_url_label}
              to={link.route_url_label}
              name={link.menu_label}
              active={link.route_url_label === `/${collapseName}`}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string.isRequired,
  subMenu: PropTypes.any,
};

export default SidenavCollapse;
