import { lazy } from "react";
import RootLayout from "layouts/RootLayout";
import RootOutlet from "layouts/RootLayout/RootOutlet";

import Dashboard from "layouts/dashboard";

const RolesTable = lazy(() => import("layouts/roles/RolesTable"));
const CommonRolesModules = lazy(() => import("layouts/roles/CommonRolesModules"));

const SubAdminTable = lazy(() => import("layouts/sub-admin/SubAdminTable"));
const CommonSubAdminModules = lazy(() => import("layouts/sub-admin/CommonSubAdminModules"));

const Customer = lazy(() => import("layouts/customer"));
import CommonAddCustomer from "layouts/customer/components/CommonAddCustomer";
import CustomerTable from "layouts/customer/components/CustomerTable";
import CustomerEmergencyContact from "layouts/customer/components/CustomerEmergencyContact";
import CustomerAddress from "layouts/customer/components/CustomerAddress";
import CustomerFamilyDetails from "layouts/customer/components/CustomerFamilyDetails";
import MyAgent from "layouts/customer/components/MyAgent";

const TicketsManagementTable = lazy(() =>
  import("layouts/TicketsManagement/TicketsManagementTable")
);
const TicketsManagementView = lazy(() => import("layouts/TicketsManagement/TicketsManagementView"));

const Agent = lazy(() => import("layouts/agent"));
import AgentTable from "layouts/agent/components/AgentTable";
import CommonAddAgent from "layouts/agent/components/CommonAddAgent";
import AgentAddress from "layouts/agent/components/AgentAddress";
import AgentServiceArea from "layouts/agent/components/AgentServiceArea";
import AgentCurrentPlan from "layouts/agent/components/AgentCurrentPlan";
import AgentAchievement from "layouts/agent/components/AgentAchievement";
import AgentVideoCover from "layouts/agent/components/AgentVideoCover";
import AgentPaymentDetails from "layouts/agent/components/AgentPaymentDetails";
import AgentRating from "layouts/agent/components/AgentRating";
import AgentRecommendation from "layouts/agent/components/AgentRecommendation";
import AgentCertificate from "layouts/agent/components/AgentCertificate";
import ImportAgent from "layouts/agent/components/ImportAgent";

const PlanTable = lazy(() => import("layouts/plan/PlanTable"));
const AddPlan = lazy(() => import("layouts/plan/AddPlan"));

const PlanFeaturesTable = lazy(() => import("layouts/plan-features/PlanFeaturesTable"));
const AddPlanFeatures = lazy(() => import("layouts/plan-features/AddPlanFeatures"));

const TransactionHistory = lazy(() => import("layouts/transaction-history"));

const AssignedSRTable = lazy(() => import("layouts/AssignedSR/AssignedSRTable"));
const AssignedSRView = lazy(() => import("layouts/AssignedSR/AssignedSRView"));

const RatingParameterTable = lazy(() => import("layouts/rating-parameter/RatingParameterTable"));
const CommonRatingParameter = lazy(() => import("layouts/rating-parameter/CommonRatingParameter"));

const LeadManagementTable = lazy(() => import("layouts/lead-management/LeadManagementTable"));
const LeadManagementView = lazy(() => import("layouts/lead-management/LeadManagementView"));

const InsuranceCompanyTable = lazy(() => import("layouts/insurance-company/InsuranceCompanyTable"));
const CommonInsuranceCompanyAcction = lazy(() =>
  import("layouts/insurance-company/CommonInsuranceCompanyAcction")
);

const InsuranceBranchTable = lazy(() => import("layouts/insurance-branch/InsuranceBranchTable"));
const CommonInsuranceBranch = lazy(() => import("layouts/insurance-branch/CommonInsuranceBranch"));
const ImportBranch = lazy(() => import("layouts/insurance-branch/ImportBranch"));

const PolicyType = lazy(() => import("layouts/policy-type/PolicyType"));
const AddPolicyType = lazy(() => import("layouts/policy-type/AddPolicyType"));

// const PolicyCategory = lazy(() => import("layouts/policy-category/PolicyCategory"));
// const AddPolicyCategory = lazy(() => import("layouts/policy-category/AddPolicyCategory"));

const PolicyTable = lazy(() => import("layouts/policy/PolicyTable"));
const CommonAddPolicy = lazy(() => import("layouts/policy/CommonAddPolicy"));
const ImportPolicy = lazy(() => import("layouts/policy/ImportPolicy"));

const PolicyRatingParameterTable = lazy(() =>
  import("layouts/policy-rating-parameter/PolicyRatingParameterTable")
);
const AddPolicyRatingParameter = lazy(() =>
  import("layouts/policy-rating-parameter/AddPolicyRatingParameter")
);

const ServiceMasterTable = lazy(() => import("layouts/service-master/ServiceMasterTable"));
const CommonServiceMasterAcction = lazy(() =>
  import("layouts/service-master/CommonServiceMasterAcction")
);

const ProofDocumentsListTable = lazy(() =>
  import("layouts/proof-documents/ProofDocumentsListTable")
);
const AddProofDocumentsList = lazy(() => import("layouts/proof-documents/AddProofDocumentsList"));

const CommonServicesIntegration = lazy(() =>
  import("layouts/services-integration/CommonServicesIntegration")
);
const ServicesIntegrationTable = lazy(() =>
  import("layouts/services-integration/ServicesIntegrationTable")
);

const CustomerRatingTable = lazy(() => import("layouts/customer-rating"));

const ModuleTable = lazy(() => import("layouts/e-learning/module/ModuleTable"));
const CommonAddingModule = lazy(() => import("layouts/e-learning/module/CommonAddingModule"));
const TopicTable = lazy(() => import("layouts/e-learning/topic/TopicTable"));
const CommonAddingTopic = lazy(() => import("layouts/e-learning/topic/CommonAddingTopic"));
const ExamTable = lazy(() => import("layouts/e-learning/exam/ExamTable"));
const CommonAddingExam = lazy(() => import("layouts/e-learning/exam/CommonAddingExam"));
const ResultsTable = lazy(() => import("layouts/e-learning/results/ResultsTable"));

const CustomerReport = lazy(() => import("layouts/reports/CustomerReport"));
const AgentReport = lazy(() => import("layouts/reports/AgentReport"));
const TicketReport = lazy(() => import("layouts/reports/TicketReport"));
const DeathClaimReport = lazy(() => import("layouts/reports/DeathClaimReport"));

const CustomerAuditLogs = lazy(() => import("layouts/audit-logs-report/CustomerAuditLogs"));
const AgentAuditLogs = lazy(() => import("layouts/audit-logs-report/AgentAuditLogs"));
const AdminAuditLogs = lazy(() => import("layouts/audit-logs-report/AdminAuditLogs"));

const CustomerActivityLogs = lazy(() =>
  import("layouts/activity-logs-report/CustomerActivityLogs")
);
const AgentActivityLogs = lazy(() => import("layouts/activity-logs-report/AgentActivityLogs"));
const AdminActivityLogs = lazy(() => import("layouts/activity-logs-report/AdminActivityLogs"));

const DownloadReport = lazy(() => import("layouts/downloads"));

const AdvertisementPostTable = lazy(() =>
  import("layouts/advertisement-post/AdvertisementPostTable")
);
const CommonAdvertisementPost = lazy(() =>
  import("layouts/advertisement-post/CommonAdvertisementPost")
);

const PagesTable = lazy(() => import("layouts/pages/PagesTable"));
const CommonPageUI = lazy(() => import("layouts/pages/CommonPageUI"));

const EmailTemplateTable = lazy(() => import("layouts/email-template/EmailTemplateTable"));
const CommonEmailTemplate = lazy(() => import("layouts/email-template/CommonEmailTemplate"));

const LanguageTable = lazy(() => import("layouts/language-translation/LanguageTable"));
const AddLanguage = lazy(() => import("layouts/language-translation/AddLanguage"));

const GlobalConfig = lazy(() => import("layouts/global-config"));

const CommonVersionTable = lazy(() => import("layouts/app-version/CommonVersionTable"));
const CommonAppVersion = lazy(() => import("layouts/app-version/CommonAppVersion"));

const NotificationTable = lazy(() => import("layouts/notification/NotificationTable"));
const CommonAddingNotification = lazy(() =>
  import("layouts/notification/CommonAddingNotification")
);

const Profile = lazy(() => import("layouts/profile"));
const ProfileDetails = lazy(() => import("layouts/profile/components/ProfileDetails"));
const UpdateProfile = lazy(() => import("layouts/profile/components/UpdateProfile"));
const ChangePassword = lazy(() => import("layouts/profile/components/ChangePassword"));

import LogIn from "layouts/authentication/log-in";
import LoginWithPassword from "layouts/authentication/log-in/Forms/LoginWithPassword";
import ForgetPassword from "layouts/authentication/log-in/Forms/ForgetPassword";
import EnterMobileNum from "layouts/authentication/log-in/Forms/EnterMobileNum";
import EnterOTP from "layouts/authentication/log-in/Forms/EnterOTP";
import LogOut from "layouts/authentication/log-out";
import NotFound from "layouts/404";
import BulkImport from "layouts/bulkImport";

const routes = [
  {
    path: "",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/roles",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <RolesTable />,
          },
          {
            path: "add-role",
            element: <CommonRolesModules />,
          },
          {
            path: "edit-role/:id",
            element: <CommonRolesModules />,
          },
        ],
      },
      {
        path: "/sub-admin",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <SubAdminTable />,
          },
          {
            path: "add-sub-admin",
            element: <CommonSubAdminModules />,
          },
          {
            path: "edit-sub-admin",
            element: <CommonSubAdminModules />,
          },
        ],
      },
      {
        path: "/customer",
        element: <Customer />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <CustomerTable />,
          },
          {
            path: "add-customer",
            element: <CommonAddCustomer />,
          },
          {
            path: "edit-customer/:id",
            element: <CommonAddCustomer />,
          },
          {
            path: "emergency-contact/:id",
            element: <CustomerEmergencyContact />,
          },
          {
            path: "customer-address/:id",
            element: <CustomerAddress />,
          },
          {
            path: "customer-family-details/:id",
            element: <CustomerFamilyDetails />,
          },
          {
            path: "my-agent/:id",
            element: <MyAgent />,
          },
        ],
      },
      {
        path: "/ticket",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <TicketsManagementTable />,
          },
          {
            path: ":id",
            element: <TicketsManagementView />,
          },
        ],
      },
      {
        path: "/agent",
        element: <Agent />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <AgentTable />,
          },
          {
            path: "add-agent",
            element: <CommonAddAgent />,
          },
          {
            path: "edit-agent/:id",
            element: <CommonAddAgent />,
          },
          {
            path: "agent-address/:id",
            element: <AgentAddress />,
          },
          {
            path: "service-area/:id",
            element: <AgentServiceArea />,
          },
          {
            path: "current-plan/:id",
            element: <AgentCurrentPlan />,
          },
          {
            path: "achievement/:id",
            element: <AgentAchievement />,
          },
          {
            path: "video-cover/:id",
            element: <AgentVideoCover />,
          },
          {
            path: "payment-details/:id",
            element: <AgentPaymentDetails />,
          },
          {
            path: "agent-rating/:id",
            element: <AgentRating />,
          },
          {
            path: "recommendation/:id",
            element: <AgentRecommendation />,
          },
          {
            path: "certificate/:id",
            element: <AgentCertificate />,
          },
          {
            path: "import-agent",
            element: <ImportAgent />,
          },
        ],
      },
      {
        path: "/plan",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PlanTable />,
          },
          {
            path: "add-plan",
            element: <AddPlan />,
          },
          {
            path: "edit-plan/:id",
            element: <AddPlan />,
          },
        ],
      },
      {
        path: "/plan-features",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PlanFeaturesTable />,
          },
          {
            path: "add-plan-feature",
            element: <AddPlanFeatures />,
          },
        ],
      },
      {
        path: "/assigned-sr",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <AssignedSRTable />,
          },
          {
            path: ":id",
            element: <AssignedSRView />,
          },
          {
            path: ":id/:id",
            element: <AssignedSRView />,
          },
        ],
      },
      {
        path: "/lead-management",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <LeadManagementTable />,
          },
          {
            path: ":id",
            element: <LeadManagementView />,
          },
        ],
      },
      {
        path: "/rating-parameter",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <RatingParameterTable />,
          },
          {
            path: "add-rating-parameter",
            element: <CommonRatingParameter />,
          },
          {
            path: "edit-rating-parameter/:id",
            element: <CommonRatingParameter />,
          },
        ],
      },
      {
        path: "/transaction-history",
        element: <TransactionHistory />,
        protected: true,
      },
      {
        path: "/insurance-company",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <InsuranceCompanyTable />,
          },
          {
            path: "add-insurance-company",
            element: <CommonInsuranceCompanyAcction />,
          },
          {
            path: "edit-insurance-company/:id",
            element: <CommonInsuranceCompanyAcction />,
          },
        ],
      },
      {
        path: "/insurance-branch",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <InsuranceBranchTable />,
          },
          {
            path: "add-insurance-branch",
            element: <CommonInsuranceBranch />,
          },
          {
            path: "edit-insurance-branch/:id",
            element: <CommonInsuranceBranch />,
          },
          {
            path: "import-insurance-branch",
            element: <ImportBranch />,
          },
        ],
      },
      {
        path: "/policy-type",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PolicyType />,
          },
          {
            path: "add-policy-type",
            element: <AddPolicyType />,
          },
          {
            path: "edit-policy-type/:id",
            element: <AddPolicyType />,
          },
        ],
      },
      // {
      //   path: "/policy-category",
      //   element: <RootOutlet />,
      //   protected: true,
      //   children: [
      //     {
      //       path: "",
      //       index: true,
      //       element: <PolicyCategory />,
      //     },
      //     {
      //       path: "add-policy-category",
      //       element: <AddPolicyCategory />,
      //     },
      //     {
      //       path: "edit-policy-category/:id",
      //       element: <AddPolicyCategory />,
      //     },
      //   ],
      // },
      {
        path: "/policy",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PolicyTable />,
          },
          {
            path: "add-new-policy",
            element: <CommonAddPolicy />,
          },
          {
            path: "import-policies",
            element: <ImportPolicy />,
          },
          {
            path: "edit-policy/:id",
            element: <CommonAddPolicy />,
          },
        ],
      },
      {
        path: "/product-rating-parameter",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PolicyRatingParameterTable />,
          },
          {
            path: "add-product-rating-parameter",
            element: <AddPolicyRatingParameter />,
          },
          {
            path: "edit-product-rating-parameter/:id",
            element: <AddPolicyRatingParameter />,
          },
        ],
      },
      {
        path: "/service-master",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ServiceMasterTable />,
          },
          {
            path: "add-service-master",
            element: <CommonServiceMasterAcction />,
          },
          {
            path: "edit-service-master/:id",
            element: <CommonServiceMasterAcction />,
          },
        ],
      },
      {
        path: "/proof-documents",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ProofDocumentsListTable />,
          },
          {
            path: "add-proof-documents",
            element: <AddProofDocumentsList />,
          },
          {
            path: "edit-proof-documents/:id",
            element: <AddProofDocumentsList />,
          },
        ],
      },
      {
        path: "/services-integration",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ServicesIntegrationTable />,
          },
          {
            path: "add-services-integration",
            element: <CommonServicesIntegration />,
          },
          {
            path: "edit-services-integration/:id",
            element: <CommonServicesIntegration />,
          },
        ],
      },
      {
        path: "/product-customer-rating",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <CustomerRatingTable />,
          },
        ],
      },
      {
        path: "/e-learning-modules",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ModuleTable />,
          },
          {
            path: "add",
            element: <CommonAddingModule />,
          },
          {
            path: "edit/:id",
            element: <CommonAddingModule />,
          },
        ],
      },
      {
        path: "/e-learning-topics",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <TopicTable />,
          },
          {
            path: "add",
            element: <CommonAddingTopic />,
          },
          {
            path: "edit/:id",
            element: <CommonAddingTopic />,
          },
        ],
      },
      {
        path: "/e-learning-exams",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ExamTable />,
          },
          {
            path: "add",
            element: <CommonAddingExam />,
          },
          {
            path: "edit/:id",
            element: <CommonAddingExam />,
          },
        ],
      },
      {
        path: "/e-learning-results",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <ResultsTable />,
          },
        ],
      },
      {
        path: "/customer-report",
        element: <CustomerReport />,
        protected: true,
      },
      {
        path: "/agent-report",
        element: <AgentReport />,
        protected: true,
      },
      {
        path: "/ticket-report",
        element: <TicketReport />,
        protected: true,
      },
      {
        path: "/death-intimation-report",
        element: <DeathClaimReport />,
        protected: true,
      },
      {
        path: "/admin-audit-logs",
        element: <AdminAuditLogs />,
        protected: true,
      },
      {
        path: "/agent-audit-logs",
        element: <AgentAuditLogs />,
        protected: true,
      },
      {
        path: "/customer-audit-logs",
        element: <CustomerAuditLogs />,
        protected: true,
      },
      {
        path: "/customer-activity-logs",
        element: <CustomerActivityLogs />,
        protected: true,
      },
      {
        path: "/agent-activity-logs",
        element: <AgentActivityLogs />,
        protected: true,
      },
      {
        path: "/admin-activity-logs",
        element: <AdminActivityLogs />,
        protected: true,
      },
      {
        path: "/downloads",
        element: <DownloadReport />,
        protected: true,
      },
      {
        path: "/bulk-import",
        element: <BulkImport />,
        protected: true,
      },
      {
        path: "/advertisement-banner",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <AdvertisementPostTable />,
          },
          {
            path: "add-advertisement-banner",
            element: <CommonAdvertisementPost />,
          },
          {
            path: "edit-advertisement-banner/:id",
            element: <CommonAdvertisementPost />,
          },
        ],
      },
      {
        path: "/pages",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <PagesTable />,
          },
          {
            path: "add-page",
            element: <CommonPageUI />,
          },
          {
            path: "edit-page/:id",
            element: <CommonPageUI />,
          },
        ],
      },
      {
        path: "/email-template",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <EmailTemplateTable />,
          },
          {
            path: "add-email-template",
            element: <CommonEmailTemplate />,
          },
          {
            path: "edit-email-template/:id",
            element: <CommonEmailTemplate />,
          },
        ],
      },
      {
        path: "/language-translation",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <LanguageTable />,
          },
          {
            path: "add-new-language",
            element: <AddLanguage />,
          },
          {
            path: "edit-language/:id",
            element: <AddLanguage />,
          },
        ],
      },
      {
        path: "/global-config",
        element: <GlobalConfig />,
        protected: true,
      },
      {
        path: "/app-version",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <CommonVersionTable />,
          },
          {
            path: "add-app-version",
            element: <CommonAppVersion />,
          },
          {
            path: "edit-app-version/:id",
            element: <CommonAppVersion />,
          },
        ],
      },
      {
        path: "/send-notification",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <NotificationTable />,
          },
          {
            path: "add-send-notification",
            element: <CommonAddingNotification />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
        protected: true,
        children: [
          {
            element: <ProfileDetails />,
            index: true,
          },
          {
            path: "edit-profile",
            element: <UpdateProfile />,
          },
          {
            element: <ChangePassword />,
            path: "change-password",
          },
        ],
      },
      {
        path: "log-out",
        element: <LogOut />,
      },
    ],
    protected: true,
  },
  {
    path: "/",
    element: <LogIn />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <LoginWithPassword />,
      },
      {
        element: <EnterMobileNum />,
        path: "send-otp",
      },
      {
        element: <EnterOTP />,
        path: "verify-otp",
      },
      {
        element: <EnterMobileNum />,
        path: "otp-login",
      },
      {
        element: <ForgetPassword />,
        path: "forgot",
      },
    ],
  },
];

export default routes;
