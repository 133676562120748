import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormLabel, Grid } from "@mui/material";

import WrapCard from "Container/Cards/WrapCard";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useHandlePostMutation } from "app/features/api/CommonPostApi";
import { useFormik } from "formik";
import { Toast } from "components/Toaster";
import fileDownload from "js-file-download";

import ErrorMessageComponent from "components/FormikErrorMessage";
import * as yup from "yup";
import FileUploadUI from "components/SuiFileUploadInput/FileUploadUI";
import CompanyDropdown from "components/SuiDropDownList/CompanyDropdown";
import SuiBackdrop from "components/SuiBackdrop";
const ImportAgent = () => {
  const { data: ImportData } = useHandleGetApiQuery({ url: `admin/agent_bulk_import` });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [handlePost] = useHandlePostMutation();
  const onDownload = () => {
    if (!ImportData) {
      return;
    }
    const link = document.createElement("a");
    link.href = ImportData?.data.file_path;
    link.download = "agent_file.csv";
    link.click();
  };
  const convert = async (jsonData) => {
    fileDownload(jsonData, "errors.csv");
  };
  const initialValues = {
    policy_provider_id: "",
    agent_file: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      policy_provider_id: yup.string().required("Please select an Insurance Company."),
      agent_file: yup
        .mixed()
        .required("Please select csv file to upload")
        .test("fileFormat", "Please upload csv file only", (value) => {
          return value && ["text/csv"].includes(value.type);
        }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const uploadData = new FormData();
      setIsSubmitting(true);
      uploadData.append("agent_file", values.agent_file);
      uploadData.append("policy_provider_id", values.policy_provider_id);
      const data = await handlePost({ url: "admin/agent_bulk_import", data: uploadData });
      if (data.data?.status.code === 201 || data.data?.status.code === 200) {
        setIsSubmitting(false);
        setSubmitting(false);
        Toast({ message: data.data.status.message });
        navigate("/bulk-import");
      }
      if (data?.error && data?.error.originalStatus === 400) {
        setIsSubmitting(false);
        setSubmitting(false);
        convert(data?.error.data);
      }
      if (data?.error) {
        setIsSubmitting(false);
        setSubmitting(false);
        Toast({ type: "error", message: data.error.data.status.message });
      }
    },
  });

  const handleFileChange = useCallback((file) => {
    formik.setFieldValue("agent_file", file);
  }, []);
  const removeFileName = () => {
    return;
  };
  return (
    <WrapCard title="Import the file">
      <SuiBackdrop open={isSubmitting} />
      <Grid container rowSpacing={4} sx={{ pt: 3 }}>
        <Grid item xs={12}>
          <SuiButton
            onClick={onDownload}
            size="small"
            sx={{ fontSize: "1rem", fontWeight: "500" }}
            buttonColor="info"
            component="button"
          >
            Download Sample file
          </SuiButton>
          <SuiTypography textColor="text2" fontSize={14} pt={1}>
            Download the sample file and update Agent information according to the example given.
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <FormLabel htmlFor="policy_provider_id" required>
            Insurance Company
          </FormLabel>
          <CompanyDropdown
            select={{
              label: "Select Insurance Company",
              name: "policy_provider_id",
              val: formik.values.policy_provider_id,
              fn: formik.handleChange,
            }}
          />
          {!formik.values.policy_provider_id && (
            <SuiTypography textColor="text2" fontSize={14} pt={1}>
              Select Insurance Company to Upload a CSV file.
            </SuiTypography>
          )}
          <ErrorMessageComponent
            touched={formik.touched.policy_provider_id}
            error={formik.errors.policy_provider_id}
          />
        </Grid>
        <Grid item xs={12} sx={{ p: "0 !important" }} />
        <Grid item xs={7}>
          <FileUploadUI
            id="upload_file"
            label="Upload File"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            getFile={handleFileChange}
            removeFileName={removeFileName}
          />
          {!formik.values.agent_file && (
            <SuiTypography textColor="text2" fontSize={14} pt={1}>
              Upload a CSV file that has Agent information.
            </SuiTypography>
          )}
          <ErrorMessageComponent
            touched={formik.touched.agent_file}
            error={formik.errors.agent_file}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm="auto">
              <SuiButton
                size="small"
                sx={{ fontSize: "1rem", fontWeight: "500" }}
                component={Link}
                to="/agent"
                buttonColor="secondary"
                fullWidth
              >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item xs={12} sm="auto">
              <SuiButton
                size="small"
                sx={{ fontSize: "1rem", fontWeight: "500" }}
                buttonColor="info"
                fullWidth
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Submit
              </SuiButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapCard>
  );
};

export default ImportAgent;
