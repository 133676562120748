import React from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import AgentAvatarWithBadge from "components/AgentAvatarWithBadge";
import CommonRating from "components/Rating";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const MyAgent = () => {
  const { id } = useParams();

  const { data, isFetching } = useHandleGetApiQuery({ url: `admin/customer-agent/${id}` });
  return (
    <Grid container className="h-100" rowSpacing={2} alignContent="space-between">
      <Grid item xs={12}>
        {data?.data.length > 0 && !isFetching ? (
          <Grid
            container
            spacing={2}
            alignContent="flex-start"
            sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "overlay", pb: "2rem" }}
          >
            {data?.data.map((item, index) => (
              <Grid item key={index} xs={6}>
                <SuiBox p={1.5} sx={{ border: "1px solid #E2EBEF", borderRadius: 2 }}>
                  <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs="auto" alignSelf="flex-start">
                      <AgentAvatarWithBadge
                        agentAvatar={{
                          name: `${item.first_name} ${item.last_name}`,
                          src: item.profile_image,
                          alt: `${item.first_name} ${item.last_name}`,
                          size: "55px",
                          fontSize: "19px",
                        }}
                        planBadge={{
                          height: 16,
                          planSrc: item.subscription.plan.logo,
                          planAlt: item.subscription.plan.name,
                        }}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <SuiTypography
                        fontSize={{ xs: 14, mg: 16 }}
                        fontWeight="semiBold"
                        textTransform="capitalize"
                      >
                        {item?.first_name} {item?.middle_name} {item?.last_name}
                      </SuiTypography>
                      <SuiTypography
                        fontSize={{ xs: 12, mg: 14 }}
                        fontWeight="regular"
                        textColor="text2"
                        textTransform="capitalize"
                      >
                        {item?.address.city}, {item?.address.state}
                      </SuiTypography>
                      <SuiBox
                        display="flex"
                        flexWrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ minWidth: 135, width: "100%", mt: 0.5 }}
                      >
                        <CommonRating readOnly value={item?.agent_ratings.stars} size="small" />
                        <SuiTypography fontSize={10} textColor="text2">
                          ({item?.agent_ratings.rating} Ratings)
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Grid>
            ))}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "This customer don't have any Agent details."
            )}
          </SuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default MyAgent;
