import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Grid, FormLabel, Icon } from "@mui/material";
import SuiTitleInput from "components/SuiTitleInput";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiPhoneNo from "components/SuiPhoneNo";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiPasswordInput from "components/SuiPasswordInput";
import { useFormik } from "formik";
import { CustomerSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { CustomerUpdateSchema } from "Validation";
import withPostContainer from "Container/withPostContainer";
import {
  getNewProperties,
  checkIfEmpty,
  getCountryCode,
  handleCountryCode,
  getCountryShortCode,
} from "api/helper";
import { isValidPhoneNumber } from "react-phone-number-input";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";

const CommonAddCustomer = ({ data, id, handlePostRequest, isFetching }) => {
  const btnText = id ? "Update" : "Add";
  const btnTextLoad = id ? "Updating..." : "Adding...";
  const [updateData, setUpdateData] = useState();
  const initialValues = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_no: "",
    country_code: "+91",
    email: "",
    newPassword: "",
    confirmPassword: "",
    referral_code: "",
  };
  const formik = useFormik({
    initialValues: updateData ? updateData : initialValues,
    validationSchema: id ? CustomerUpdateSchema : CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const addData = {
        first_name: values.first_name,
        middle_name: checkIfEmpty(values.middle_name),
        last_name: values.last_name,
        email: values.email,
        password: values.confirmPassword,
        title: values.title,
        country_code: values.country_code,
        mobile_no: values.mobile_no,
        referral_code: values.referral_code ? values.referral_code : null,
      };
      if (
        !isValidPhoneNumber(
          formik.values.mobile_no,
          getCountryShortCode(getCountryCode(formik.values.country_code))
        )
      ) {
        return;
      } else {
        const updateValue = getNewProperties(formik.initialValues, addData);
        handlePostRequest({
          url: id ? `admin/customer/${id}` : "admin/customer",
          data: id ? updateValue : addData,
          isUpdate: id ? true : false,
          redirect: "/customer",
        });
      }
    },
  });
  useEffect(() => {
    if (data) {
      const value = {
        title: data?.data.usr_title,
        first_name: data?.data.usr_first_name,
        middle_name: data?.data.usr_middle_name ? data?.data.usr_middle_name : "",
        last_name: data?.data.usr_last_name,
        mobile_no: data?.data.usr_mobile_no,
        country_code: data?.data.country_code,
        email: data?.data.usr_email,
        referral_code: data?.data.referral_code,
      };
      setUpdateData(value);
    }
  }, [data]);

  return (
    <>
      {(data?.data && !isFetching) || !id ? (
        <Grid component="form" container spacing={{ xs: 1, lg: 2 }}>
          <Grid item xs={6}>
            <Grid container spacing={{ xs: 1, lg: 1.5 }}>
              <Grid item xs={3}>
                <FormLabel htmlFor="title" required>
                  Title
                </FormLabel>
                <SuiTitleInput value={formik.values.title} setValue={formik.handleChange} />
                <ErrorMessageComponent touched={formik.touched.title} error={formik.errors.title} />
              </Grid>
              <Grid item xs={9}>
                <FormLabel htmlFor="first_name" required>
                  First Name
                </FormLabel>
                <SuiInput
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  inputSettungs={{ autoComplete: "off", required: true }}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.first_name}
                  error={formik.errors.first_name}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="middle_name">Middle Name</FormLabel>
            <SuiInput
              id="middle_name"
              name="middle_name"
              placeholder="Middle Name"
              inputSettungs={{ autoComplete: "off" }}
              value={formik.values.middle_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.middle_name}
              error={formik.errors.middle_name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="last_name" required>
              Last Name
            </FormLabel>
            <SuiInput
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.last_name}
              error={formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="mobile_no" required>
              Mobile Number
            </FormLabel>
            <SuiPhoneNo
              countryCode={(e) => handleCountryCode(formik, e)}
              mobileNum={formik.handleChange}
              codeValue={getCountryCode(formik.values.country_code)}
              phoneValue={formik.values.mobile_no}
            />
            <ErrorMessageComponent
              touched={formik.touched.mobile_no}
              error={
                formik.values.mobile_no &&
                !isValidPhoneNumber(
                  formik.values.mobile_no,
                  getCountryShortCode(getCountryCode(formik.values.country_code))
                )
                  ? "Please enter valid mobile number"
                  : formik.errors.mobile_no
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="email" required>
              Email ID
            </FormLabel>
            <SuiInput
              id="email"
              name="email"
              placeholder="Email ID"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="agent_referral_code">Agent referral code</FormLabel>
            <SuiInput
              id="referral_code"
              name="referral_code"
              disabled={id && data?.data.referral_code ? true : false}
              placeholder="Agent referral code"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.referral_code}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.referral_code}
              error={formik.errors.referral_code}
            />
          </Grid>
          {id ? null : (
            <>
              <Grid item xs={6}>
                <FormLabel htmlFor="newPassword" required>
                  Create password
                </FormLabel>
                <SuiPasswordInput
                  attr={{ name: "newPassword", placeholder: "Create Password" }}
                  value={formik.values.newPassword}
                  getValue={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.newPassword}
                  error={formik.errors.newPassword}
                />
                <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
                  <Icon
                    fontSize="small"
                    sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}
                  >
                    info_outlined
                  </Icon>
                  <SuiTypography
                    fontWeight="medium"
                    textColor="text2"
                    sx={{ fontSize: "0.8rem" }}
                    px={0.5}
                    py={0}
                  >
                    Note: Password required at least 8 characters, 1 number, 1 Alphabet, 1 special
                    character.
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="confirmPassword" required>
                  Confirm Password
                </FormLabel>
                <SuiPasswordInput
                  attr={{ name: "confirmPassword", placeholder: "Confirm Password" }}
                  value={formik.values.confirmPassword}
                  getValue={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.confirmPassword}
                  error={formik.errors.confirmPassword}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6} />
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  component={Link}
                  to="/customer"
                  buttonColor="secondary"
                  fullWidth
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  buttonColor="info"
                  disabled={formik.isSubmitting || !formik.dirty}
                  onClick={formik.handleSubmit}
                  fullWidth
                  type="submit"
                >
                  {formik.isSubmitting ? btnTextLoad : btnText}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 390px)"
        >
          <CommonSpinner size="100px" color={colors.info.main} />
        </SuiBox>
      )}
    </>
  );
};

export default withPostContainer(CommonAddCustomer, "admin/customer/");
