import React from "react";
import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { stringAvatar } from "api/helper";
import SuiBox from "components/SuiBox";

const useStyles = makeStyles(() => ({
  planImg: {
    position: "absolute",
    bottom: -8,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    height: "auto",
    objectFit: "contain",
    objectPosition: "center",
  },
}));

const AgentAvatarWithBadge = ({ agentAvatar, planBadge }) => {
  const classes = useStyles();
  const { name, src, alt, size, fontSize } = agentAvatar;
  const { height, planSrc, planAlt } = planBadge;

  return (
    <SuiBox sx={{ position: "relative" }}>
      <Avatar alt={alt} {...stringAvatar(name, src, size, fontSize)} src={src} />
      <Box
        component="img"
        className={classes.planImg}
        sx={{ maxWidth: size, maxHeight: height }}
        src={planSrc}
        alt={planAlt}
      />
    </SuiBox>
  );
};

export default AgentAvatarWithBadge;
