import PropTypes from "prop-types";

const OfficeAddIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30.921 25">
    <g id="Group_767" transform="translate(-41 -349)">
      <path
        id="Path_502"
        d="M231.659,187.15a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-181.055 168.89)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_503"
        d="M308.659,187.15a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-252.265 168.89)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_504"
        d="M231.659,260.65a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-181.055 100.916)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_505"
        d="M308.659,260.65a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-252.265 100.916)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_506"
        d="M231.659,334.15a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-181.055 32.942)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_507"
        d="M308.659,334.15a1.645,1.645,0,1,1-1.645-1.645,1.645,1.645,0,0,1,1.645,1.645"
        transform="translate(-252.265 32.942)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_508"
        d="M420.982,282.844h3.289a.987.987,0,0,0,0-1.974h-3.289a.987.987,0,0,0,0,1.974Z"
        transform="translate(-358.272 80.695)"
        fill={color.path ? color.path : "#97AFB8"}
      />
      <path
        id="Path_509"
        d="M420.982,326.6h3.289a.987.987,0,0,0,0-1.974h-3.289a.987.987,0,0,0,0,1.974Z"
        transform="translate(-358.272 40.225)"
        fill={color.path ? color.path : "#97AFB8"}
      />
      <path
        id="Path_510"
        d="M420.982,370.344h3.289a.987.987,0,0,0,0-1.974h-3.289a.987.987,0,0,0,0,1.974Z"
        transform="translate(-358.272 -0.226)"
        fill={color.path ? color.path : "#97AFB8"}
      />
      <path
        id="Path_511"
        d="M174.309,136.781h-.987V125.268a2.948,2.948,0,0,0-2.96-2.96H165.1a2.64,2.64,0,0,0-.987.2v-5.789a2.948,2.948,0,0,0-2.96-2.96H149.309a2.948,2.948,0,0,0-2.96,2.96v20.066h-.987a.987.987,0,1,0,0,1.974h28.947a.987.987,0,1,0,0-1.974Zm-9.21-12.5h5.263a1.012,1.012,0,0,1,.987.987V136.78h-7.237V125.268A1.012,1.012,0,0,1,165.1,124.281Zm-16.776-7.565a1.012,1.012,0,0,1,.987-.987h11.842a1.012,1.012,0,0,1,.987.987v20.066H148.323Z"
        transform="translate(-103.375 235.245)"
        fill={color.path ? color.path : "#97AFB8"}
      />
    </g>
  </svg>
);

OfficeAddIcon.propTypes = {
  color: PropTypes.any,
};

export default OfficeAddIcon;
