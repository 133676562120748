import React from "react";
import { Tab, Tabs } from "@mui/material";

const CertificateTypeTab = ({ tabs, value, setValue }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons={false}>
      {tabs.map(({ label, value }) => (
        <Tab key={value} label={label} value={value} />
      ))}
    </Tabs>
  );
};

export default CertificateTypeTab;
