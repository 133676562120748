import React from "react";
import { Grid, FormLabel, CardMedia } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { imageBase } from "api/helper";

export const Close = ({ onClose }) => (
  <SuiBox
    sx={{
      bgcolor: "#FC3C3C !important",
      position: "absolute",
      width: 16,
      height: 16,
      top: -8,
      right: -8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50rem",
      cursor: "pointer",
    }}
    onClick={onClose}
  >
    <CloseIcon sx={{ width: 12, color: "#fff" }} />
  </SuiBox>
);

const SuiFileUploadInput = ({ id, label, accept, getFile, src, required = true }) => {
  const [image, setImage] = React.useState(null);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      getFile(e.target.files[0]);
      setImage(reader.result);
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const removeFile = () => {
    const file = document.getElementById(id);
    file.value = "";
    getFile(null);
    setImage(null);
  };
  return (
    <>
      <FormLabel htmlFor={id} required={required}>
        {label}
      </FormLabel>
      <Grid container spacing={2}>
        <Grid item sx={{ flexGrow: 1 }}>
          {image || src ? (
            <SuiBox
              position="relative"
              borderRadius="5px"
              width={450}
              my={2}
              p={1}
              sx={{ bgcolor: "#f4f4f4 !important" }}
            >
              <Close onClose={removeFile} />
              <CardMedia
                component="img"
                alt={label}
                height={240}
                m={0}
                sx={{ width: "100%", m: 0, objectFit: "scale-down" }}
                image={image ? imageBase(image) : src}
              />
            </SuiBox>
          ) : (
            <SuiBox display="flex" title={"No file chosen"}>
              <SuiInput
                value=""
                type="text"
                placeholder={label}
                inputProps={{
                  readOnly: true,
                  sx: { "&:focus": { border: "0.125rem solid #D3E0E5" } },
                }}
                sx={{ flexGrow: 1 }}
              />
              <SuiButton
                buttonColor="warning"
                size="large"
                sx={{
                  ml: -1.5,
                  px: 2,
                  fontWeight: 400,

                  "&:hover": { transform: "none" },
                }}
                component="label"
                htmlFor={id}
                startIcon={<FileUploadOutlinedIcon />}
              >
                Upload
              </SuiButton>
            </SuiBox>
          )}
        </Grid>
      </Grid>

      <SuiInput
        id={id}
        type="file"
        inputProps={{
          sx: { display: "none" },
          hidden: true,
          required: true,
          accept: accept,
        }}
        onChange={handleFileUpload}
      />
    </>
  );
};

SuiFileUploadInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string,
  getFile: PropTypes.func,
  hideReset: PropTypes.any,
  required: PropTypes.bool,
};

export default SuiFileUploadInput;
