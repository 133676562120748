import { useMemo, createRef } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import configs from "Container/Charts/DoughnutCharts/DefaultDoughnutChart/configs";
import BoxLegends from "Container/ChartLegend/BoxLegends";

const DefaultDoughnutChart = ({ title, height, chart, totalText, id }) => {
  const { data, options } = configs(chart.labels, chart.datasets, chart.cutout);
  const myChartRef = createRef();
  const centerText = {
    id: `centerText${id}`,
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      const total = data.datasets[0].data.reduce((totalVal, num) => totalVal + num, 0);

      ctx.save();
      ctx.font = "500 11px Outfit";
      ctx.fillStyle = "#6E828A";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText("Total", xCoor, yCoor - 14);
      ctx.font = "600 12px Outfit";
      ctx.fillStyle = "#303841";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText(`${total} ${totalText}`, xCoor, yCoor);
    },
  };
  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    myChart.legend.chart.toggleDataVisibility(index);
    myChart.update();
  };
  const renderChart = (
    <SuiBox p={2}>
      {title ? (
        <SuiBox mb={1}>
          <SuiTypography variant="h6" textTransform="capitalize">
            {title}
          </SuiTypography>
        </SuiBox>
      ) : null}
      <SuiBox minHeight={height} pt={2}>
        <Grid container spacing={2} alignItems="center" py={1}>
          <Grid item xs={12} sm={7} lg={12} xl={6}>
            {useMemo(
              () => (
                <Doughnut
                  id={id}
                  ref={myChartRef}
                  data={data}
                  options={options}
                  plugins={[centerText]}
                />
              ),
              [chart, height]
            )}
          </Grid>
          <Grid item xs={12} sm={5} lg={12} xl={6}>
            <Grid container rowSpacing={1} columnSpacing={1.5} alignItems="flex-start">
              {chart.labels.map((label, index) => (
                <Grid item xs={6} sm={12} md={6} xl={12} key={index}>
                  <BoxLegends
                    id={`${id}_${index}`}
                    color={chart.datasets.backgroundColors[index]}
                    label={`${label} (${chart.datasets.data[index]})`}
                    onLegendFn={(e) => toggleLegend(e, index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );

  return title ? <Card>{renderChart}</Card> : renderChart;
};

DefaultDoughnutChart.defaultProps = {
  title: "",
  height: "100%",
};

DefaultDoughnutChart.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DefaultDoughnutChart;
