import { useHandleUpdateOrDeleteMutation } from "app/features/api/CommonChangeApi";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { setSearchParams } from "app/features/AuthSlice";
import SuiDialog from "components/SuiDialog";
import { Toast } from "components/Toaster";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const withGetContainer = ({ WrappedComponent, url, title, message }) => {
  const newComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { permissions } = useSelector((store) => store.auth);
    const [open, setOpen] = useState(false);
    const [updateData, setUpdateData] = useState(null);
    const [urls, setUrls] = useState(null);
    const [resetShorting, setResetShorting] = useState(false);
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();

    useEffect(() => {
      dispatch(setSearchParams({}));
    }, []);
    const handleAdree = useCallback(
      (url) => async () => {
        const { data: response } = await handleUpdateOrDelete({
          url: `${url}`,
        });
        if (response?.status.code === 200) {
          Toast({ message: response?.status.message });
        }
        setOpen(false);
      },
      []
    );
    const handleStateUpdate = useCallback((item) => {
      setUpdateData({ ...item });
      setOpen(true);
    }, []);
    const handleDelete = useCallback(
      ({ url }) =>
        () => {
          setUrls(url);
          setUpdateData(null);
          setOpen(true);
        },
      []
    );
    const { searchParams } = useSelector((store) => store.auth);
    const { data, isLoading, isFetching, error } = useHandleGetApiQuery(
      { url: url, params: searchParams },
      searchParams,
      url,
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    );
    useEffect(() => {
      if (error) {
        if (error?.originalStatus >= 500) {
          Toast({ type: "error", message: error?.status });
        } else {
          if (error?.data?.status) {
            Toast({ type: "error", message: error?.data.status.message });
          }
        }
      }
      if (error?.data?.status?.code === 401) {
        navigate("/dashboard");
      }
    }, [error]);

    const handleUpdateRequest = (url, data) => async () => {
      const response = await handleUpdateOrDelete({
        url: `${url}`,
        data,
        update: true,
      });
      if (response?.error) {
        if (response?.error?.originalStatus >= 500) {
          Toast({ type: "error", message: response?.error?.status });
        } else {
          if (response?.error?.data.status) {
            Toast({ type: "error", message: response?.error?.data.status.message });
          } else {
            Toast({ type: "error", message: "Something went wrong!." });
          }
        }
      }

      if (response?.data?.status.code === 201 || response?.data?.status.code === 200) {
        Toast({ message: response?.data.status.message });
      }
      setOpen(false);
    };
    const handleCloseDialog = useCallback(() => {
      setOpen(false);
      setUrls(null);
    }, []);
    return (
      <>
        <SuiDialog
          width={380}
          title={`Are you sure you want to delete ${title}?`}
          subTitle={message ? message : null}
          open={open}
          handleClose={handleCloseDialog}
          onSubmit={
            !updateData ? handleAdree(urls) : handleUpdateRequest(updateData.url, updateData.data)
          }
          isEdit={updateData}
        />
        <WrappedComponent
          data={data}
          isLoading={isLoading}
          handleDelete={handleDelete}
          permissions={permissions}
          isFetching={isFetching}
          handleUpdateRequest={handleStateUpdate}
          resetShorting={resetShorting}
          setResetShorting={setResetShorting}
          searchParams={searchParams}
        />
      </>
    );
  };

  return newComponent;
};

export default withGetContainer;
