import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    loginWithPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/login",
          method: "POST",
          body: data,
        };
      },
    }),
    adminProfile: build.query({
      query: () => {
        return {
          url: "admin/profile",
        };
      },
    }),
    adminProfileUpdate: build.mutation({
      query: (data) => {
        return {
          url: "admin/profile",
          method: "PATCH",
          body: data,
        };
      },
    }),
    logout: build.mutation({
      query: () => {
        return {
          url: "admin/logout",
          method: "POST",
          body: null,
        };
      },
    }),
    generateOtp: build.mutation({
      query: (data) => {
        return {
          url: "otp/admin/generate",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: (data) => {
        return {
          url: "otp/verify",
          method: "POST",
          body: data,
        };
      },
    }),
    resetPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/reset-password",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLoginWithPasswordMutation,
  useAdminProfileQuery,
  useLogoutMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
  useAdminProfileUpdateMutation,
  useResetPasswordMutation,
} = AuthApi;
