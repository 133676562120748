import React, { useCallback, useState } from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import CommonAccordion from "./CommonAccordion";

import { capitalize } from "api/helper";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import SuiTablePagination from "components/SuiTablePagination";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const CustomerFamilyDetails = () => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const { data, isFetching } = useHandleGetApiQuery(
    { url: `admin/customer-family-detail/${id}`, params: { page: page } },
    page,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);
  return (
    <Grid container className="h-100" rowSpacing={2} alignContent="space-between">
      <Grid item xs={12}>
        {data?.data.count > 0 && !isFetching ? (
          <Grid
            container
            spacing={2}
            alignContent="flex-start"
            sx={{ maxHeight: "calc(100vh - 245px)", overflowY: "overlay", pb: "2rem" }}
          >
            {data?.data.results.map((nominee) => {
              const name = `${nominee.first_name} ${nominee.last_name}`;
              const fullName = `${nominee.title} ${nominee.first_name} ${
                nominee.middle_name ? nominee.middle_name : ""
              } ${nominee.last_name}`;
              const relation = nominee.relation;
              const date = new Date(nominee.dob);
              const birthdateString = [
                date.getDate().toString().padStart(2, "0"),
                date.getMonth() + 1,
                date.getFullYear(),
              ]
                .join("/")
                .toString();
              const email = nominee.email;
              const mobileNo = nominee.mobile_no
                ? `${nominee.country_code} ${nominee.mobile_no}`
                : "";
              const isEmergencyContact = nominee.is_emergency_contact;

              return (
                <Grid
                  key={nominee.user_family_details_id}
                  id={nominee.user_family_details_id}
                  item
                  xs={6}
                >
                  <CommonAccordion
                    id={nominee.user_family_details_id}
                    head={{ type: "Family", label: capitalize(name), relation: relation }}
                    isDefault={isEmergencyContact}
                  >
                    <SuiBox display="flex" flexWrap="wrap">
                      <SuiTypography fontSize="1rem" fontWeight="medium" textTransform="capitalize">
                        Full Name :&nbsp;
                      </SuiTypography>
                      <SuiTypography fontSize="1rem">{capitalize(fullName)}</SuiTypography>
                    </SuiBox>
                    <SuiBox display="flex" flexWrap="wrap">
                      <SuiTypography fontSize="1rem" fontWeight="medium" textTransform="capitalize">
                        DOB :&nbsp;
                      </SuiTypography>
                      <SuiTypography fontSize="1rem">{birthdateString}</SuiTypography>
                    </SuiBox>
                    {mobileNo && (
                      <SuiBox display="flex" flexWrap="wrap">
                        <SuiTypography
                          fontSize="1rem"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Mobile No. :&nbsp;
                        </SuiTypography>
                        <SuiTypography fontSize="1rem">{mobileNo}</SuiTypography>
                      </SuiBox>
                    )}
                    {email && (
                      <SuiBox display="flex" flexWrap="wrap">
                        <SuiTypography
                          fontSize="1rem"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Email :&nbsp;
                        </SuiTypography>
                        <SuiTypography fontSize="1rem">{email.toLowerCase()}</SuiTypography>
                      </SuiBox>
                    )}
                  </CommonAccordion>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            {isFetching ? (
              <CommonSpinner size="100px" color={colors.info.main} />
            ) : (
              "This customer don't have any family details."
            )}
          </SuiBox>
        )}
      </Grid>
      {data?.data.count > 10 ? (
        <Grid item sx={{ ml: "auto" }}>
          <SuiTablePagination
            margin="0px"
            totalData={data?.data.count}
            totalShowing={data?.data.results.length}
            currentPage={{ page: page, fn: handlePageChange }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomerFamilyDetails;
