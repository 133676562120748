import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  FormLabel,
  Icon,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";
import SuiTitleInput from "components/SuiTitleInput";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiPhoneNo from "components/SuiPhoneNo";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiDropDownList from "components/SuiDropDownList";
import SuiDatePicker from "components/SuiDatePicker";
import style from "layouts/agent/components/style";
import { useFormik } from "formik";
import { AgentAddSchema } from "Validation";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import {
  formatDate,
  getNewProperties,
  getCountryShortCode,
  getCountryCode,
  handleCountryCode,
} from "api/helper";
import { AgentUpdateSchema } from "Validation";
import withPostContainer from "Container/withPostContainer";
import { isValidPhoneNumber } from "react-phone-number-input";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
import CompanyDropdown from "components/SuiDropDownList/CompanyDropdown";
import { checkIfEmpty } from "api/helper";

const CommonAddAgent = ({ handlePostRequest, data, id, isFetching }) => {
  const classes = style();
  const initialValues = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    policy_provider: "",
    agent_licence_id: "",
    mobile_no: "",
    email: "",
    pan_number: "",
    country_code: "+91",
    service_experience: null,
    languages_known: [],
    newPassword: "",
    confirmPassword: "",
    subscription_plan: "",
    state: "",
    city: "",
    branch: "",
  };
  const [searchLanguage, setSearchLanguage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [city, setCity] = useState([]);
  const [branch, setBranch] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const btnText = id ? "Update" : "Add";
  const btnTextLoad = id ? "Updating..." : "Adding...";
  const [formData, setFormData] = useState({ languagesKnown: [] });
  const [planList, setPlanList] = useState([]);
  const [planDisable, setPlanDisable] = useState();
  const { data: PlaneFeatures, isLoading: planLoading } = useHandleGetApiQuery({
    url: "admin/filter/plans",
    params: { ordering: "level" },
  });
  const { data: Languages } = useHandleGetApiQuery({
    url: "languages",
  });
  const { data: States, isLoading: stateLoading } = useHandleGetApiQuery({
    url: "places/states",
  });
  const getData = () => {
    const featureData = [];
    PlaneFeatures?.data.map((item) => {
      if (item.status) {
        featureData.push(item.name);
        if (item.level == 1) {
          setPlanDisable(item.name);
        }
      }
    });
    setPlanList(featureData);
  };
  useEffect(() => {
    if (Languages) {
      const languages = Languages?.data.map(({ name }) => name);
      setLanguages(languages);
      setAllLanguages(languages);
    }
  }, [Languages]);
  useEffect(() => {
    getData();
  }, [PlaneFeatures]);
  useEffect(() => {
    if (data) {
      if (data?.data.languages_known) {
        setFormData({ languagesKnown: data?.data.languages_known.split(",") });
      }
    }
  }, [data]);
  const formik = useFormik({
    initialValues: data
      ? {
          policy_provider: data?.data.policy_provider ? data?.data.policy_provider : "",
          agent_licence_id: data?.data.agent_licence_id,
          pan_number: data?.data.pan_number || "",
          service_experience: data?.data.service_experience,
          languages_known: data?.data.languages_known
            ? data?.data.languages_known.split(",")
            : data?.data.languages_known,
          subscription_plan: data?.data.plan_name || "",
          first_name: data?.data.first_name,
          middle_name: data?.data.middle_name ? data?.data.middle_name : "",
          last_name: data?.data.last_name,
          email: data?.data.email,
          title: data?.data.title,
          country_code: data?.data.country_code,
          mobile_no: data?.data.mobile_no,
          state: data?.data.state || "",
          city: data?.data.city || "",
          branch: data?.data.branch || "",
        }
      : initialValues,
    validationSchema: id ? AgentUpdateSchema : AgentAddSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const finalData = getNewProperties(formik.initialValues, values);
      delete finalData["confirmPassword"];
      delete finalData["newPassword"];
      finalData["password"] = values.confirmPassword;
      if ("languages_known" in finalData && !id) {
        finalData["languages_known"] =
          values.languages_known.length > 0 ? values.languages_known.toString() : null;
      }
      if (
        !isValidPhoneNumber(
          formik.values.mobile_no,
          getCountryShortCode(getCountryCode(formik.values.country_code))
        )
      ) {
        return;
      } else {
        const updateData = getNewProperties(formik.initialValues, values);
        if ("languages_known" in updateData) {
          updateData["languages_known"] =
            values.languages_known.length > 0 ? values.languages_known.toString() : null;
        }
        if ("agent_licence_id" in updateData) {
          updateData["agent_licence_id"] = values.agent_licence_id ? values.agent_licence_id : null;
        }
        if ("middle_name" in updateData) {
          updateData.middle_name = checkIfEmpty(updateData.middle_name);
        }
        const url = id ? `admin/agent/${id}` : `admin/agent`;
        handlePostRequest({
          data: id ? updateData : finalData,
          url,
          redirect: "/agent",
          isUpdate: id ? true : null,
        });
      }
    },
  });
  const { data: allCity, isLoading: cityLoading } = useHandleGetApiQuery(
    {
      url: "places/states/cities",
      params: { state_id: formik.values.state },
    },
    {
      skip: formik.values.state ? false : true,
      refetchOnMountOrArgChange: true,
    },
    formik.values.state
  );
  const { data: allBranches, isLoading: branchLoading } = useHandleGetApiQuery(
    {
      url: "places/states/cities/branches",
      params: {
        state_id: formik.values.state,
        city_id: formik.values.city,
        policy_provider: formik.values.policy_provider,
      },
    },
    {
      skip: formik.values.city ? false : true,
      refetchOnMountOrArgChange: true,
    },
    formik.values.city
  );
  useEffect(() => {
    if (allCity) {
      setCity(allCity?.data);
    }
  }, [allCity]);
  useEffect(async () => {
    if (allBranches) {
      setBranch(allBranches.data);
    }
  }, [allBranches]);
  const handleDateChange = (e) => {
    if (e) {
      formik.setFieldValue("service_experience", formatDate(new Date(e)));
    } else {
      formik.setFieldValue("service_experience", null);
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value) {
      const selected = typeof value === "string" ? value.split(",") : value;
      setFormData({ ...formData, languagesKnown: !selected[0] ? [] : selected });
      formik.setFieldValue("languages_known", value);
    }
  };
  const handleRemoveLanguage = useCallback(
    (index, languages) => () => {
      const newLanguages = { ...languages };
      newLanguages.languagesKnown.splice(index, 1);
      setFormData(newLanguages);
      formik.setFieldValue("languages_known", newLanguages.languagesKnown);
    },
    []
  );
  const handleCompany = useCallback((e) => {
    formik.handleChange(e);
    formik.setFieldValue("state", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("branch", "");
    setCity([]);
  }, []);

  return (
    <>
      {(data?.data && !isFetching) || !id ? (
        <Grid component="form" container spacing={{ xs: 1, lg: 2 }}>
          <Grid item xs={6}>
            <FormLabel htmlFor="title" required>
              Title
            </FormLabel>
            <SuiTitleInput value={formik.values.title} setValue={formik.handleChange} />
            <ErrorMessageComponent touched={formik.touched.title} error={formik.errors.title} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="first_name" required>
              First Name
            </FormLabel>
            <SuiInput
              type="text"
              id="first_name"
              name="first_name"
              placeholder="First Name"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.first_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.first_name}
              error={formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="middle_name">Middle Name</FormLabel>
            <SuiInput
              type="text"
              id="middle_name"
              name="middle_name"
              placeholder="Middle Name"
              inputSettungs={{ autoComplete: "off" }}
              value={formik.values.middle_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.middle_name}
              error={formik.errors.middle_name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="last_name" required>
              Last Name
            </FormLabel>
            <SuiInput
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.last_name}
              error={formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="policy_provider" required>
              Company Name
            </FormLabel>
            <CompanyDropdown
              select={{
                label: "Select Company Name",
                name: "policy_provider",
                val: formik.values.policy_provider,
                fn: handleCompany,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.policy_provider}
              error={formik.errors.policy_provider}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="agent_licence_id">Agent Licence ID</FormLabel>
            <SuiInput
              type="text"
              id="agent_licence_id"
              name="agent_licence_id"
              placeholder="Agent ID"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.agent_licence_id}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.agent_licence_id}
              error={formik.errors.agent_licence_id}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="state" required>
              Branch State
            </FormLabel>
            <SuiDropDownList
              data={States?.data}
              isLoading={stateLoading}
              readOnly={formik.values.policy_provider ? false : true}
              select={{
                key: "state_id",
                labelName: "name",
                label: "Select State",
                name: "state",
                valueKey: "state_id",
                val: formik.values.state,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent touched={formik.touched.state} error={formik.errors.state} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="city" required>
              City Name
            </FormLabel>
            <SuiDropDownList
              readOnly={formik.values.policy_provider ? false : true}
              data={city}
              isLoading={cityLoading}
              select={{
                key: "city_id",
                labelName: "name",
                label: "Select City",
                name: "city",
                valueKey: "city_id",
                val: formik.values.city,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent touched={formik.touched.city} error={formik.errors.city} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="branch" required>
              Branch Name
            </FormLabel>
            <SuiDropDownList
              data={branch}
              isLoading={branchLoading}
              readOnly={formik.values.policy_provider ? false : true}
              select={{
                key: "branch_id",
                labelName: "name",
                label: "Select Branch",
                name: "branch",
                valueKey: "branch_id",
                val: formik.values.branch,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent touched={formik.touched.branch} error={formik.errors.branch} />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="mobile_no" required>
              Mobile Number
            </FormLabel>
            <SuiPhoneNo
              countryCode={(e) => handleCountryCode(formik, e)}
              mobileNum={formik.handleChange}
              codeValue={getCountryCode(formik.values.country_code)}
              phoneValue={formik.values.mobile_no}
            />
            <ErrorMessageComponent
              touched={formik.touched.mobile_no}
              error={
                formik.values.mobile_no &&
                !isValidPhoneNumber(
                  formik.values.mobile_no,
                  getCountryShortCode(getCountryCode(formik.values.country_code))
                )
                  ? "Please enter valid mobile number"
                  : formik.errors.mobile_no
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="email" required>
              Email ID
            </FormLabel>
            <SuiInput
              id="email"
              name="email"
              placeholder="Email ID"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="pan_number">PAN Number</FormLabel>
            <SuiInput
              type="text"
              id="pan_number"
              name="pan_number"
              placeholder="PAN Number"
              inputSettungs={{ autoComplete: "off", required: true }}
              value={formik.values.pan_number}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.pan_number}
              error={formik.errors.pan_number}
            />
          </Grid>
          {!id ? (
            <>
              <Grid item xs={12} />
              <Grid item xs={6}>
                <FormLabel htmlFor="newPassword" required>
                  Create password
                </FormLabel>
                <SuiPasswordInput
                  attr={{ name: "newPassword", placeholder: "Create Password" }}
                  value={formik.values.newPassword}
                  getValue={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.newPassword}
                  error={formik.errors.newPassword}
                />
                <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
                  <Icon
                    fontSize="small"
                    sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}
                  >
                    info_outlined
                  </Icon>
                  <SuiTypography
                    fontWeight="medium"
                    textColor="text2"
                    sx={{ fontSize: "0.8rem" }}
                    px={0.5}
                    py={0}
                  >
                    Note: Password required at least 8 characters, 1 number, 1 Alphabet, 1 special
                    character.
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={6}>
                <FormLabel htmlFor="confirmPassword" required>
                  Confirm Password
                </FormLabel>
                <SuiPasswordInput
                  attr={{ name: "confirmPassword", placeholder: "Confirm Password" }}
                  value={formik.values.confirmPassword}
                  getValue={formik.handleChange}
                />
                <ErrorMessageComponent
                  touched={formik.touched.confirmPassword}
                  error={formik.errors.confirmPassword}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={6}>
            <SuiDatePicker
              input={{
                id: "service_experience",
                lable: "Service Experience",
                value: formik.values.service_experience,
                fn: handleDateChange,
                notRequired: true,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.service_experience}
              error={formik.errors.service_experience}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="languages-known" variant="label">
              Languages Known
            </FormLabel>
            <FormControl fullWidth>
              <Select
                id="languages-known"
                size="small"
                displayEmpty
                multiple
                name="languagesKnown"
                value={formData.languagesKnown}
                renderValue={() => "Select Languages"}
                onChange={handleChange}
                MenuProps={{
                  sx: { "& .MuiMenu-paper": { maxHeight: 300 } },
                }}
                sx={{
                  "&> .MuiSelect-select": {
                    color: formData.languagesKnown.length > 0 ? "inherit" : "#AAAEB1 !important",
                  },
                }}
              >
                <SuiBox
                  component="li"
                  customClass={classes.searchBar}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <TextField
                    sx={{
                      bgcolor: "#F4F5F5",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": { bgcolor: "transparent !important" },
                    }}
                    value={searchLanguage}
                    onChange={(e) => {
                      setSearchLanguage(e.target.value);
                      const list = languages.filter((lang) => {
                        return lang
                          .toLocaleLowerCase()
                          .startsWith(e.target.value.toLocaleLowerCase());
                      });
                      setAllLanguages([...list]);
                    }}
                    size="small"
                    fullWidth
                    name="searchLanguage"
                    id="search-language"
                    type="search"
                    placeholder="Search"
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      inputProps: {
                        sx: { border: "none !important", borderRadius: 0 },
                      },
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 1 }}>
                          <SearchRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SuiBox>
                <ErrorMessageComponent
                  touched={formik.touched.languages_known}
                  error={formik.errors.languages_known}
                />
                {allLanguages.length > 0 ? (
                  allLanguages.map((language) => {
                    return (
                      <MenuItem
                        disableRipple
                        value={language}
                        key={language}
                        className={classes.checkbox_wrap}
                      >
                        <SuiBox customClass="Checkbox_box">
                          <Checkbox
                            size="small"
                            checked={formData.languagesKnown.indexOf(language) > -1}
                          />
                          <SuiTypography component="span" textTransform="capitalize">
                            {language}
                          </SuiTypography>
                        </SuiBox>
                      </MenuItem>
                    );
                  })
                ) : (
                  <SuiBox component="li" sx={{ textAlign: "center", py: 3 }}>
                    No Language Found.
                  </SuiBox>
                )}
              </Select>
            </FormControl>
          </Grid>
          {formData.languagesKnown.length > 0 && formData.languagesKnown[0] && (
            <Grid item xs={12}>
              <SuiBox
                display="flex"
                sx={{
                  gap: 2,
                  py: 2,
                  flexWrap: "wrap",
                  maxHeight: 180,
                  overflowY: "auto",
                }}
              >
                {formData.languagesKnown.map((item, index) => (
                  <SuiBox
                    key={item}
                    sx={{
                      bgcolor: "#E5EBED !important",
                      position: "relative",
                      px: 2,
                      py: 1,
                      borderRadius: "5px",
                    }}
                  >
                    <SuiBox
                      sx={{
                        bgcolor: "#FC3C3C !important",
                        position: "absolute",
                        width: 16,
                        height: 16,
                        top: -8,
                        right: -8,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50rem",
                        cursor: "pointer",
                      }}
                      onClick={handleRemoveLanguage(index, formData)}
                    >
                      <CloseIcon sx={{ width: 12, color: "#fff" }} />
                    </SuiBox>
                    {item}
                  </SuiBox>
                ))}
              </SuiBox>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormLabel htmlFor="subscription_plan" required>
              Assign Plan
            </FormLabel>
            <SuiDropDownList
              data={planList}
              isLoading={planLoading}
              readOnly={id && data?.data.plan_name}
              select={{
                label: "Select Assign Plan",
                name: "subscription_plan",
                val: formik.values.subscription_plan,
                fn: formik.handleChange,
                enableId: planDisable,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.subscription_plan}
              error={formik.errors.subscription_plan}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  component={Link}
                  to="/agent"
                  buttonColor="secondary"
                  fullWidth
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  buttonColor="info"
                  fullWidth
                  onClick={formik.handleSubmit}
                  disabled={formik.isSubmitting || !formik.dirty}
                  type="submit"
                >
                  {formik.isSubmitting ? btnTextLoad : btnText}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 390px)"
        >
          <CommonSpinner size="100px" color={colors.info.main} />
        </SuiBox>
      )}
    </>
  );
};

export default withPostContainer(CommonAddAgent, "admin/agent/");
