import PropTypes from "prop-types";

const OtherAddIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25.192 25.019">
    <g id="noun-address-1227829" transform="translate(-96.334 -27.998)">
      <path
        id="Path_533"
        d="M285.819,109.667a3.367,3.367,0,1,0-2.373-.992,3.37,3.37,0,0,0,2.373.992Z"
        transform="translate(-176.888 -71.208)"
        fill={color.fill ? color.fill : "#97AFB8"}
      />
      <path
        id="Path_534"
        d="M119.831,42.789A.834.834,0,0,0,119,42.1h-5.211l1.112-1.493a7.952,7.952,0,0,0,1.624-5.264,7.668,7.668,0,0,0-15.322,0,7.954,7.954,0,0,0,1.613,5.264l1.112,1.493H98.85a.834.834,0,0,0-.834.687l-1.669,9.246h0a.834.834,0,0,0,.834.982h23.5a.834.834,0,0,0,.834-.982Zm-16.963-7.33a6,6,0,0,1,11.991,0,6.284,6.284,0,0,1-1.293,4.154l-4.7,6.3-4.705-6.3a6.285,6.285,0,0,1-1.293-4.154Zm-4.688,15.9,1.368-7.578h5.634l3.012,4.018a.834.834,0,0,0,1.338,0l3.014-4.026h5.767l1.368,7.586Z"
        transform="translate(0)"
        fill={color.path ? color.path : "#97AFB8"}
      />
    </g>
  </svg>
);

OtherAddIcon.propTypes = {
  color: PropTypes.any,
};

export default OtherAddIcon;
