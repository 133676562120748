import { Outlet, useNavigate } from "react-router-dom";

import CoverLayout from "layouts/authentication/components/CoverLayout";

import loginImg from "assets/images/login/loginImg.svg";

import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import { useSelector } from "react-redux";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useDispatch } from "react-redux";
import { Logout } from "app/features/AuthSlice";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((store) => store.auth);
  const { isLoading, error } = useHandleGetApiQuery({ url: `admin/profile` });
  if (error?.status === 403) {
    dispatch(Logout());
  }
  const logIn = () => {
    return navigate("/dashboard");
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CoverLayout image={loginImg}>
          {isAuthenticated ? (
            <>
              {isLoading ? (
                <SuiBox display="flex" justifyContent="center" alignItems="center">
                  <CommonSpinner size="100px" color={colors.info.main} />
                </SuiBox>
              ) : (
                <>
                  <SuiTypography mt={2} component="h1" variant="formHeading">
                    You are already signed in.
                  </SuiTypography>
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      variant="contained"
                      buttonColor="info"
                      disabled={isLoading}
                      fullWidth
                      onClick={logIn}
                    >
                      Let&lsquo;s go
                    </SuiButton>
                  </SuiBox>
                </>
              )}
            </>
          ) : (
            <Outlet />
          )}
        </CoverLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LogIn;
