import PropTypes from "prop-types";
import { Pagination } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

const SuiTablePagination = ({ totalData, totalShowing, currentPage, margin, pageSize = 10 }) => {
  return (
    <>
      {totalData > pageSize && (
        <SuiBox
          mt={margin || 3}
          mb={margin || 10}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <SuiTypography sx={{ fontSize: "1rem", fontWeight: "600" }}>
            Showing {totalShowing}
          </SuiTypography>
          <SuiTypography sx={{ fontSize: "1rem", px: "10px" }} textColor="text2">
            Out of
          </SuiTypography>
          <SuiTypography sx={{ fontSize: "1rem", fontWeight: "600" }}>
            {totalData} Results
          </SuiTypography>
          <Pagination
            sx={{
              ".MuiPaginationItem-page": { color: "#6E828A" },
              ".Mui-selected": { color: "#fff" },
            }}
            page={currentPage.page}
            count={Math.ceil(totalData / pageSize)}
            color="info"
            onChange={(e, value) => {
              if (!value) return;
              currentPage.fn(e, value);
            }}
          />
        </SuiBox>
      )}
    </>
  );
};

SuiTablePagination.propTypes = {
  totalData: PropTypes.number,
  totalShowing: PropTypes.number,
  currentPage: PropTypes.shape({ page: PropTypes.number, fn: PropTypes.func }),
};

export default SuiTablePagination;
