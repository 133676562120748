import React from "react";
import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import style from "layouts/agent/components/style";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const AgentAchievement = () => {
  const classes = style();
  const { id } = useParams();
  const { data: Achievement, isFetching } = useHandleGetApiQuery({
    url: `admin/agent_achievements/${id}`,
  });
  return (
    <>
      {isFetching ? (
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 390px)"
        >
          <CommonSpinner size="100px" color={colors.info.main} />
        </SuiBox>
      ) : (
        <Grid
          container
          spacing={{
            xs: 2,
            sm: 3,
            md: 2,
            lg: 3,
          }}
        >
          {Achievement?.data.map((item) => {
            return (
              <Grid item xs={6} lg={4} key={item.criteria_name}>
                <SuiBox customClass={classes.achievement_box}>
                  <SuiTypography customClass="count" fontWeight="bold">
                    {item.count}
                  </SuiTypography>
                  <SuiBox customClass="divider" />
                  <SuiTypography customClass="label" whiteSpace="break-spaces">
                    {item.criteria_name === "Service Request Raised"
                      ? "SRs \nRaised"
                      : item.criteria_name.replace(" ", " \n")}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default AgentAchievement;
