import { getLocalStorageData } from "hooks/useLocalStorage";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import axios from "axios";

export const CountryCodes = getCountries()
  .sort((a, b) => en[a].localeCompare(en[b]))
  .map((country) => {
    const countryName = en[country];
    const countryCallingCode = `+${getCountryCallingCode(country)}`;
    return { countryShortCode: country, countryName, countryCallingCode };
  });

export const getCountryCode = (countryCode) => {
  return countryCode.slice(countryCode.indexOf("+"));
};

export const getCountryShortCode = (countryCode) => {
  const filterData = CountryCodes.filter((obj) => obj.countryCallingCode === countryCode);
  return filterData[0].countryShortCode;
};
export const handleCountryCode = (formik, event) => {
  formik.setFieldValue("country_code", getCountryCode(event.target.value));
};
const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, image, size, font) => {
  return {
    sx: {
      bgcolor: image ? "transparent" : `${stringToColor(name)}44`,
      color: stringToColor(name),
      width: `${size || "40px"}`,
      height: `${size || "40px"}`,
      fontSize: `${font || "17px"}`,
    },
    children: `${name?.split(" ")[0][0].toUpperCase()}${name?.split(" ")[1][0].toUpperCase()}`,
  };
};

export const capitalize = (word) => {
  return word
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    })
    ?.join(" ");
};

export const imageBase = (image) => {
  if (!image) {
    return "";
  } else if (image.indexOf("base64,") === -1) {
    return `data:image/jpeg;base64,${image}`;
  } else {
    return image;
  }
};

export const setDuration = (timeUnit) => {
  const tymeType = ["SECOND", "MINUTE", "HOUR", "DAY"];

  if (timeUnit === tymeType[0]) {
    return "SS";
  } else if (timeUnit === tymeType[1]) {
    return "MM";
  } else if (timeUnit === tymeType[2]) {
    return "HH";
  } else if (timeUnit === tymeType[3]) {
    return "Day";
  } else {
    return "";
  }
};

export const setDigits = (num) => {
  if (num) {
    return num <= 9 ? `0${num}` : num;
  } else {
    return "--";
  }
};

export const getNewObjectProperties = (prevObj, newObj) => {
  const results = prevObj.filter((obj) => {
    return !newObj.some((obj2) => {
      return JSON.stringify(obj) === JSON.stringify(obj2);
    });
  });
  return results;
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (prevObj[key] !== newObj[key]) {
      if (
        newObj[key]?.length > 0 ||
        !isNaN(newObj[key]) ||
        (newObj[key] && typeof newObj[key] === "object")
      ) {
        resultObject[key] = newObj[key];
      } else {
        resultObject[key] = null;
      }
    }
  }
  if (resultObject["mobile_no"]) {
    resultObject["country_code"] = newObj.country_code;
  }
  return resultObject;
};

export const checkIfEmpty = (value) => {
  if (value && value.length > 0) {
    return value;
  }
  return null;
};

export const checkIfNull = (value) => {
  if (value) {
    return value;
  }
  return "";
};
export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const bgColor = (name) => {
  let hash = 0;
  let i;
  let color = "#";
  for (i = 0; i < name?.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0x77;
    color += `f0${value.toString(16)}`.slice(-2);
  }
  const style = {
    p: "10px",
    objectFit: "contain",
    bgcolor: `${color}55`,
    borderRadius: "7px 7px 0 0",
    m: 0,
  };
  return style;
};

export const appendZero = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const getReports = async (params, url) => {
  const isRemember = await getLocalStorageData("isRemember");
  const token = await getLocalStorageData(isRemember ? "refresh_token" : "token");
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Public-Key": "1d5ee304-5de4-4490-8cfa-4b23a2bb5058",
      "x-appversion": "web",
    },
    params,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, config);
  return response;
};
