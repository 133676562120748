import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import configs from "Container/Charts/BarCharts/ReportsBarChart/configs";

function ReportsBarChart({ title, chart, height = "11.5rem", color = "#fff" }) {
  const { data, options } = configs(chart.labels, chart.datasets, chart.xTicks, chart.xTitle);
  const toolTipLine = {
    id: "toolTipLine",
    beforeDraw(chart) {
      const { ctx, tooltip, chartArea } = chart;

      if (tooltip._active && tooltip._active.length) {
        ctx.save();

        const activePoint = tooltip._active[0];

        ctx.beginPath();
        ctx.setLineDash([3, 4]);
        ctx.moveTo(activePoint.element.x, chartArea.top);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgb(252, 60, 60)";
        ctx.stroke();
        ctx.restore();
      }
    },
  };
  return (
    <Card>
      <SuiBox pt={2} px={2}>
        <SuiBox mb={2}>
          <SuiTypography variant="h6" textTransform="capitalize">
            {title}
          </SuiTypography>
        </SuiBox>
        {useMemo(
          () => (
            <SuiBox backgroundColor={color} borderRadius="md" height={height}>
              <Bar data={data} options={options} plugins={[toolTipLine]} />
            </SuiBox>
          ),
          [chart]
        )}
      </SuiBox>
    </Card>
  );
}

export default ReportsBarChart;
