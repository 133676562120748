import * as React from "react";
import PropTypes, { object } from "prop-types";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Card,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import SuiButton from "components/SuiButton";
import { Link } from "react-router-dom";
import SuiBox from "components/SuiBox";
import CommonSpinner from "components/CommonSpinner";
import { useDispatch } from "react-redux";
import { getSorting } from "app/features/AuthSlice";
import colors from "assets/theme/base/colors";
import { useEffect } from "react";

const EnhancedTableToolbar = ({ toolbar, permission, exportBtn, reloadBtnFn, loading }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h5" id="tableTitle" fontWeight="600" component="div">
        {toolbar.title}
      </Typography>
      {permission ? (
        <SuiBox>
          {toolbar.button && (
            <SuiButton
              component={Link}
              to={toolbar.button.to}
              size="small"
              buttonColor="info"
              sx={{ fontSize: "14px", fontWeight: "500", px: "12px" }}
              startIcon={<AddRoundedIcon />}
            >
              {toolbar.button.text}
            </SuiButton>
          )}
          {toolbar.button2 && (
            <SuiButton
              component={Link}
              to={toolbar.button2.to}
              size="small"
              buttonColor="warning"
              sx={{ fontSize: "14px", fontWeight: "500", px: "12px", ml: 2 }}
            >
              {toolbar.button2.text}
            </SuiButton>
          )}
          {exportBtn && (
            <SuiButton
              onClick={exportBtn}
              size="small"
              buttonColor="info"
              sx={{ fontSize: "14px", fontWeight: "500", px: 2.5 }}
              startIcon={<CloudUploadIcon />}
            >
              Export CSV
            </SuiButton>
          )}
        </SuiBox>
      ) : null}
      {reloadBtnFn ? (
        <SuiButton
          onClick={reloadBtnFn}
          disabled={loading}
          size="small"
          buttonColor="warning"
          sx={{ fontSize: "14px", fontWeight: "500", px: 2.5 }}
          startIcon={<ReplayRoundedIcon fontSize="small" />}
        >
          Refresh
        </SuiButton>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  toolbar: PropTypes.any,
};

const EnhancedTableHead = ({ tablehead, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tablehead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            width={headCell.width}
            sx={{ fontSize: 14, fontWeight: "500", color: "#6E828A" }}
          >
            {headCell.sorting ? (
              <TableSortLabel
                sx={{ color: "#6E828A !important", fontSize: 14 }}
                active
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  tablehead: PropTypes.arrayOf(object).isRequired,
};

const TableLayout = ({
  reset,
  setReset,
  tableMinWidth,
  toolbar,
  tablehead,
  rows,
  isLoading,
  isFetching,
  permission,
  exportBtn,
  reloadBtnFn,
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  useEffect(() => {
    if (reset) {
      setOrder("asc");
      setOrderBy("");
    }
  }, [reset]);
  const handleRequestSort = (event, property) => {
    if (reset) setReset(false);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    dispatch(getSorting({ order: isAsc ? "-" : "", orderBy: property }));
  };

  return (
    <Card sx={{ width: "100%" }}>
      <EnhancedTableToolbar
        toolbar={toolbar}
        permission={permission}
        exportBtn={exportBtn}
        reloadBtnFn={reloadBtnFn}
        loading={isLoading || isFetching}
      />
      <TableContainer sx={{ "tr:last-of-type td": { border: "none" } }}>
        <Table sx={{ minWidth: tableMinWidth }} aria-labelledby={toolbar.title} size="medium">
          <EnhancedTableHead
            tablehead={tablehead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody sx={{ "& td, & th": { fontSize: 14 } }}>
            {isLoading || isFetching ? (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    paddingTop: "100px",
                    paddingBottom: "100px",
                  }}
                  colSpan={tablehead.length}
                >
                  <CommonSpinner size="100px" color={colors.info.main} />
                </TableCell>
              </TableRow>
            ) : rows?.length > 0 ? (
              <>{rows}</>
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    paddingTop: "100px",
                    paddingBottom: "100px",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                  }}
                  colSpan={tablehead.length}
                >
                  No Data Found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
TableLayout.defaultProps = {
  tableMinWidth: "100%",
};
TableLayout.propTypes = {
  tableMinWidth: PropTypes.any,
  toolbar: PropTypes.object,
  tablehead: PropTypes.arrayOf(object),
  rows: PropTypes.any,
};
export default TableLayout;
