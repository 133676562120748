import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function MiniStatisticsCard({ backgroundColor, title, count, icon }) {
  return (
    <Card>
      <SuiBox backgroundColor={backgroundColor} backgroundGradient>
        <SuiBox px={1.5} py={2}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <SuiTypography
                variant="button"
                fontSize={{ xs: 12, lg: 14 }}
                textColor="text2"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {title}
              </SuiTypography>
              <SuiTypography
                variant="h5"
                fontSize={{ xs: 18, md: 20, xl: 22 }}
                fontWeight="bold"
                textColor="dark"
              >
                {count}
              </SuiTypography>
            </Grid>
            <Grid item xs={4}>
              <SuiBox
                backgroundColor={icon.color}
                sx={{ backdropFilter: "blur(7px)" }}
                width="3rem"
                height="3rem"
                marginLeft="auto"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                boxShadow="lg"
                backgroundGradient
              >
                {icon.component}
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default MiniStatisticsCard;
