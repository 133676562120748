import React from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import SuiTypography from "components/SuiTypography";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useParams } from "react-router-dom";
import { capitalize } from "api/helper";
import moment from "moment";
import SuiBox from "components/SuiBox";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
const headCells = [
  {
    id: "transaction_id",
    width: "auto",
    align: "left",
    sorting: false,
    label: "TRANSACTION ID",
  },
  {
    id: "amount",
    width: "auto",
    align: "center",
    sorting: false,
    label: "AMOUNT",
  },
  {
    id: "payment_method",
    width: "auto",
    align: "left",
    sorting: false,
    label: "PAYMENT METHOD",
  },
  {
    id: "status",
    width: "auto",
    align: "left",
    sorting: false,
    label: "STATUS",
  },
  {
    id: "date_time",
    width: "auto",
    align: "left",
    sorting: false,
    label: "DATE - TIME",
  },
];
const AgentPaymentDetails = () => {
  const { id } = useParams();
  const { data: Payments, isFetching } = useHandleGetApiQuery({
    url: `admin/agent_payment_details/${id}`,
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <>
      <SuiTypography
        fontSize="1.1rem"
        textTransform="capitalize"
        fontWeight="medium"
        sx={{ pb: 2, position: "sticky", top: 0, bgcolor: "#fff", zIndex: 1 }}
      >
        Payment Details
      </SuiTypography>
      <Grid container rowSpacing={2} alignContent="space-between">
        <Grid item xs={12}>
          {Payments?.data && !isFetching ? (
            <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0, bgcolor: "#E6EDF0" } }}
                  >
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        width={headCell.width}
                        sx={{ fontSize: "14px", fontWeight: "500", color: "#6E828A" }}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            sx={{ color: "#6E828A !important", fontSize: 13 }}
                            active
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Payments?.data.results.map((row) => (
                    <TableRow key={row.transactionId} sx={{ "& td, & th": { fontSize: 14 } }}>
                      <TableCell component="th" scope="row">
                        {row.cf_transaction_id ? row.cf_transaction_id : "N/A"}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="left">
                        {row.payment_method ? row.payment_method : "N/A"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: row.order_status === "SUCCESS" ? "#9EE85D" : "#FC3C3C",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {capitalize(row.order_status.replace(/_/g, " "))}
                      </TableCell>
                      <TableCell align="left">
                        {moment(new Date(row.created_at)).format("Do MMM YYYY | hh:mm A")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 390px)"
            >
              {isFetching ? (
                <CommonSpinner size="100px" color={colors.info.main} />
              ) : (
                "This Agent don't have any Payment Details."
              )}
            </SuiBox>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AgentPaymentDetails;
